import { Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

import PreliminaryWorkAPI from "../api/PreliminaryWork.api";
import Autosave from "../components/Autosave";
import GroundPrepSelect from "../components/GroundPrepSelect";
import IrrigationList from "../components/IrrigationList";
import Layout from "../components/Layout";
import ListingDateWithMaterials from "../components/ListingDateWithMaterials";
import PrefieldMaterials from "../components/PrefieldMaterials";
import SoilAnalysis from "../components/SoilAnalysis";
import SprayList from "../components/SprayList";
import TractorCreatableAutocomplete from "../components/TractorCreatableAutocomplete";
import { PreliminaryWork, PreliminaryWorkInput, Tractor } from "../types";

export default function PreliminaryWorkShowPage() {
  const { id } = useParams();
  const { data } = PreliminaryWorkAPI.useDetail(id);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2">Preliminary Work</Typography>
        </Box>
        {data && <PreliminaryWorkForm preliminaryWork={data} />}
      </Box>
    </Layout>
  );
}

function PreliminaryWorkForm({
  preliminaryWork,
}: {
  preliminaryWork: PreliminaryWork;
}) {
  const [listingDate, setListingDate] = React.useState<Dayjs | null>(
    preliminaryWork.listingDate ? dayjs(preliminaryWork.listingDate) : null,
  );
  const [phFullTest, setPhFullTest] = React.useState<number | undefined>(
    preliminaryWork.phFullTest,
  );
  const [phFullTestDate, setPhFullTestDate] = React.useState<Dayjs | null>(
    preliminaryWork.phFullTestDate
      ? dayjs(preliminaryWork.phFullTestDate)
      : null,
  );
  const [phQuickTest, setPhQuickTest] = React.useState<number | undefined>(
    preliminaryWork.phQuickTest,
  );
  const [phQuickTestDate, setPhQuickTestDate] = React.useState<Dayjs | null>(
    preliminaryWork.phQuickTestDate
      ? dayjs(preliminaryWork.phQuickTestDate)
      : null,
  );
  const [prefieldAnalysisComments, setPrefieldAnalysisComments] =
    React.useState<string>(preliminaryWork.prefieldAnalysisComments || "");
  const [soilAnalysisComments, setSoilAnalysisComments] =
    React.useState<string>(preliminaryWork.soilAnalysisComments || "");
  const [tractor, setTractor] = React.useState<Tractor | undefined>();

  const preliminaryWorkInput: PreliminaryWorkInput = {
    id: preliminaryWork.id,
    listingDate: listingDate ? listingDate.toDate() : listingDate,
    phFullTest,
    phFullTestDate: phFullTestDate ? phFullTestDate.toDate() : phFullTestDate,
    phQuickTest,
    phQuickTestDate: phQuickTestDate
      ? phQuickTestDate.toDate()
      : phQuickTestDate,
    prefieldAnalysisComments,
    soilAnalysisComments,
    tractorId: tractor?.id,
  };

  const { mutateAsync } = PreliminaryWorkAPI.useUpdateAuto();

  return (
    <React.Fragment>
      <Autosave
        data={preliminaryWorkInput}
        patchApiCall={mutateAsync}
        showPopup={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TractorCreatableAutocomplete
            setCurrentTractor={(tractor: Tractor) => setTractor(tractor)}
            tractor={tractor}
          />
        </Grid>
      </Grid>
      <Divider>
        <Chip label="Soil Analysis" />
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="pH Full Test"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={phFullTest}
            onChange={(e) => {
              if (isNil(e.target.value) || e.target.value.length === 0) {
                setPhFullTest(undefined);
              } else {
                const newVal = parseFloat(e.target.value);
                if (newVal >= 0) setPhFullTest(newVal);
                if (newVal < 0) setPhFullTest(0);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="ph Full Test Date"
            value={phFullTestDate}
            onChange={(value) => setPhFullTestDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="pH Quick Test"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={phQuickTest}
            onChange={(e) => {
              if (isNil(e.target.value) || e.target.value.length === 0) {
                setPhQuickTest(undefined);
              } else {
                const newVal = parseFloat(e.target.value);
                if (newVal >= 0) setPhQuickTest(newVal);
                if (newVal < 0) setPhQuickTest(0);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="ph Quick Test Date"
            value={phQuickTestDate}
            onChange={(value) => setPhQuickTestDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <SoilAnalysis
        parentId={preliminaryWork.id}
        soilAnalysisFiles={preliminaryWork.soilAnalysisFiles}
        useUploadFile={PreliminaryWorkAPI.useUploadFile}
      />
      <TextField
        label="Soil Analysis Comments"
        multiline
        rows={4}
        value={soilAnalysisComments}
        onChange={(e) => setSoilAnalysisComments(e.target.value)}
      />
      <Divider>
        <Chip label="Prefield Work" />
      </Divider>
      <TextField
        label="Prefield Analysis Comments"
        multiline
        rows={4}
        value={prefieldAnalysisComments}
        onChange={(e) => setPrefieldAnalysisComments(e.target.value)}
      />
      <PrefieldMaterials
        materialApplicableType={"PreliminaryWork"}
        materialApplicableId={preliminaryWork.id}
      />

      <GroundPrepSelect
        groundPrepAttachments={preliminaryWork.groundPrepAttachments}
        groundPrepAttachableType={"PreliminaryWork"}
        groundPrepAttachableId={preliminaryWork.id}
      />
      <IrrigationList
        actualNumAcres={preliminaryWork?.lot?.numOfAcres}
        title="Preliminary Irrigations"
        isPreirrigation={true}
        irrigableId={preliminaryWork.id}
        irrigableType={"PreliminaryWork"}
      />
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="body1">Listing Date</Typography>

        <ListingDateWithMaterials
          actualNumAcres={preliminaryWork?.lot?.numOfAcres}
          materialApplicableId={preliminaryWork.id}
          materialApplicableType={"PreliminaryWork"}
          listingDate={listingDate}
          setListingDate={setListingDate}
        />
      </Box>
      <Divider />
      <SprayList
        actualNumAcres={preliminaryWork?.lot?.numOfAcres}
        title="Sprays"
        sprayableId={preliminaryWork.id}
        sprayableType="PreliminaryWork"
      />
    </React.Fragment>
  );
}
