import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isNil } from "lodash";
import React from "react";

import CultivationAPI from "../api/Cultivation.api";
import HoeDateAPI from "../api/HoeDate.api";
import IrrigationAPI from "../api/Irrigation.api";
import MaterialApplicationAPI from "../api/MaterialApplication.api";
import SprayAPI from "../api/Spray.api";
import {
  Cultivation,
  Harvest,
  HoeDate,
  Irrigation,
  MaterialApplication,
  Spray,
} from "../types";
import {
  extractDate,
  materialApplicationDataToString,
  materialApplicationPhaseToString,
} from "../utils";

type Props = {
  harvest: Harvest;
};
export default function HarvestSummaryTab({ harvest }: Props) {
  const theme = useTheme();

  const { data: cultivations } = CultivationAPI.useList(harvest.id);
  const { data: hoeDates } = HoeDateAPI.useList(harvest.id);
  const { data: irrigations } = IrrigationAPI.useList({
    irrigableType: "Harvest",
    irrigableId: harvest.id,
  });
  const { data: materialApplications } =
    MaterialApplicationAPI.useListByHarvest(harvest.id);
  const { data: sprays } = SprayAPI.useList({
    sprayableType: "Harvest",
    sprayableId: harvest.id,
  });

  const dateData = orderHarvestDateInformation({
    harvest,
    hoeDates,
    cultivations,
    irrigations,
    materialApplications,
    sprays,
  });
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {dateData.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              border: `2px solid ${theme.palette.grey["300"]}`,
              borderRadius: "0.5rem",
              p: "0.5rem",
            }}
          >
            <Typography variant="body1">{data.name}</Typography>
            {data.subinfo && (
              <Typography variant="body2">Phase {data.subinfo}</Typography>
            )}
            <Typography variant="body2">
              {data?.date ? extractDate(data?.date) : "Missing date"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function orderHarvestDateInformation({
  harvest,
  cultivations,
  hoeDates,
  irrigations,
  materialApplications,
  sprays,
}: {
  harvest: Harvest;
  cultivations?: Cultivation[];
  hoeDates?: HoeDate[];
  irrigations?: Irrigation[];
  materialApplications?: MaterialApplication[];
  sprays?: Spray[];
}) {
  const data: Array<{ date?: Date; name: string; subinfo?: string }> = [];
  data.push({
    date: harvest.actualWetDate,
    name: "Actual Wet Date",
  });
  data.push({
    date: harvest.estHarvestDate,
    name: "Estimated Harvest Date",
  });
  // data.push({
  //   date: harvest.listingDate,
  //   name: "Listing Date",
  // });
  data.push({
    date: harvest.projectedWetDate,
    name: "Projected Wet Date",
  });
  data.push({
    date: harvest.thinningDate,
    name: "Thinning Date",
  });
  data.push({
    date: harvest.transplantDate,
    name: "Transplant Date",
  });
  // data.push({
  //   date: harvest.hoeingDate,
  //   name: "Hoeing Date",
  // })
  // harvest?.hoeDates.sort((a,b) => {
  //   if (isNil(a.date) || isNil(b.date)) {
  //     return 0;
  //   }
  //   return b.date.getTime() - a.date.getTime();
  // }).forEach((hoeDate, i) => {
  //   data.push({
  //     date: hoeDate.date,
  //     name: `Hoe Date ${i + 1}`
  //   })
  // })
  if (hoeDates) {
    hoeDates.forEach((hoeDate) => {
      data.push({
        name: "Hoe Date",
        date: hoeDate.date,
      });
    });
  }
  // harvest -> cultivations.applied_on
  if (cultivations) {
    cultivations.forEach((cultivation) => {
      data.push({
        name: "Cultivation",
        date: cultivation.appliedOn,
      });
    });
  }
  // harvest -> irrigations.applied_on
  if (irrigations) {
    irrigations.forEach((irrigation) => {
      data.push({
        name: "Irrigation",
        date: irrigation.appliedOn,
      });
    });
  }
  // harvest -> sprays.applied_on
  if (sprays) {
    sprays.forEach((spray) => {
      data.push({
        name: "Spray",
        date: spray.appliedOn,
      });
    });
  }
  // harvest -> material_applications.applied_on
  if (materialApplications) {
    materialApplications.forEach((materialApplication) => {
      data.push({
        name: materialApplicationDataToString(materialApplication),
        subinfo: materialApplicationPhaseToString(materialApplication.phase),
        date: materialApplication.appliedOn,
      });
    });
  }
  return data.sort((a, b) => {
    if (isNil(a.date) && isNil(b.date)) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
    if (isNil(a.date)) {
      return 1;
    }
    if (isNil(b.date)) {
      return -1;
    }
    return b.date.getTime() - a.date.getTime();
  });
}
