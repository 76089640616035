import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { UseQueryResult } from "react-query";

import HarvestAPI from "../api/Harvest.api";
import Autosave from "../components/Autosave";
import HarvestFieldWorkReportTab from "../components/HarvestFieldWorkReportTab";
import HarvestIrrigationReportTab from "../components/HarvestIrrigationReportTab";
import HarvestMaterialApplicationTab from "../components/HarvestMaterialApplicationTab";
import HarvestPlantingReportTab from "../components/HarvestPlantingReportTab";
import HarvestReportTab from "../components/HarvestReportTab";
import HarvestSprayTab from "../components/HarvestSprayTab";
import HarvestSummaryTab from "../components/HarvestSummaryTab";
import { Harvest, HarvestInput } from "../types";

export default function HarvestTabs({
  harvest,
  refetchHarvest,
}: {
  harvest: Harvest;
  refetchHarvest: () => Promise<UseQueryResult>;
}) {
  const [tabIndex, setTabIndex] = React.useState(harvest.tabIndex);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = React.useState(0);

  const handleResize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };

  React.useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef, containerWidth]);

  const harvestInput: HarvestInput = {
    id: harvest.id,
    tabIndex,
  };

  const { mutateAsync } = HarvestAPI.useUpdateAuto();

  return (
    <React.Fragment>
      <Autosave
        data={harvestInput}
        patchApiCall={mutateAsync}
        showPopup={false}
      />
      <Box
        ref={containerRef}
        sx={{ borderBottom: 1, borderColor: "divider", flexShrink: 1 }}
      >
        <Tabs
          sx={{ width: `${containerWidth}px` }}
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="harvest navigation tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Planting Report" {...a11yProps(0)} />
          <Tab label="Field Work" {...a11yProps(1)} />
          <Tab label="Irrigation" {...a11yProps(2)} />
          <Tab label="Sprays" {...a11yProps(3)} />
          <Tab label="Applied Material" {...a11yProps(4)} />
          <Tab label="Harvest Report" {...a11yProps(5)} />
          <Tab label="Summary" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        {harvest && <HarvestPlantingReportTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        {harvest && <HarvestFieldWorkReportTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        {harvest && <HarvestIrrigationReportTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={3}>
        {harvest && <HarvestSprayTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={4}>
        {harvest && <HarvestMaterialApplicationTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={5}>
        {harvest && <HarvestReportTab harvest={harvest} />}
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={6}>
        {harvest && <HarvestSummaryTab harvest={harvest} />}
      </CustomTabPanel>
    </React.Fragment>
  );
}

function a11yProps(index: number) {
  return {
    id: `harvest-navigation-tab-${index}`,
    "aria-controls": `harvest-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`harvest-tabpanel-${index}`}
      aria-labelledby={`harvest-navigation-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: "1rem" }}>{children}</Box>}
    </div>
  );
}
