import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";

export default function MatchAllRoute() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          The requested page does not exist
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {
            "You did not break the site, but we are not able to find what you are looking for."
          }
        </Typography>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://holadaygrow.com/">
              Holaday Grow
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}
