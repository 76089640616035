import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import React from "react";

import CultivationAPI from "../api/Cultivation.api";
import {
  Cultivation,
  CultivationInput,
  Harvest,
  MaterialApplicationPhase,
} from "../types";
import MaterialsUsed from "./MaterialsUsed";
import MaterialsUsedList from "./MaterialsUsedList";

function createEmptyCultivationInput({
  harvestId,
}: {
  harvestId: number;
}): CultivationInput {
  return {
    appliedOn: new Date(),
    harvestId,
  };
}

type Props = {
  harvest: Harvest;
};

export default function CultivationList({ harvest }: Props) {
  const [openCultivationModal, setOpenCultivationModal] =
    React.useState<boolean>(false);

  const { data } = CultivationAPI.useList(harvest.id);

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", p: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          pb: "1rem",
        }}
      >
        <Typography variant="subtitle1">Cultivations</Typography>
        <Button
          onClick={() => {
            setOpenCultivationModal(true);
            createEmptyCultivationInput({ harvestId: harvest.id });
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>

      <Grid container spacing={2} wrap={"wrap"}>
        {data &&
          data.map((cultivation) => (
            <Grid item xs={12} md={6} lg={4} key={cultivation.id}>
              <CultivationLineItem
                harvest={harvest}
                phase={"cultivating"}
                cultivation={cultivation}
              />
            </Grid>
          ))}
      </Grid>

      <CultivationModal
        harvest={harvest}
        open={openCultivationModal}
        onClose={() => setOpenCultivationModal(false)}
        cultivation={undefined}
      />
    </Paper>
  );
}

type CultivationLineItemProps = {
  cultivation: Cultivation;
  phase: MaterialApplicationPhase;
  harvest: Harvest;
};
function CultivationLineItem({
  cultivation,
  phase,
  harvest,
}: CultivationLineItemProps) {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { mutateAsync } = CultivationAPI.useDelete({
    harvestId: cultivation.harvestId,
    id: cultivation.id,
  });

  const handleDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "1rem",
        borderRadius: 1,
        border: `2px solid ${theme.palette.grey["300"]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={() => setOpenModal(true)}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Typography variant="body1">
            Cultivated on {dayjs(cultivation.appliedOn).format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <Box>
        <MaterialsUsedList
          materialApplicableId={cultivation.id}
          materialApplicableType={"Cultivation"}
          phase={"cultivating"}
        />
      </Box>

      <CultivationModal
        harvest={harvest}
        open={openModal}
        onClose={() => setOpenModal(false)}
        cultivation={cultivation}
      />
    </Box>
  );
}

type CultivationModalProps = {
  harvest: Harvest;
  open: boolean;
  onClose: () => void;
  cultivation?: Cultivation;
};

function CultivationModal({
  harvest,
  open,
  onClose,
  cultivation,
}: CultivationModalProps) {
  const [cultivationId, setCultivationId] = React.useState<number | undefined>(
    cultivation?.id,
  );
  const [appliedOn, setAppliedOn] = React.useState<Dayjs | null>(
    dayjs(cultivation?.appliedOn),
  );
  const { mutateAsync: saveMutateAsync } = CultivationAPI.useSave({
    id: cultivationId,
    harvestId: cultivation?.harvestId ? cultivation.harvestId : harvest.id,
    appliedOn: appliedOn ? appliedOn.toDate() : appliedOn,
  });

  const saveAndShowMaterials = async () => {
    try {
      const res = await saveMutateAsync();
      setCultivationId(res.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    try {
      await saveMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteMutateAsync } = CultivationAPI.useDelete({
    harvestId: cultivation?.harvestId ? cultivation.harvestId : harvest.id,
    id: cultivationId,
  });

  const handleDelete = async () => {
    try {
      await deleteMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    if (isNil(cultivation)) {
      setCultivationId(undefined);
      setAppliedOn(null);
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-cultivation"
      aria-describedby="modal-cultivation-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography id="input-slider" gutterBottom>
          Cultivation
        </Typography>
        <DatePicker
          label="Date"
          value={appliedOn}
          onChange={(value) => setAppliedOn(value)}
        />
        {isNil(cultivationId) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <Typography variant="body1">
              Did you apply any materials?
            </Typography>
            <Button onClick={saveAndShowMaterials}>Yes</Button>
            <Button color="secondary" onClick={handleCreate}>
              No
            </Button>
          </Box>
        ) : (
          <MaterialsUsed
            numAcres={harvest?.lot?.numOfAcres}
            materialApplicableId={cultivationId} // cultivation does not exist yet
            materialApplicableType={"Cultivation"}
            phase={"cultivating"}
          />
        )}
        {cultivation?.id && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Button onClick={handleCreate} variant="contained" fullWidth>
              Save
            </Button>
            {cultivation?.id && (
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Delete
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
