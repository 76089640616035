import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Button, Modal, Typography } from "@mui/material";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";

import { FieldVariety, TrialVariety } from "../types";
import { getSessionToken } from "../utils";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);

type Props = {
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
export default function FieldTrialVarietyImageUpload({
  fieldTrialVariety,
  fieldTrialVarietyType,
}: Props) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const pondRef = React.useRef(null);

  const sessionToken = getSessionToken();

  const serverUrl =
    fieldTrialVarietyType === "TrialVariety"
      ? `${process.env.REACT_APP_API_ENDPOINT}/trial_varieties/${fieldTrialVariety.id}/images`
      : `${process.env.REACT_APP_API_ENDPOINT}/field_varieties/${fieldTrialVariety.id}/images`;
  return (
    <React.Fragment>
      <Button variant="contained" fullWidth onClick={() => setOpenModal(true)}>
        <CameraAltIcon />
      </Button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="field-trial-variety-image-upload-modal-title"
        aria-describedby="field-trial-variety-image-upload-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="body1" gutterBottom>
            Max 3 files at a time
          </Typography>
          <FilePond
            ref={pondRef}
            allowMultiple={true}
            allowRevert={false}
            maxFiles={3}
            server={{
              process: {
                url: serverUrl,
                headers: {
                  Authorization: `Bearer ${sessionToken}`,
                },
              },
            }}
            //onaddfilestart={this.onaddfilestart}
          />
          <Button
            size="large"
            variant="contained"
            fullWidth
            // onClick={hideCamera}
            onClick={() => setOpenModal(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
