import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  title: string;
  text: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
  onAccept: () => void;
};
export default function DeleteConfirmation({
  open,
  setOpen,
  title,
  text,
  onAccept,
}: Props) {
  const handleAccept = () => {
    setOpen(false);
    onAccept();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      aria-describedby="alert-dialog-delete-confirmation"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Disagree</Button>
        <Button onClick={handleAccept}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
