import { Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";

import HarvestAPI from "../api/Harvest.api";
import { Harvest, HarvestInput } from "../types";
import Autosave from "./Autosave";
import HarvestEvaluations from "./HarvestEvaluations";
import PreharvestEvaluationMultiselect from "./PreharvestEvaluationMultiselect";

type HarvestYield = {
  yieldTotalCartons?: number;
  yieldTotalBulkLbs?: number;
  yieldPerAcreCartons?: number;
  yieldPerAcreBulkLbs?: number;
  estYieldPerAcre?: number;
  estLeftInField?: number;
};
type Props = {
  harvest: Harvest;
};
export default function HarvestReportTab({ harvest }: Props) {
  const [harvestYield, setHarvestYield] = React.useState<HarvestYield>({});
  const [harvestReportComments, setHarvestReportComments] =
    React.useState<string>(harvest.harvestReportComments || "");

  const harvestInput: HarvestInput = {
    id: harvest.id,
    harvestReportComments,
    yieldTotalCartons: harvestYield.yieldTotalCartons,
    yieldTotalBulkLbs: harvestYield.yieldTotalBulkLbs,
    yieldPerAcreCartons: harvestYield.yieldPerAcreCartons,
    yieldPerAcreBulkLbs: harvestYield.yieldPerAcreBulkLbs,
    estLeftInField: harvestYield.estLeftInField,
    estYieldPerAcre: harvestYield.estYieldPerAcre,
  };
  const { mutateAsync } = HarvestAPI.useUpdateAuto();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Autosave
        data={harvestInput}
        patchApiCall={mutateAsync}
        showPopup={true}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>

      <Divider>
        <Chip label="Prevaluation" />
      </Divider>

      {/* Preharvest Quality analysis */}
      <PreharvestEvaluationMultiselect harvest={harvest} />

      <TextField
        label="Preharvest Report Comments"
        multiline
        rows={4}
        value={harvestReportComments}
        onChange={(e) => setHarvestReportComments(e.target.value)}
      />

      <Divider>
        <Chip label="Evaluation" />
      </Divider>

      <HarvestEvaluations harvestId={harvest.id} />

      <Divider>
        <Chip label="Yield Information" />
      </Divider>
      {/* yield total information */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <YieldTotalForm
            harvestYield={harvestYield}
            setHarvestYield={setHarvestYield}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <YieldPerAcreForm
            harvestYield={harvestYield}
            setHarvestYield={setHarvestYield}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <YieldImpactForm
            harvestYield={harvestYield}
            setHarvestYield={setHarvestYield}
          />
        </Grid>
      </Grid>

      {/* comments for evaluation */}
    </Box>
  );
}

type HarvestYieldFormProps = {
  harvestYield: HarvestYield;
  setHarvestYield: (arg0: HarvestYield) => void;
};
function YieldTotalForm({
  harvestYield,
  setHarvestYield,
}: HarvestYieldFormProps) {
  return (
    <Box
      sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <Typography variant="h6">Yield Totals</Typography>
      <TextField
        label="Total Cartons"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.yieldTotalCartons}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              yieldTotalCartons: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              yieldTotalCartons: parseInt(val),
            });
          }
        }}
        fullWidth
      />
      <TextField
        label="Total Bulk Lbs"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.yieldTotalBulkLbs}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              yieldTotalBulkLbs: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              yieldTotalBulkLbs: parseInt(val),
            });
          }
        }}
        fullWidth
      />
      <Typography variant="body1">Bulk/ CTN equation</Typography>
      <Typography variant="body1">Total Ctn + Lbs</Typography>
    </Box>
  );
}

function YieldPerAcreForm({
  harvestYield,
  setHarvestYield,
}: HarvestYieldFormProps) {
  return (
    <Box
      sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <Typography variant="h6">Yields per Acre</Typography>
      <TextField
        label="Cartons per Acre"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.yieldPerAcreCartons}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              yieldPerAcreCartons: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              yieldPerAcreCartons: parseInt(val),
            });
          }
        }}
        fullWidth
      />
      <TextField
        label="Bulk Lbs per Acre"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.yieldTotalBulkLbs}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              yieldTotalBulkLbs: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              yieldTotalBulkLbs: parseInt(val),
            });
          }
        }}
        fullWidth
      />
      <TextField
        label="Est. Yield per Acre"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.estYieldPerAcre}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              estYieldPerAcre: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              estYieldPerAcre: parseInt(val),
            });
          }
        }}
        fullWidth
      />
      <TextField
        label="Est. Cartons left in Field"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={harvestYield.estLeftInField}
        onChange={(e) => {
          const val = e.target.value;
          if (isEmpty(val)) {
            setHarvestYield({
              ...harvestYield,
              estLeftInField: 0,
            });
          } else {
            setHarvestYield({
              ...harvestYield,
              estLeftInField: parseInt(val),
            });
          }
        }}
        fullWidth
      />
    </Box>
  );
}

function YieldImpactForm({
  harvestYield,
  setHarvestYield,
}: HarvestYieldFormProps) {
  return (
    <Box
      sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <Typography variant="h6">Yield Impact</Typography>
      <Typography variant="body1">Depreseed Market Dropdown</Typography>
      <Typography variant="body1">Weather / Field Dropdown</Typography>
      <Typography variant="body1">Quality Dropdown</Typography>
    </Box>
  );
}
