import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useParams } from "react-router-dom";

import UserAPI from "../api/User.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { useSessionContext } from "../contexts/Session.context";
import { User, UserInput, UserRole } from "../types";
import { userRoleValue } from "../utils";

export default function UserShowPage() {
  const { session } = useSessionContext();
  const { id } = useParams();
  const { data: user } = UserAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">User Details</Typography>
        </Box>

        {user && session && (
          <UserForm user={user} currentUserRole={session.role} />
        )}
      </Box>
    </Layout>
  );
}

function UserForm({
  user,
  currentUserRole,
}: {
  user: User;
  currentUserRole: UserRole;
}) {
  const [email, setEmail] = React.useState<string>(user.email);
  const [firstName, setFirstName] = React.useState<string>(user.firstName);
  const [lastName, setLastName] = React.useState<string>(user.lastName);
  const [userRole, setUserRole] = React.useState<UserRole>(user.role);

  const input: UserInput = {
    ...user,
    email: user.email === email ? "" : email,
    firstName,
    lastName,
    role: userRole,
  };

  const handleUserRoleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value as UserRole);
  };

  return (
    <BasicShowEdit
      modelApiUseSave={UserAPI.useSave}
      modelApiUseDelete={UserAPI.useDelete}
      modelInput={input}
    >
      <React.Fragment>
        <TextField
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          label="First Name"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
        {userRoleValue(currentUserRole) >= userRoleValue("business_admin") && (
          <FormControl fullWidth>
            <InputLabel id="user-permissions-select-label">
              User Permissions
            </InputLabel>
            <Select
              labelId="user-permissions-select-label"
              id="user-permissions-select"
              value={userRole}
              label="User Permissions"
              onChange={handleUserRoleChange}
            >
              {userRoleValue(currentUserRole) >= userRoleValue("admin") && (
                <MenuItem value={"admin"}>Admin</MenuItem>
              )}
              {userRoleValue(currentUserRole) >=
                userRoleValue("business_admin") && (
                <MenuItem value={"business_admin"}>Farmer Admin</MenuItem>
              )}
              <MenuItem value={"basic"}>Farmer Employee</MenuItem>
            </Select>
          </FormControl>
        )}
      </React.Fragment>
    </BasicShowEdit>
  );
}
