import { isNil } from "lodash";
import React from "react";

import LotAPI from "../api/Lot.api";
import { Lot, Ranch } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  lot?: Lot;
  setLot: (lot?: Lot) => void;
  ranch: Ranch;
};

export default function LotByRanchSearch({ lot, setLot, ranch }: Props) {
  const [query, setQuery] = React.useState<string>("");

  const { data } = LotAPI.useSearchByRanch({ query, ranch });

  const options = data
    ? data.map((o) => ({ label: `${o.num}`, value: o }))
    : [];

  const value = isNil(lot)
    ? { label: query, value: query }
    : { label: `${lot.num}`, value: lot };

  return (
    <SearchBarAutocomplete
      label={"Lot"}
      inputValue={query}
      setSearchValue={setQuery}
      options={options}
      noOptionsText={"Lot not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue)) {
          setLot(undefined);
        } else {
          setLot(newValue.value);
        }
      }}
    />
  );
}
