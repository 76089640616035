// import dayjs from "dayjs";
import ky from "ky";
import { isEmpty, isNil } from "lodash";

import { HttpError } from "../types/http-error";

const client = ky.extend({
  retry: 0,
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  throwHttpErrors: true,
  hooks: {
    beforeRequest: [
      (request) => {
        const data = window.localStorage.getItem(
          process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        );
        if (!(isNil(data) || isEmpty(data))) {
          const sessionData = JSON.parse(data) || {};
          // const expDate = new Date(sessionData["exp"]);
          // const parsedDate = dayjs(expDate, "MM-DD-YYYY HH:mm");
          // TODO cancel call and redirect to login screen with a notification
          // sessionData["exp"] &&
          //   expDate > new Date()
          if (sessionData["token"]) {
            request.headers.set(
              "Authorization",
              `Bearer ${sessionData["token"]}`,
            );
          }
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response && response.status === 401) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 404) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 406) {
          // Client Error with code sent
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status >= 400) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        }
      },
    ],
  },
});

export default client;
