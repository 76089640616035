import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  Commodity,
  ListResponseData,
  Variety,
  VarietyInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
  handleSimpleList,
} from "./handler";

const methods = {
  useAttachedCommodities: (id: number | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<Commodity[]>({
      queryFn: () => handleSimpleList({ url: `varieties/${id}/commodities` }),
      queryKey: ["varieties", id?.toString(), "commodities"],
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Variety[]>({
      queryKey: ["varieties", query],
      queryFn: () => handleSearch({ baseUrl: "varieties", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (varietyInput: VarietyInput) => {
    const navigate = useNavigate();
    return useMutation<Variety>({
      mutationFn: () =>
        handleSave({ baseUrl: "varieties", input: varietyInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Variety>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Variety>>({
      cacheTime: 0,
      queryKey: ["varieties", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "varieties", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Variety>({
      cacheTime: 0,
      queryKey: ["variety", id],
      queryFn: () => handleDetail({ baseUrl: "varieties", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Variety>({
      mutationFn: () => handleDelete({ baseUrl: "varieties", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};
export default methods;
