import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React from "react";

import CommodityAPI from "../api/Commodity.api";
import { Commodity } from "../types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  commodities: Commodity[];
  setCommodities: (arg: Commodity[]) => void;
};
export default function SelectMultipleCommodities({
  commodities,
  setCommodities,
}: Props) {
  const [query, setQuery] = React.useState<string>("");
  const { data } = CommodityAPI.useSearch({ query });

  const options = data || [];

  return (
    <Autocomplete
      multiple
      id="checkboxes-multiple-commodities-select"
      options={options}
      onInputChange={(
        event: React.SyntheticEvent,
        value: string,
        reason: string,
      ) => {
        if (reason !== "reset") {
          setQuery(value);
        }
      }}
      onChange={(event: any, newValue: Commodity[]) => {
        setCommodities(newValue);
      }}
      value={commodities} // TODO
      style={{ width: "100%" }}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Commodity List"
          placeholder="Commodities associated with Variety"
        />
      )}
    />
  );
}
