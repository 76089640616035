import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import MaterialApplicationAPI from "../api/MaterialApplication.api";
import {
  Material,
  MaterialApplicableType,
  MaterialApplication,
  MaterialApplicationInput,
  MaterialApplicationPhase,
  MeasuringUnit,
} from "../types";
import MaterialSearchableAutocomplete from "./MaterialSearchableAutocomplete";
import MeasuringUnitSelect from "./MeasuringUnitSelect";

type Props = {
  open: boolean;
  onClose: () => void;
  phase: MaterialApplicationPhase;
  materialApplication?: MaterialApplication;
  materialApplicableId: number;
  materialApplicableType: MaterialApplicableType;
  numAcres?: number;
};
export default function AppliedMaterialModal({
  numAcres,
  phase,
  open,
  onClose,
  materialApplication,
  materialApplicableId,
  materialApplicableType,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchedMaterial, setSearchedMaterial] = React.useState<Material>();
  const [appliedOn, setAppliedOn] = React.useState<Dayjs | null>(
    materialApplication?.appliedOn
      ? dayjs(materialApplication?.appliedOn)
      : null,
  );
  const [amount, setAmount] = React.useState<number | undefined>(
    materialApplication?.amount,
  );
  const [measuringUnit, setMeasuringUnit] = React.useState<
    MeasuringUnit | undefined
  >(materialApplication?.measuringUnit);

  const input: MaterialApplicationInput = {
    materialApplicableId,
    materialApplicableType,
    materialId: searchedMaterial && searchedMaterial.id,
    phase,
    appliedOn: appliedOn ? appliedOn.toDate() : appliedOn,
    amount,
    measuringUnitId: measuringUnit?.id,
  };
  if (materialApplication?.id) {
    input["id"] = materialApplication?.id;
  }
  const { mutateAsync } = MaterialApplicationAPI.useSave(input);

  const handleSave = async () => {
    try {
      if (isNil(materialApplication) && isNil(searchedMaterial)) {
        return enqueueSnackbar("Must select a Material to proceed.", {
          variant: "error",
        });
      }
      await mutateAsync();
      if (isNil(materialApplication?.id)) {
        setAppliedOn(null);
        setMeasuringUnit(undefined);
        setAmount(undefined);
      }
      onClose();
      enqueueSnackbar("Saved material application successfully");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {materialApplication ? (
            <Box>
              <Typography variant="h6">
                {materialApplication.material?.name}
              </Typography>
            </Box>
          ) : (
            <MaterialSearchableAutocomplete
              label={"Select applied material"}
              material={searchedMaterial}
              setMaterial={setSearchedMaterial}
            />
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
            mt: "0.5rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DatePicker
              label="Applied On"
              value={appliedOn}
              onChange={(value) => setAppliedOn(value)}
              sx={{ flex: 1, width: "100%" }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Material per Acre"
              value={materialPerAcre(amount, numAcres)}
              disabled
              fullWidth
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Total Amount of Material"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={amount || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNil(e.target.value) || e.target.value.length === 0) {
                  setAmount(undefined);
                } else {
                  const newVal = parseFloat(e.target.value);
                  if (newVal >= 0) setAmount(newVal);
                  if (newVal < 0) setAmount(0);
                }
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <MeasuringUnitSelect
              usage={"applied_materials"}
              measuringUnit={measuringUnit}
              setMeasuringUnit={setMeasuringUnit}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Button onClick={handleSave} variant="contained" fullWidth>
            Save
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            fullWidth
            color={"secondary"}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

function materialPerAcre(totalMaterial?: number, numAcres?: number) {
  if (isNil(numAcres) || isNil(totalMaterial)) {
    return "Number of acres has not been defined.";
  }
  return totalMaterial / numAcres;
}
