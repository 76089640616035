import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import {
  MuiColorInput,
  MuiColorInputColors,
  MuiColorInputFormat,
  MuiColorInputValue,
  matchIsValidColor,
} from "mui-color-input";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import CommodityAPI from "../api/Commodity.api";
import { Commodity, Criterium } from "../types";
import CreateCriteriumModal from "./CreateCriteriumModal";
import CriteriaDragAndDrop from "./CriteriaDragAndDrop";

type Props = {
  commodity: Commodity;
};

export default function CriteriaShow({ commodity }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [color, setColor] = React.useState<MuiColorInputValue>(
    commodity.color || "#bd0b25",
  );
  const [cropRotationColor, setCropRotationColor] =
    React.useState<MuiColorInputValue>(
      commodity.cropRotationColor || "#bd0b25",
    );
  const [criteria, setCriteria] = React.useState<Criterium[]>(
    commodity.criteria,
  );

  const { mutateAsync } = CommodityAPI.useSaveAndArchiveOnEdit({
    ...commodity,
    color,
    cropRotationColor,
    criteria,
  });

  const { mutateAsync: deleteCommodity } = CommodityAPI.useDelete(commodity.id);

  const handleSave = async () => {
    try {
      const res = await mutateAsync();
      if (res.archivedCommodity) {
        navigate(`/commodities/${res.commodity.id}`);
      }
      enqueueSnackbar("Saved Commodity");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCommodity();
      enqueueSnackbar("Archived Commodity");
      navigate(`/commodities`);
    } catch (error) {
      let message = "Unknown error occurred. Please try again later.";
      console.error(error);
      if (error instanceof Error) {
        message = error.message;
      }
      enqueueSnackbar(message);
    }
  };

  const [openModel, setOpenModal] = React.useState<boolean>(false);

  const addCriterium = (criterium: Criterium) => {
    setCriteria([...criteria, criterium]);
  };

  const replaceCriterium = (criterium: Criterium) => {
    const criteriaIndex = criteria.findIndex((c) => c.id === criterium.id);
    criteria[criteriaIndex] = criterium;
    const updatedCriteria = criteria.map((c, index) => ({
      ...c,
      orderId: index,
    }));

    setCriteria([...updatedCriteria]);
  };

  const removeCriterium = (criterium: Criterium) => {
    const updatedCriteria = criteria.filter((c) => c.id !== criterium.id);
    setCriteria(updatedCriteria);
  };

  const handleColorChange = (color: string, colors: MuiColorInputColors) => {
    if (matchIsValidColor(color)) {
      setColor(color);
    } else {
      enqueueSnackbar("Invalid color");
    }
  };

  const handleCropRotationColorChange = (
    color: string,
    colors: MuiColorInputColors,
  ) => {
    if (matchIsValidColor(color)) {
      setCropRotationColor(color);
    } else {
      enqueueSnackbar("Invalid color");
    }
  };

  const format: MuiColorInputFormat = "hex";

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">{commodity.name}</Typography>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            color={"secondary"}
          >
            Archive
          </Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "2rem",
        }}
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">Main Color</Typography>
          <MuiColorInput
            value={color}
            onChange={handleColorChange}
            format={format}
          />
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">Crop Rotation Color</Typography>
          <MuiColorInput
            value={cropRotationColor}
            onChange={handleCropRotationColorChange}
            format={format}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h6">Evaluation Criteria</Typography>
        <Button onClick={() => setOpenModal(true)}>
          <AddCircleIcon />
        </Button>
      </Box>
      <div>
        <CriteriaDragAndDrop
          criteria={criteria}
          setCriteria={setCriteria}
          replaceCriterium={replaceCriterium}
          removeCriterium={removeCriterium}
        />
      </div>
      <CreateCriteriumModal
        open={openModel}
        onClose={() => {
          setOpenModal(false);
        }}
        commodityId={commodity.id}
        addCriterium={addCriterium}
      />
    </React.Fragment>
  );
}
