import { css } from "@emotion/css";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";

import ProfileNavigationMenu from "./ProfileNavigationMenu";

export default function NavigationBar() {
  // const { currentBusinessUnit } = useBusinessUnitContext();
  const { collapseSidebar } = useProSidebar();

  return (
    <AppBar position="static" sx={{ flexShrink: 1, pl: "1rem", pr: "1rem" }}>
      {/*<Container>*/}
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "flex" },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              collapseSidebar();
            }}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <div
            className={css`
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}
          >
            <Link
              to={`/dashboard`}
              className={css`
                text-decoration: none;
              `}
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Luis Scattini & Sons
              </Typography>
            </Link>
          </div>
          <div>
            <ProfileNavigationMenu />
          </div>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              collapseSidebar();
            }}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <div
            className={css`
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              justify-content: center;
              align-items: end;
            `}
          >
            <ProfileNavigationMenu />
          </div>
        </Box>
      </Toolbar>
      {/*</Container>*/}
    </AppBar>
  );
}
