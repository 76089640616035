import { Box, Chip, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";

import MaterialApplicationAPI from "../api/MaterialApplication.api";
import {
  MaterialApplicableType,
  MaterialApplication,
  MaterialApplicationPhase,
} from "../types";
import { materialApplicationDataToString } from "../utils";

type Props = {
  actualNumAcres?: number;
  materialApplicableId?: number;
  materialApplicableType: MaterialApplicableType;
  phase: MaterialApplicationPhase;
};
export default function MaterialsUsedList(
  {
    actualNumAcres,
    materialApplicableId,
    materialApplicableType,
    phase,
  }: Props,
) {
  const { data } = MaterialApplicationAPI.useList({
    materialApplicableId,
    materialApplicableType,
    phase,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {data && !isEmpty(data) && (
        <Divider sx={{ mb: "0.5rem" }}>
          <Chip label="Materials Used" />
        </Divider>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {data &&
          data.map((materialApplication) => (
            <MaterialUsedItem
              actualNumAcres={actualNumAcres}
              materialApplication={materialApplication}
            />
          ))}
      </Box>
    </Box>
  );
}

function MaterialUsedItem(
  {
    actualNumAcres,
    materialApplication,
  }: {
    actualNumAcres?: number;
    materialApplication: MaterialApplication;
  },
) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        key={materialApplication.id}
      >
        <Typography variant="body1">
          {materialApplicationDataToString(materialApplication)}
        </Typography>
        <Typography variant="subtitle1">
          On {dayjs(materialApplication.appliedOn).format("MM/DD/YYYY")}
        </Typography>
      </Box>
    </>
  );
}
