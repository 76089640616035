import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Chip, Modal, Paper, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import HoeDateAPI from "../api/HoeDate.api";
import { Harvest, HoeDate } from "../types";

type Props = {
  harvest: Harvest;
};

export default function HoeDateList({ harvest }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { data: hoeDates } = HoeDateAPI.useList(harvest.id);

  const [newHoeDate, setNewHoeDate] = React.useState<Dayjs | null>(
    dayjs(new Date()),
  );
  const [open, setOpen] = React.useState<boolean>(false);

  const { mutateAsync } = HoeDateAPI.useSave({
    harvestId: harvest.id,
    date: newHoeDate ? newHoeDate.toDate() : newHoeDate,
  });

  const handleCreate = async () => {
    if (isNil(newHoeDate)) {
      return enqueueSnackbar("Must enter a date.");
    }
    try {
      await mutateAsync();
      setOpen(false);
      setNewHoeDate(dayjs(new Date()));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "1rem",
          py: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box sx={{ flexShrink: 1 }}>
            <Typography variant="subtitle1">Weeding Date List</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddCircleIcon />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "begin",
              flexWrap: "wrap",
              listStyle: "none",
              gap: 1,
              p: 1,
              m: 0,
            }}
            component="ul"
          >
            {hoeDates &&
              hoeDates.map((hoeDate, i) => (
                <HoeDateChip key={i} hoeDate={hoeDate} />
              ))}
          </Box>
        </Box>
      </Paper>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-hoedate"
        aria-describedby="modal-hodate-creation"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <DatePicker
              label="Date"
              value={newHoeDate}
              onChange={(value) => setNewHoeDate(value)}
            />
            <Box sx={{ flex: 1, height: "100%" }}>
              <Button
                onClick={handleCreate}
                fullWidth
                variant="contained"
                sx={{ height: "100%" }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

type HoeDateChipProps = {
  hoeDate: HoeDate;
};

function HoeDateChip({ hoeDate }: HoeDateChipProps) {
  const { mutateAsync } = HoeDateAPI.useDelete({
    harvestId: hoeDate.harvestId,
    id: hoeDate.id,
  });

  const handleDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Chip
      label={dayjs(hoeDate.date).format("MM/DD/YYYY")}
      onDelete={handleDelete}
    />
  );
}
