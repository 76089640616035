import { Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import React from "react";

import HarvestAPI from "../api/Harvest.api";
import PreliminaryWorkAPI from "../api/PreliminaryWork.api";
import { Harvest, HarvestInput } from "../types";
import Autosave from "./Autosave";
import CultivationList from "./CultivationList";
import GroundPrepSelect from "./GroundPrepSelect";
import HoeDateList from "./HoeDateList";
import ListingDateWithMaterials from "./ListingDateWithMaterials";
import PrefieldMaterials from "./PrefieldMaterials";
import PreliminaryWorkHarvestWrapper from "./PreliminaryWorkHarvestWrapper";
import SoilAnalysis from "./SoilAnalysis";

type Props = {
  harvest: Harvest;
};
export default function HarvestFieldWorkReportTab({ harvest }: Props) {
  const [soilAnalysisComments, setSoilAnalysisComments] =
    React.useState<string>(
      harvest?.preliminaryWork?.soilAnalysisComments || "",
    );
  const [prefieldAnalysisComments, setPrefieldAnalysisComments] =
    React.useState<string>(
      harvest?.preliminaryWork?.prefieldAnalysisComments || "",
    );
  const [listingDate, setListingDate] = React.useState<Dayjs | null>(
    harvest?.preliminaryWork?.listingDate
      ? dayjs(harvest.preliminaryWork.listingDate)
      : null,
  );
  const [thinningDate, setThinningDate] = React.useState<Dayjs | null>(
    harvest.thinningDate ? dayjs(harvest.thinningDate) : null,
  );
  const [numOfInchesAdded, setNumOfInchesAdded] = React.useState<number>(
    harvest.numOfInchesAdded ? harvest.numOfInchesAdded : 0,
  );

  const harvestInput: HarvestInput = {
    id: harvest.id,
    thinningDate: thinningDate ? thinningDate.toDate() : thinningDate,
    numOfInchesAdded,
  };

  const { mutateAsync } = HarvestAPI.useUpdateAuto();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Autosave
        data={harvestInput}
        patchApiCall={mutateAsync}
        showPopup={true}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>
      <PreliminaryWorkHarvestWrapper
        input={{
          id: harvest.preliminaryWorkId,
          listingDate: listingDate ? listingDate.toDate() : listingDate,
          soilAnalysisComments,
          prefieldAnalysisComments,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Divider>
            <Chip label="Soil Analysis" />
          </Divider>
          <SoilAnalysis
            parentId={harvest.preliminaryWorkId}
            soilAnalysisFiles={harvest.preliminaryWork?.soilAnalysisFiles}
            useUploadFile={PreliminaryWorkAPI.useUploadFile}
          />
          <TextField
            label="Soil Analysis Comments"
            multiline
            rows={4}
            value={soilAnalysisComments}
            onChange={(e) => setSoilAnalysisComments(e.target.value)}
            disabled={!harvest.preliminaryWorkId}
            fullWidth
          />
          <Divider>
            <Chip label="Prefield Work" />
          </Divider>
          <TextField
            label="Prefield Analysis Comments"
            multiline
            rows={4}
            value={prefieldAnalysisComments}
            onChange={(e) => setPrefieldAnalysisComments(e.target.value)}
            disabled={!harvest.preliminaryWorkId}
          />
          <PrefieldMaterials
            actualNumAcres={harvest?.lot?.numOfAcres}
            materialApplicableId={harvest.preliminaryWorkId}
            materialApplicableType={"PreliminaryWork"}
          />
          <Divider>
            <Chip label="Field Work" />
          </Divider>
          <ListingDateWithMaterials
            actualNumAcres={harvest?.lot?.numOfAcres}
            materialApplicableId={harvest.preliminaryWorkId}
            materialApplicableType={"PreliminaryWork"}
            listingDate={listingDate}
            setListingDate={setListingDate}
          />
        </Box>
      </PreliminaryWorkHarvestWrapper>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GroundPrepSelect
            groundPrepAttachments={
              harvest?.preliminaryWork?.groundPrepAttachments
            }
            groundPrepAttachableType={"PreliminaryWork"}
            groundPrepAttachableId={harvest.preliminaryWorkId}
          />
        </Grid>
        {/* Thinning Date */}
        {/* TODO: missing the number of inches that you added */}
        <Grid item xs={12} md={3}>
          <DatePicker
            label="Thinning Date"
            value={thinningDate}
            onChange={(value) => setThinningDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Plant Spacing in Inches"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={numOfInchesAdded}
            onChange={(e) => {
              const val = e.target.value;
              if (isEmpty(val)) {
                setNumOfInchesAdded(0);
              } else {
                setNumOfInchesAdded(parseInt(val));
              }
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <HoeDateList harvest={harvest} />
      <CultivationList harvest={harvest} />
    </Box>
  );
}
