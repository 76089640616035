import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import {
  MaterialApplication,
  MaterialApplicationInput,
  MaterialApplicationPhase,
  SimpleListResponseData,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleMutationError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    materialApplicableId,
    materialApplicableType,
    phase,
  }: {
    materialApplicableId?: number;
    materialApplicableType: string;
    phase: MaterialApplicationPhase;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<MaterialApplication>>({
      cacheTime: 0,
      queryKey: [
        "material_applications",
        materialApplicableType,
        materialApplicableId?.toString(),
        phase,
      ],
      queryFn: () =>
        handleSimpleList({
          url: "material_applications",
          params: [
            { key: "material_applicable_type", value: materialApplicableType },
            { key: "material_applicable_id", value: materialApplicableId },
            { key: "phase", value: phase },
          ],
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useListByHarvest: (harvestId: number | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<MaterialApplication>>({
      cacheTime: 0,
      queryKey: ["material_applications", "Harvest", harvestId?.toString()],
      queryFn: () =>
        handleSimpleList({
          url: "material_applications/harvest",
          params: [{ key: "harvest_id", value: harvestId }],
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useDetail: (id: string | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<MaterialApplication>({
      cacheTime: 0,
      queryKey: ["material_applications", id],
      queryFn: () => handleDetail({ baseUrl: "material_applications", id }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useSave: (materialApplicationInput: MaterialApplicationInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<MaterialApplication>({
      mutationFn: () =>
        handleSave({
          baseUrl: "material_applications",
          input: materialApplicationInput,
        }),
      onError: handleFetchError(enqueueSnackbar),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "material_applications",
          data.materialApplicableType,
          data.materialApplicableId.toString(),
        ]);
      },
    });
  },
  useSaveByHarvest: ({
    materialApplicationInput,
    harvestId,
  }: {
    materialApplicationInput: MaterialApplicationInput;
    harvestId: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<MaterialApplication>({
      mutationFn: () =>
        handleSave({
          baseUrl: "material_applications",
          input: materialApplicationInput,
        }),
      onError: handleFetchError(enqueueSnackbar),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "material_applications",
          "Harvest",
          harvestId.toString(),
        ]);
      },
    });
  },
  useDelete: (id: number | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<MaterialApplication>({
      mutationFn: () => handleDelete({ baseUrl: "material_applications", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "material_applications",
          data.materialApplicableType,
          data.materialApplicableId.toString(),
        ]);
      },
    });
  },
};

export default methods;
