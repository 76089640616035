import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isEmpty, isNil } from "lodash";
import { useSnackbar } from "notistack";
import pluralize from "pluralize";
import React from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

import {
  FieldVariety,
  FieldVarietyInput,
  MeasuringUnit,
  SimpleListResponseData,
  TrialVariety,
  TrialVarietyInput,
  Variety,
} from "../types";
import MeasuringUnitSelect from "./MeasuringUnitSelect";
import VarietySearchableAutocomplete from "./VarietySearchableAutocomplete";

type Props = {
  harvestId: number;
  type: "TrialVariety" | "FieldVariety";
  modelAPI: {
    useHarvestList: (
      harvestId: number,
    ) => UseQueryResult<
      SimpleListResponseData<FieldVariety | TrialVariety>,
      unknown
    >;
    useSave: (
      fieldTrialVarietyInput: FieldVarietyInput | TrialVarietyInput,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDelete: (
      id: number,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDetail: (id: string | undefined) => UseQueryResult;
  };
};

export default function PlantedVarietyList({
  type,
  harvestId,
  modelAPI,
}: Props) {
  const { data } = modelAPI.useHarvestList(harvestId);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const title = pluralize(
    type === "FieldVariety" ? "Field Variety" : "Trial Variety",
  );
  return (
    <Paper
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        px: "1rem",
        py: "1rem",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Button
          onClick={() => {
            setShowModal(true);
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>
      <Divider />
      {data && !isEmpty(data) ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            py: 1.5,
            px: 0.5,
            m: 0,
            gap: "1rem",
          }}
          component="ul"
        >
          {data.map((fieldVariety) => {
            return (
              <FieldTrialVarietyChip
                key={fieldVariety.id}
                fieldTrialVariety={fieldVariety}
                modelAPI={modelAPI}
                type={type}
              />
            );
          })}
        </Box>
      ) : (
        <Typography variant="body1">No {title} have been added.</Typography>
      )}
      <CreateFieldTrialVarietyModal
        harvestId={harvestId}
        open={showModal}
        onClose={() => setShowModal(false)}
        modelAPI={modelAPI}
        type={type}
      />
    </Paper>
  );
}

type FieldTrialVarietyChipProps = {
  fieldTrialVariety: FieldVariety | TrialVariety;
  type: "TrialVariety" | "FieldVariety";
  modelAPI: {
    useHarvestList: (
      harvestId: number,
    ) => UseQueryResult<
      SimpleListResponseData<FieldVariety | TrialVariety>,
      unknown
    >;
    useSave: (
      fieldTrialVarietyInput: FieldVarietyInput | TrialVarietyInput,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDelete: (
      id: number,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDetail: (id: string | undefined) => UseQueryResult;
  };
};
function FieldTrialVarietyChip({
  fieldTrialVariety,
  type,
  modelAPI,
}: FieldTrialVarietyChipProps) {
  const theme = useTheme();

  const [openModel, setOpenModal] = React.useState<boolean>(false);
  const { mutateAsync } = modelAPI.useDelete(fieldTrialVariety.id);

  const handleDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          border: `2px solid ${theme.palette.grey["300"]}`,
          borderRadius: 1,
          p: "1rem",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography onClick={() => setOpenModal(true)} variant="body1">
            {fieldTrialVariety?.variety?.name}
          </Typography>
          <IconButton aria-label="delete" onClick={handleDelete}>
            <ClearIcon />
          </IconButton>
        </Box>
        <Divider light={true} />
        <Box onClick={() => setOpenModal(true)}>
          <Typography variant="body2">
            Number of Acres: {fieldTrialVariety.numAcres}
          </Typography>
          <Typography variant="body2">
            Number of Beds: {fieldTrialVariety.numBeds}
          </Typography>
          <Typography variant="body2">
            Seed Spacing: {fieldTrialVariety.numSeedBoxes}
          </Typography>
          <Typography variant="body2">
            Seed Usage: {fieldTrialVariety.seedUsage}{" "}
            {fieldTrialVariety?.measuringUnit?.name}
          </Typography>
          <Typography variant="body2">
            Seed Lot #: {fieldTrialVariety.seedLotNum}
          </Typography>
          <Typography variant="body2">
            Seed Count: {fieldTrialVariety.seedCount}
          </Typography>
          <Typography variant="body2">
            Seed Company: {fieldTrialVariety.variety?.seedCompany?.name}
          </Typography>
        </Box>
      </Box>
      <CreateFieldTrialVarietyModal
        harvestId={fieldTrialVariety.harvestId}
        fieldTrialVariety={fieldTrialVariety}
        open={openModel}
        onClose={() => setOpenModal(false)}
        modelAPI={modelAPI}
        type={type}
      />
    </React.Fragment>
  );
}

type CreateFieldTrialVarietyModalProps = {
  harvestId: number;
  fieldTrialVariety?: FieldVariety | TrialVariety;
  open: boolean;
  type: "TrialVariety" | "FieldVariety";
  onClose: () => void;
  modelAPI: {
    useHarvestList: (
      harvestId: number,
    ) => UseQueryResult<
      SimpleListResponseData<FieldVariety | TrialVariety>,
      unknown
    >;
    useSave: (
      fieldTrialVarietyInput: FieldVarietyInput | TrialVarietyInput,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDelete: (
      id: number,
    ) => UseMutationResult<FieldVariety | TrialVariety, unknown, void, unknown>;
    useDetail: (id: string | undefined) => UseQueryResult;
  };
};
function CreateFieldTrialVarietyModal({
  harvestId,
  fieldTrialVariety,
  open,
  type,
  onClose,
  modelAPI,
}: CreateFieldTrialVarietyModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [searchedVariety, setSearchedVariety] = React.useState<
    Variety | undefined
  >(undefined);
  const [numAcres, setNumAcres] = React.useState<number | undefined>(
    fieldTrialVariety?.numAcres,
  );
  const [numBeds, setNumBeds] = React.useState<string>(
    fieldTrialVariety?.numBeds || "",
  );
  const [numSeedBoxes, setNumSeedBoxes] = React.useState<number | undefined>(
    fieldTrialVariety?.numSeedBoxes,
  );
  const [seedLotNum, setSeedLotNum] = React.useState<string>(
    fieldTrialVariety?.seedLotNum || "",
  );
  const [seedUsage, setSeedUsage] = React.useState<string>(
    fieldTrialVariety?.seedUsage || "",
  );
  const [seedCount, setSeedCount] = React.useState<string>(
    fieldTrialVariety?.seedCount || "",
  );
  const [measuringUnit, setMeasuringUnit] = React.useState<
    MeasuringUnit | undefined
  >(fieldTrialVariety?.measuringUnit);

  const { mutateAsync } = modelAPI.useSave({
    id: fieldTrialVariety?.id,
    harvestId,
    measuringUnitId: measuringUnit?.id,
    varietyId: fieldTrialVariety
      ? fieldTrialVariety.varietyId
      : searchedVariety?.id,
    numAcres,
    numBeds,
    numSeedBoxes,
    seedLotNum,
    seedUsage,
    seedCount,
  });

  const createFieldTrialVariety = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (isNil(fieldTrialVariety) && isNil(searchedVariety)) {
      return enqueueSnackbar("Must select a Variety to proceed.", {
        variant: "warning",
      });
    }
    try {
      await mutateAsync();
      setSearchedVariety(undefined);
      if (isNil(fieldTrialVariety?.id)) {
        setNumAcres(undefined);
        setNumBeds("");
        setNumSeedBoxes(undefined);
        setSeedLotNum("");
        setMeasuringUnit(undefined);
        setSeedUsage("");
        setSeedCount("");
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        {fieldTrialVariety ? (
          <Box>
            <Typography variant="h6">
              {fieldTrialVariety.variety?.name}
            </Typography>
          </Box>
        ) : (
          <VarietySearchableAutocomplete
            label={"Search for Variety"}
            variety={searchedVariety}
            setVariety={setSearchedVariety}
          />
        )}
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Number of Acres"
              type="number"
              value={numAcres}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNil(e.target.value) || e.target.value.length === 0) {
                  setNumAcres(undefined);
                } else {
                  const newVal = parseFloat(e.target.value);
                  if (newVal >= 0) setNumAcres(newVal);
                  if (newVal < 0) setNumAcres(0);
                }
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              label={type === "FieldVariety" ? "Number of Beds" : "Bed Numbers"}
              value={numBeds}
              onChange={(event) => {
                setNumBeds(event.target.value);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Seed Spacing"
              type="number"
              value={numSeedBoxes}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNumSeedBoxes(parseFloat(event.target.value));
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <MeasuringUnitSelect
              usage={"seeds"}
              measuringUnit={measuringUnit}
              setMeasuringUnit={setMeasuringUnit}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Seed Lot #"
              value={seedLotNum}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeedLotNum(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Seed Count"
              value={seedCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeedCount(event.target.value);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Seed Usage"
              value={seedUsage}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeedUsage(event.target.value);
              }}
              fullWidth
            />
          </Box>
        </Box>
        <Button onClick={createFieldTrialVariety} variant="contained">
          Save
        </Button>
        {/* add small text hint below, explaining search for a variety to create a new field variety */}
      </Box>
    </Modal>
  );
}
