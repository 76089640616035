import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
  Region,
  RegionInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
} from "./handler";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Region[]>({
      queryKey: ["regions", query],
      queryFn: () => handleSearch({ baseUrl: "regions", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (regionInput: RegionInput) => {
    const navigate = useNavigate();
    return useMutation<Region>({
      mutationFn: () => handleSave({ baseUrl: "regions", input: regionInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Region>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Region>>({
      cacheTime: 0,
      queryKey: ["regions", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "regions", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Region>({
      cacheTime: 0,
      queryKey: ["region", id],
      queryFn: () => handleDetail({ baseUrl: "regions", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Region>({
      mutationFn: () => handleDelete({ baseUrl: "regions", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
