import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React from "react";

import { Commodity, Ranch, ReportDateType, Shipper } from "../types";
import CommodityMultiSelectSearch from "./CommodityMultiSelectSearch";
import RanchSearch from "./RanchSearch";
import ShipperSearch from "./ShipperSearch";

type Props = {
  startDate: Dayjs | null;
  setStartDate: (arg: Dayjs | null) => void;
  endDate: Dayjs | null;
  setEndDate: (arg: Dayjs | null) => void;
  dateType: ReportDateType;
  setDateType: (arg: ReportDateType) => void;
  commodities: Commodity[];
  setCommodities: (arg: Commodity[]) => void;
  shipper?: Shipper;
  setShipper: (arg?: Shipper) => void;
  ranch?: Ranch;
  setRanch: (arg?: Ranch) => void;
};

export default function HarvestFilters({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateType,
  setDateType,
  commodities,
  setCommodities,
  shipper,
  setShipper,
  ranch,
  setRanch,
}: Props) {
  const handleDateTypeChange = (event: SelectChangeEvent) => {
    setDateType(event.target.value as ReportDateType);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1, maxWidth: "12rem" }}>
          <FormControl fullWidth>
            <InputLabel id="date-filter-select-label">Date Type</InputLabel>
            <Select
              labelId="date-filter-select-label"
              id="date-filter-select"
              value={dateType}
              label="Date Type"
              onChange={handleDateTypeChange}
            >
              <MenuItem value={"est_harvest_date"}>Est. Harvest Date</MenuItem>
              <MenuItem value={"projected_wet_date"}>Project Wet Date</MenuItem>
              <MenuItem value={"actual_wet_date"}>Wet Date</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(value) => setStartDate(value)}
            sx={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(value) => setEndDate(value)}
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <CommodityMultiSelectSearch
            commodities={commodities}
            setCommodities={setCommodities}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ShipperSearch shipper={shipper} setShipper={setShipper} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <RanchSearch ranch={ranch} setRanch={setRanch} />
        </Box>
      </Box>
    </Box>
  );
}
