import { Box, Button, Modal, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";

type Props<TypeInput, TypeModel> = {
  open: boolean;
  onClose: () => void;
  navigateUrl: string;
  modelApiUseSave: (
    arg0: TypeInput,
  ) => UseMutationResult<TypeModel, unknown, void, unknown>;
};
export default function BasicCreateModal<
  TypeInput,
  TypeModel extends { id: number },
>({
  open,
  onClose,
  navigateUrl,
  modelApiUseSave,
}: Props<TypeInput, TypeModel>) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");

  const { mutateAsync } = modelApiUseSave({ name } as TypeInput);

  const handleCreate = async () => {
    try {
      if (isEmpty(name)) {
        enqueueSnackbar("Missing required field", {
          variant: "error",
        });
      }
      const model: TypeModel = await mutateAsync();
      onClose();
      return navigate(`${navigateUrl}/${model.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value as string)
          }
        />
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
