import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import UserAPI from "../api/User.api";
import Layout from "../components/Layout";
import { useSessionContext } from "../contexts/Session.context";
import { User } from "../types";
import { HttpError } from "../types/http-error";

export default function ProfilePage() {
  const { session } = useSessionContext();

  const { data: user } = UserAPI.useDetail(session?.id);

  return (
    <Layout>
      <Container maxWidth={"sm"} disableGutters>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography>Profile</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {user && <ProfileBasicFields user={user} />}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "2rem",
            }}
          >
            {user && <UpdateEmailModal user={user} />}
            {user && <UpdatePasswordModal user={user} />}
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}

type PropsProfileBasicFields = {
  user: User;
};

function ProfileBasicFields({ user }: PropsProfileBasicFields) {
  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = React.useState(user?.firstName || "");
  const [lastName, setLastName] = React.useState(user?.lastName || "");

  const { mutateAsync } = UserAPI.useSave({
    id: user.id,
    firstName,
    lastName,
  });

  const handleSubmit = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully");
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
    }
  };

  return (
    <React.Fragment>
      <TextField
        label="First Name"
        fullWidth
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextField
        label="Last Name"
        fullWidth
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <Button variant="contained" onClick={handleSubmit} fullWidth>
        Save
      </Button>
    </React.Fragment>
  );
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UpdateEmailModal({ user }: { user: User }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);
  const [newEmail, setNewEmail] = React.useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const { mutateAsync } = UserAPI.useUpdateEmail({
    id: user.id,
    email: newEmail,
  });

  const handleSubmit = async () => {
    try {
      const res = await mutateAsync();
      enqueueSnackbar(res.message);
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)} fullWidth>
        Update Email
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Email
            </Typography>
            <TextField
              label="New Email"
              variant="outlined"
              fullWidth
              value={newEmail}
              onChange={(event) => setNewEmail(event.target.value)}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function UpdatePasswordModal({ user }: { user: User }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] =
    React.useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const { mutateAsync } = UserAPI.useSave({
    id: user.id,
    password: newPassword,
    passwordConfirmation: confirmNewPassword,
  });

  const handleSubmit = async () => {
    if (newPassword !== confirmNewPassword && newPassword.length === 0) {
      enqueueSnackbar("Invalid new password", { variant: "error" });
      return;
    }
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)} fullWidth>
        Update Password
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Password
            </Typography>
            <TextField
              label="Current Password"
              variant="outlined"
              type="password"
              fullWidth
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <TextField
              label="Confirm New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
