import { Box } from "@mui/material";
import React from "react";

import PreliminaryWorkAPI from "../api/PreliminaryWork.api";
import { PreliminaryWorkInput } from "../types";
import Autosave from "./Autosave";

type Props = {
  input: PreliminaryWorkInput;
  children: JSX.Element;
};
export default function PreliminaryWorkHarvestWrapper({
  input,
  children,
}: Props) {
  const { mutateAsync } = PreliminaryWorkAPI.useUpdateAuto();

  return (
    <React.Fragment>
      {input && input?.id && (
        <Autosave data={input} patchApiCall={mutateAsync} showPopup={true} />
      )}
      <Box>{children}</Box>
    </React.Fragment>
  );
}
