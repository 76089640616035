import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, TableCell, Typography } from "@mui/material";
import React from "react";

import CommodityAPI from "../api/Commodity.api";
import BasicCreateModal from "../components/BasicCreateModal";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import { Commodity, TableColumn } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<Commodity>[] = [
  { field: "name", label: "Name" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(commodity: Commodity): JSX.Element[] {
  return [
    <TableCell key={"name"}>{commodity.name}</TableCell>,
    <TableCell key={"createdAt"}>{extractDate(commodity.createdAt)}</TableCell>,
  ];
}

function buildRowSX(commodity: Commodity): object {
  return {
    backgroundColor: commodity?.color || "#fff",
  };
}

export default function CommodityList() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Commodities</Typography>
          <Button onClick={() => setOpenModal(true)}>
            <AddCircleIcon />
          </Button>
        </Box>

        <ListModel
          defaultOrderBy={"name"}
          modelApiUseList={CommodityAPI.useList}
          modelBaseUrl={"commodities"}
          columns={columns}
          buildTableRow={buildTableRow}
          buildRowSX={buildRowSX}
        />

        <BasicCreateModal
          open={openModel}
          onClose={() => setOpenModal(false)}
          modelApiUseSave={CommodityAPI.useSave}
          navigateUrl="/commodities"
        />
      </Box>
    </Layout>
  );
}
