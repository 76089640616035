import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import MeasuringUnitAPI from "../api/MeasuringUnit.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import {
  MeasuringUnit,
  MeasuringUnitInput,
  MeasuringUnitUsage,
} from "../types";

export default function MeasuringUnitShowPage() {
  const { id } = useParams();
  const { data: measuringUnit } = MeasuringUnitAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Measuring Units</Typography>
        </Box>

        {measuringUnit && <MeasuringUnitForm measuringUnit={measuringUnit} />}
      </Box>
    </Layout>
  );
}

function MeasuringUnitForm({
  measuringUnit,
}: {
  measuringUnit: MeasuringUnit;
}) {
  const [name, setName] = React.useState<string>(measuringUnit.name);
  const [usage, setUsage] = React.useState<MeasuringUnitUsage>(
    measuringUnit.usage || "seeds",
  );

  const input: MeasuringUnitInput = {
    ...measuringUnit,
    name,
    usage,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={MeasuringUnitAPI.useSave}
      modelApiUseDelete={MeasuringUnitAPI.useDelete}
      modelInput={input}
    >
      <React.Fragment>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="measuring-unit-usage-select-label">Type</InputLabel>
          <Select
            labelId="measuring-unit-usage-select-label"
            id="measuring-unit-usage-select"
            value={usage}
            label="Type"
            onChange={(event) => {
              setUsage(event.target.value as MeasuringUnitUsage);
            }}
          >
            <MenuItem value={"applied_materials"}>Applied Materials</MenuItem>
            <MenuItem value={"seeds"}>Seeds</MenuItem>
          </Select>
        </FormControl>
      </React.Fragment>
    </BasicShowEdit>
  );
}
