import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { isNil } from "lodash";
import React from "react";

import GroundPrepAPI from "../api/GroundPrep.api";
import { useSessionContext } from "../contexts/Session.context";
import {
  GroundPrep,
  GroundPrepAttachableType,
  GroundPrepAttachment,
} from "../types";
import Loading from "./Loading";

type Props = {
  groundPrepAttachments?: GroundPrepAttachment[];
  groundPrepAttachableId?: number;
  groundPrepAttachableType: GroundPrepAttachableType;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: GroundPrep) => option.name,
});

export default function GroundPrepSelect({
  groundPrepAttachments,
  groundPrepAttachableId,
  groundPrepAttachableType,
}: Props) {
  const { session } = useSessionContext();
  const { data, isLoading } = GroundPrepAPI.useAll(
    session && session.businessUnitId,
  );

  const [selectedGroundPreps, setSelectedGroundPreps] = React.useState<
    GroundPrep[]
  >(
    groundPrepAttachments
      ? groundPrepAttachments.map((hgp) => hgp.groundPrep)
      : [],
  );

  const { mutate } = GroundPrepAPI.useAttachToGroundPrepAttachable({
    groundPrepAttachableId,
    groundPrepAttachableType,
    groundPrepIds: selectedGroundPreps.map((gp) => gp.id),
  });

  React.useEffect(() => {
    if (groundPrepAttachableId) {
      mutate();
    }
  }, [
    groundPrepAttachableId,
    groundPrepAttachableType,
    selectedGroundPreps,
    mutate,
  ]);

  if (isNil(data) || isLoading) {
    return <Loading />;
  }

  return (
    <Autocomplete
      disabled={!groundPrepAttachableId}
      multiple
      id="checkboxes-ground-prep-select"
      options={data}
      value={selectedGroundPreps}
      onChange={(event: any, newValue: GroundPrep[]) => {
        setSelectedGroundPreps(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: "100%" }}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ground Prep List"
          placeholder="Ground Prep Actions"
        />
      )}
    />
  );
}
