import { Box } from "@mui/material";
import React from "react";

import AreaAPI from "../api/Area.api";
import { Area } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentArea: (area: Area) => void;
  area?: Area;
};

export default function AreaCreatableAutocomplete({
  area,
  setCurrentArea,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(area?.name);
  const [value, setValue] = React.useState<Area | string>(
    area ? area.name : "",
  );

  const { data } = AreaAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Area"
        dialogTitle="Add a new Area"
        noOptionsText="No results"
        setModel={(area: Area) => {
          setCurrentArea(area);
          setSearchValue(area.name);
          setValue(area.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={AreaAPI.useSave}
      />
    </Box>
  );
}
