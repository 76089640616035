import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import VarietyAPI from "../api/Variety.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import SeedCompanySearchableAutocomplete from "../components/SeedCompanySearchableAutocomplete";
import SelectMultipleCommodities from "../components/SelectMultipleCommodities";
import { Commodity, SeedCompany, Variety, VarietyInput } from "../types";

export default function VarietyShow() {
  const { id } = useParams();
  const { data: variety } = VarietyAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Varieties</Typography>
        </Box>

        {variety && <VarietyForm variety={variety} />}
      </Box>
    </Layout>
  );
}

function VarietyForm({ variety }: { variety: Variety }) {
  const [seedCompany, setSeedCompany] = React.useState<SeedCompany | undefined>(
    variety.seedCompany,
  );
  const [name, setName] = React.useState<string>(variety.name);
  const [commodities, setCommodities] = React.useState<Commodity[]>(
    variety.commodities,
  );

  const input: VarietyInput = {
    ...variety,
    name,
    seedCompanyId: seedCompany?.id,
    commodityIds: commodities.map((commodity) => commodity.id),
  };

  return (
    <BasicShowEdit
      modelApiUseSave={VarietyAPI.useSave}
      modelApiUseDelete={VarietyAPI.useDelete}
      modelInput={input}
    >
      <React.Fragment>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <SeedCompanySearchableAutocomplete
          label="Select Seed Company"
          seedCompany={seedCompany}
          setSeedCompany={setSeedCompany}
        />
        <SelectMultipleCommodities
          commodities={commodities}
          setCommodities={setCommodities}
        />
      </React.Fragment>
    </BasicShowEdit>
  );
}
