import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ApplicatorCompany,
  ApplicatorCompanyInput,
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
} from "./handler";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<ApplicatorCompany[]>({
      queryKey: ["applicator_companies", query],
      queryFn: () => handleSearch({ baseUrl: "applicator_companies", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (applicatorCompanyInput: ApplicatorCompanyInput) => {
    const navigate = useNavigate();
    return useMutation<ApplicatorCompany>({
      mutationFn: () =>
        handleSave({
          baseUrl: "applicator_companies",
          input: applicatorCompanyInput,
        }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useList: ({
    order,
    orderBy,
    page,
    query,
  }: BaseQueryProps<ApplicatorCompany>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<ApplicatorCompany>>({
      cacheTime: 0,
      queryKey: ["applicator_companies", order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "applicator_companies",
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ApplicatorCompany>({
      cacheTime: 0,
      queryKey: ["applicator_company", id],
      queryFn: () => handleDetail({ baseUrl: "applicator_companies", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<ApplicatorCompany>({
      mutationFn: () => handleDelete({ baseUrl: "applicator_companies", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
