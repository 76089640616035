import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
  SeedCompany,
  SeedCompanyInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
} from "./handler";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<SeedCompany[]>({
      queryKey: ["seed_companies", query],
      queryFn: () => handleSearch({ baseUrl: "seed_companies", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (seedCompanyInput: SeedCompanyInput) => {
    const navigate = useNavigate();
    return useMutation<SeedCompany>({
      mutationFn: () =>
        handleSave({ baseUrl: "seed_companies", input: seedCompanyInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<SeedCompany>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<SeedCompany>>({
      cacheTime: 0,
      queryKey: ["seed_companies", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "seed_companies", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<SeedCompany>({
      cacheTime: 0,
      queryKey: ["seed_company", id],
      queryFn: () => handleDetail({ baseUrl: "seed_companies", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<SeedCompany>({
      mutationFn: () => handleDelete({ baseUrl: "seed_companies", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
