import { isNil } from "lodash";
import React from "react";

import RanchAPI from "../api/Ranch.api";
import { Ranch } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  ranch?: Ranch;
  setRanch: (ranch?: Ranch) => void;
};

export default function RanchSearch({ ranch, setRanch }: Props) {
  const [query, setQuery] = React.useState<string>("");

  const { data } = RanchAPI.useSearch({ query });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  const value = isNil(ranch)
    ? { label: query, value: query }
    : { label: ranch.name, value: ranch };

  return (
    <SearchBarAutocomplete
      label={"Ranch"}
      inputValue={query}
      setSearchValue={setQuery}
      options={options}
      noOptionsText={"Ranch not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue)) {
          setRanch(undefined);
        } else {
          setRanch(newValue.value);
        }
      }}
    />
  );
}
