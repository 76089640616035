import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import randomstring from "randomstring";
import React from "react";
import { UseQueryResult } from "react-query";

import HarvestAPI from "../api/Harvest.api";
import { Harvest, HarvestInput, Lot, Sublot, SublotInput } from "../types";
import Autosave from "./Autosave";
import LotSearch from "./LotSearch";

type Props = {
  harvest: Harvest;
  refetchHarvest: () => Promise<UseQueryResult>;
};

export default function SplitSublotModal({ harvest, refetchHarvest }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [lot, setLot] = React.useState<Lot | Sublot | undefined>(harvest?.lot);

  const [open, setOpen] = React.useState(false);
  const [sublots, setSublots] = React.useState<Record<string, any>>({});

  const [sublotNum, setSublotNum] = React.useState<string>("");
  const [sublotAcres, setSublotAcres] = React.useState<number | undefined>(0);

  const { mutateAsync } = HarvestAPI.useSplitIntoSublots({
    harvestId: harvest.id,
    lotId: lot?.id,
    currentSublotNum: sublotNum,
    currentSublotNumOfAcres: sublotAcres,
    sublots: Object.values(sublots),
  });

  const handleOpen = () => {
    if (lot) {
      setOpen(true);
    } else {
      enqueueSnackbar(
        "Not able to split a harvest into sublots if a lot has not been choosen",
        {
          variant: "error",
        },
      );
    }
  };

  const handleAddSublot = () => {
    setSublots((sublots) => {
      sublots[randomstring.generate()] = { num: "", numOfAcres: undefined };
      return { ...sublots };
    });
  };

  const handleRemoveSublot = (hashKey: string) => () => {
    setSublots((sublots) => {
      delete sublots[hashKey];
      return { ...sublots };
    });
  };

  const handleSublotEdit =
    ({ hashKey, key }: { hashKey: string; key: keyof SublotInput }) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSublots((sublots) => {
        if (key === "numOfAcres") {
          if (isNil(event.target.value) || event.target.value.length === 0) {
            sublots[hashKey][key] = undefined;
          } else {
            let newVal = parseFloat(event.target.value);
            if (newVal >= 0) sublots[hashKey][key] = newVal;
            if (newVal < 0) sublots[hashKey][key] = 0;
          }
        } else {
          sublots[hashKey][key] = event.target.value;
        }
        return { ...sublots };
      });
    };

  const totalNumOfAcres = () => {
    let total = sublotAcres || 0;
    Object.values(sublots).forEach((sublot) => {
      const val = sublot.numOfAcres;
      if (Number(val) === val) total += val;
    });
    return total;
  };

  const handleSplit = async () => {
    try {
      const sublot = await mutateAsync();
      setLot(sublot);
      enqueueSnackbar("Successfully split harvest into sublots.", {
        variant: "success",
      });
      await refetchHarvest();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const harvestInput: HarvestInput = {
    id: harvest.id,
    lotableId: lot?.id,
    lotableType: lot?.lotableType,
  };

  const { mutateAsync: autoSaveMutateAsync } = HarvestAPI.useUpdateAuto();

  const patchApiCall = async (input: HarvestInput) => {
    await autoSaveMutateAsync(input);
    await refetchHarvest();
  };

  return (
    <React.Fragment>
      <Autosave
        data={harvestInput}
        patchApiCall={patchApiCall}
        showPopup={true}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "row" },
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <LotSearch lot={lot} setLot={setLot} />
        </Box>
        {lot && (
          <Box sx={{ flex: 1 }}>
            <TextField
              label={"Number of Acres"}
              value={lot.numOfAcres || ""}
              fullWidth
              disabled
            />
          </Box>
        )}
        {lot?.lotableType === "Lot" && (
          <Button onClick={handleOpen} variant="outlined">
            Split into Sublots
          </Button>
        )}
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6">Split lot into sublots</Typography>
          <Typography variant="body1">
            Total acres in lot: {lot && lot.numOfAcres}
          </Typography>
          <Typography variant="body1">
            Sum of sublots acres {totalNumOfAcres()}
          </Typography>
          <Box>
            <Typography variant="body2">
              Current harvest will be sublot...
            </Typography>
            <Box
              sx={{
                marginTop: "0.5rem",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <TextField
                label={"Sublot Num"}
                value={sublotNum}
                onChange={(event) => setSublotNum(event.target.value)}
              />
              <TextField
                label="Acres in sublot"
                value={sublotAcres}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  if (isNil(e.target.value) || e.target.value.length === 0) {
                    setSublotAcres(undefined);
                  } else {
                    const newVal = parseFloat(e.target.value);
                    if (newVal >= 0) setSublotAcres(newVal);
                    if (newVal < 0) setSublotAcres(0);
                  }
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button onClick={handleAddSublot}>
                <AddCircleIcon />
              </Button>
              <Typography variant="body1">Add Sublots</Typography>
            </Box>
            {Object.keys(sublots).map((hashKey) => {
              const sublot = sublots[hashKey];
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    label={"Sublot Name"}
                    value={sublot["num"]}
                    onChange={handleSublotEdit({ key: "num", hashKey })}
                  />
                  <TextField
                    label="Acres in sublot"
                    value={sublot["numOfAcres"]}
                    onChange={handleSublotEdit({ key: "numOfAcres", hashKey })}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button onClick={handleRemoveSublot(hashKey)}>
                    <RemoveIcon />
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Button variant="contained" onClick={handleSplit}>
            Split
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
