import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import VarietyAPI from "../api/Variety.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import SeedCompanySearchableAutocomplete from "../components/SeedCompanySearchableAutocomplete";
import { SeedCompany, TableColumn, Variety } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<Variety>[] = [
  { field: "name", label: "Name" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(variety: Variety): JSX.Element[] {
  return [
    <TableCell key={"name"}>{variety.name}</TableCell>,
    <TableCell key={"createdAt"}>{extractDate(variety.createdAt)}</TableCell>,
  ];
}

export default function VarietyList() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Varieties</Typography>
          <Button onClick={() => setOpenModal(true)}>
            <AddCircleIcon />
          </Button>
        </Box>

        <ListModel
          defaultOrderBy={"name"}
          modelApiUseList={VarietyAPI.useList}
          modelBaseUrl={"varieties"}
          columns={columns}
          buildTableRow={buildTableRow}
        />
        <VarietyModal open={openModel} onClose={() => setOpenModal(false)} />
      </Box>
    </Layout>
  );
}

type VarietyModalProps = {
  open: boolean;
  onClose: () => void;
};
function VarietyModal({ open, onClose }: VarietyModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [seedCompany, setSeedCompany] = React.useState<
    SeedCompany | undefined
  >();

  const { mutateAsync } = VarietyAPI.useSave({
    name,
    seedCompanyId: seedCompany?.id,
  });

  const handleCreate = async () => {
    try {
      if (isEmpty(name)) {
        enqueueSnackbar("Missing required field", {
          variant: "error",
        });
      }
      const model: Variety = await mutateAsync();
      onClose();
      return navigate(`/varieties/${model.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value as string)
          }
        />
        <SeedCompanySearchableAutocomplete
          label="Select Seed Company"
          seedCompany={seedCompany}
          setSeedCompany={setSeedCompany}
        />
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
