import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import RanchAPI from "../api/Ranch.api";
import AreaCreatableAutocomplete from "../components/AreaCreatableAutocomplete";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import RanchLotList from "../components/RanchLotList";
import RegionCreatableAutocomplete from "../components/RegionCreatableAutocomplete";
import { Area, Ranch, RanchInput, Region } from "../types";

export default function RanchShow() {
  const { id } = useParams();
  const { data: ranch } = RanchAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Ranches</Typography>
        </Box>

        {ranch && <RanchForm ranch={ranch} />}
      </Box>
    </Layout>
  );
}

function RanchForm({ ranch }: { ranch: Ranch }) {
  const [name, setName] = React.useState<string>(ranch.name);
  const [area, setArea] = React.useState<Area | undefined>(ranch.area);
  const [region, setRegion] = React.useState<Region | undefined>(ranch.region);

  const input: RanchInput = {
    ...ranch,
    name,
    areaId: area?.id,
    regionId: region?.id,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={RanchAPI.useSave}
      modelApiUseDelete={RanchAPI.useDelete}
      modelInput={input}
    >
      <React.Fragment>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <AreaCreatableAutocomplete
          area={area}
          setCurrentArea={(newArea: Area) => setArea(newArea)}
        />
        <RegionCreatableAutocomplete
          region={region}
          setCurrentRegion={(newRegion: Region) => setRegion(newRegion)}
        />
        <RanchLotList ranch={ranch} />
      </React.Fragment>
    </BasicShowEdit>
  );
}
