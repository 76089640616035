import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import PreharvestAPI from "../api/Preharvest.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import {
  PreharvestQuestion,
  PreharvestQuestionInput,
  TableColumn,
} from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<PreharvestQuestion>[] = [
  { field: "question", label: "Question" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(preharvestQuestion: PreharvestQuestion): JSX.Element[] {
  return [
    <TableCell key={"question"}>{preharvestQuestion.question}</TableCell>,
    <TableCell key={"createdAt"}>
      {extractDate(preharvestQuestion.createdAt)}
    </TableCell>,
  ];
}

export default function PreharvestQuestionListPage() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Preharvest Question Lists</Typography>
          <Button onClick={() => setOpenModal(true)}>
            <AddCircleIcon />
          </Button>
        </Box>

        <ListModel
          defaultOrderBy={"question"}
          modelApiUseList={PreharvestAPI.useQuestionList}
          modelBaseUrl={"preharvest_questions"}
          columns={columns}
          buildTableRow={buildTableRow}
        />
        <PreharvestQuestionCreateModal
          open={openModel}
          onClose={() => setOpenModal(false)}
        />
      </Box>
    </Layout>
  );
}

type PreharvestQuestionCreateModalProps = {
  open: boolean;
  onClose: () => void;
};
function PreharvestQuestionCreateModal({
  open,
  onClose,
}: PreharvestQuestionCreateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [question, setQuestion] = React.useState<string>("");

  const { mutateAsync } = PreharvestAPI.useQuestionSave({
    question,
  } as PreharvestQuestionInput);

  const handleCreate = async () => {
    try {
      if (isEmpty(question)) {
        enqueueSnackbar("Missing required field", {
          variant: "error",
        });
      }
      const model: PreharvestQuestion = await mutateAsync();
      onClose();
      return navigate(`/preharvest_questions/${model.id}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Question"
          variant="outlined"
          value={question}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setQuestion(event.target.value as string)
          }
        />
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
