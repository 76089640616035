import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert, Avatar, Box, Container, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

import UserAPI from "../api/User.api";
import Copyright from "../components/Copyright";
import Loading from "../components/Loading";

export default function EmailConfirmation() {
  const { token } = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);

  const { mutate, isLoading, isError, error, isSuccess, data } =
    UserAPI.useEmailVerification(token);

  React.useEffect(() => {
    if (!isEmpty(token)) {
      setLoading(false);

      mutate();
    }
  }, [token, mutate]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Email Confirmation
        </Typography>
        {isError && error instanceof Error && (
          <Alert severity="error">{error?.message}</Alert>
        )}
        {isSuccess && <Alert severity="success">{data?.message}</Alert>}
        <Box sx={{ marginTop: 2 }}>{(loading || isLoading) && <Loading />}</Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
