import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  ListResponseData,
  PreharvestAnswerArchive,
  PreharvestAnswerInput,
  PreharvestEvaluation,
  PreharvestQuestion,
  PreharvestQuestionArchive,
  PreharvestQuestionInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSimpleList,
} from "./handler";

// import queryClient from "./queryClient";

const methods = {
  useQuestionList: ({
    order,
    orderBy,
    page,
    query,
  }: BaseQueryProps<PreharvestQuestion>) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<ListResponseData<PreharvestQuestion>>({
      cacheTime: 0,
      queryKey: ["preharvest_questions", order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "preharvest_questions",
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useQuestionDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<PreharvestQuestion>({
      cacheTime: 0,
      queryKey: ["preharvest_questions", id],
      queryFn: () => handleDetail({ baseUrl: "preharvest_questions", id }),
      onError: handleQueryError(navigate),
    });
  },
  useQuestionSave: (preharvestQuestionInput: PreharvestQuestionInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreharvestQuestion>({
      mutationFn: () =>
        handleSave({
          baseUrl: "preharvest_questions",
          input: preharvestQuestionInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useQuestionSaveAndArchiveOnEdit: (
    preharvestQuestionInput: PreharvestQuestionInput,
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreharvestQuestionArchive>({
      mutationFn: () =>
        handleSave({
          baseUrl: "preharvest_questions",
          input: preharvestQuestionInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useQuestionDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreharvestQuestionArchive>({
      mutationFn: () => handleDelete({ baseUrl: "preharvest_questions", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useAnswerSave: (preharvestAnswerInput: PreharvestAnswerInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreharvestAnswerArchive>({
      mutationFn: () =>
        handleSave({
          baseUrl: `preharvest_questions/${preharvestAnswerInput.preharvestQuestionId}/preharvest_answers`,
          input: preharvestAnswerInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useAnswerDelete: (preharvestQuestionId: number, id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreharvestAnswerArchive>({
      mutationFn: () =>
        handleDelete({
          baseUrl: `preharvest_questions/${preharvestQuestionId}/preharvest_answers`,
          id,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useListQuestionsWithAnswers: ({
    businessUnitId,
  }: {
    businessUnitId?: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<PreharvestQuestion[]>({
      cacheTime: 5000,
      queryKey: [
        "preharvest_questions",
        businessUnitId?.toString(),
        "with_answers",
      ],
      queryFn: () =>
        handleSimpleList({ url: "preharvest_questions/with_answers" }),
      onError: handleFetchError(enqueueSnackbar),
      enabled: !isNil(businessUnitId),
    });
  },
  useCreateEvaluations: ({
    harvestId,
    preharvestQuestionId,
    preharvestAnswerIds,
  }: {
    harvestId: number;
    preharvestQuestionId: number;
    preharvestAnswerIds: number[];
  }) => {
    return useMutation<PreharvestEvaluation[]>({
      mutationFn: () =>
        handleSave({
          baseUrl: `preharvest_questions/evaluate`,
          input: {
            harvestId,
            preharvestQuestionId,
            preharvestAnswerIds,
          },
        }),
    });
  },
  useListEvaluations: ({
    harvestId,
    preharvestQuestionId,
  }: {
    harvestId: number;
    preharvestQuestionId: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<PreharvestEvaluation[]>({
      cacheTime: 0,
      queryKey: ["preharvest_evaluations", harvestId, preharvestQuestionId],
      queryFn: () =>
        handleSimpleList({
          url: "preharvest_questions/evaluations",
          params: [
            { key: "harvest_id", value: harvestId },
            { key: "preharvest_question_id", value: preharvestQuestionId },
          ],
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
};

export default methods;
