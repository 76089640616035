// import { BusinessUnitProvider } from "./contexts/BusinessUnit.context"
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { SnackbarProvider, closeSnackbar } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import queryClient from "./api/queryClient";
import { SessionProvider } from "./contexts/Session.context";
import reportWebVitals from "./reportWebVitals";
// import './index.css';
// import CssBaseline from "@mui/material/CssBaseline";
// import { ThemeProvider } from "@mui/material/styles";
// import theme from "./theme";
import HoladayThemeProvider from "./theme/HoladayThemeProvider";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("holaday/farmer");
  setupLogRocketReact(LogRocket);
  // Sentry.init({
  //   dsn: SentryURL,
  //   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });
  // LogRocket.getSessionURL((sessionURL) => {
  // Sentry.configureScope((scope) => {
  //     scope.setExtra("sessionURL", sessionURL);
  //   });
  // });
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HoladayThemeProvider>
          <BrowserRouter>
            <SessionProvider>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                action={(snackbarId) => (
                  <Button
                    onClick={() => closeSnackbar(snackbarId)}
                    variant="outlined"
                    color="secondary"
                  >
                    Dismiss
                  </Button>
                )}
              >
                <ProSidebarProvider>
                  <App />
                </ProSidebarProvider>
              </SnackbarProvider>
            </SessionProvider>
          </BrowserRouter>
        </HoladayThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
