import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  PreliminaryWork,
  PreliminaryWorkInput,
  SimpleListResponseData,
} from "../types";
import {
  handleBasicPost,
  handleDetail,
  handleFetch,
  handleFetchError,
  handleFileUpload,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useListByLot: (lotId: string | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<PreliminaryWork>>({
      cacheTime: 0,
      queryKey: ["preliminary_works", lotId],
      queryFn: () =>
        handleSimpleList({
          url: `lots/${lotId}/preliminary_works`,
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<PreliminaryWork>({
      cacheTime: 0,
      queryKey: ["preliminary_work", id],
      queryFn: () => handleDetail({ baseUrl: "preliminary_works", id }),
      onError: handleQueryError(navigate),
    });
  },
  useUploadFile: ({ id, files }: { id?: number; files: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreliminaryWork>({
      mutationFn: () =>
        handleFileUpload({
          url: `preliminary_works/${id}/soil_analysis_files`,
          files,
          paramName: "files",
        }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useSave: (input: PreliminaryWorkInput) => {
    const navigate = useNavigate();
    return useMutation<PreliminaryWork>({
      mutationFn: () =>
        handleSave({
          baseUrl: "preliminary_works",
          input: { preliminaryWork: input },
        }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useCreateAndAttachHarvest: ({ harvestId }: { harvestId: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<PreliminaryWork>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "preliminary_works/create_and_attach_harvest",
          input: { harvestId },
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async () => {
        await queryClient.invalidateQueries(["harvest", harvestId.toString()]);
      },
    });
  },
  useAttachToHarvest: ({ harvestId }: { harvestId: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: ({
        preliminaryWorkId,
        harvestId,
      }: {
        preliminaryWorkId?: number;
        harvestId: number;
      }) =>
        handleBasicPost({
          baseUrl: "preliminary_works/attach_to_harvest",
          input: { preliminaryWorkId, harvestId },
        }),
      mutationKey: ["attach_to_harvest"],
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async () => {
        await queryClient.invalidateQueries(["harvest", harvestId.toString()]);
      },
    });
  },
  useFetchAvailableByLot: (lotId: number | string | undefined) => {
    return useQuery<PreliminaryWork>({
      queryFn: () =>
        handleFetch({ url: `lots/${lotId}/preliminary_work_available` }),
      queryKey: ["preliminary_work_available", lotId?.toString()],
      cacheTime: 0,
      enabled: !!lotId,
    });
  },
  useUpdateAuto: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: (input: PreliminaryWorkInput) =>
        handleSave({
          baseUrl: "preliminary_works",
          input,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
};
export default methods;
