import { Autocomplete, Chip, TextField } from "@mui/material";
// import { createFilterOptions } from "@mui/material/Autocomplete";
import { filter } from "lodash";
import React from "react";

import CommodityAPI from "../api/Commodity.api";
import { Commodity } from "../types";

type Props = {
  commodities: Commodity[];
  setCommodities: (commodities: Commodity[]) => void;
};

type CommodityOption = { label: string; value: Commodity };

export default function CommodityMultiSelectSearch({
  commodities,
  setCommodities,
}: Props) {
  const [query, setQuery] = React.useState<string>("");
  const { data } = CommodityAPI.useSearch({ query });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Autocomplete
      freeSolo
      multiple
      autoHighlight
      filterSelectedOptions
      id="commodity-search-multi-select-outlined"
      options={options}
      filterOptions={(options, params) => {
        const selectedCommodityIds = commodities.map(
          (commodity) => commodity.id,
        );
        const filtered = filter(
          options,
          (option: CommodityOption) =>
            !selectedCommodityIds.includes(option.value.id),
        );
        return filtered;
      }}
      renderTags={(value: readonly CommodityOption[], getTagProps) =>
        value.map((option: CommodityOption, index: number) => (
          <Chip
            variant="outlined"
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(event: any, newValues: (CommodityOption | string)[]) => {
        const commodityOptions = filter(
          newValues,
          (obj: CommodityOption | string) => typeof obj !== "string",
        ) as CommodityOption[];
        setCommodities(commodityOptions.map((obj) => obj.value));
      }}
      value={commodities.map((commodity) => ({
        label: commodity.name,
        value: commodity,
      }))}
      onInputChange={(event, newQuery) => {
        setQuery(newQuery);
      }}
      disableCloseOnSelect
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Commodities"
          placeholder="Filter by commodities"
        />
      )}
    />
  );
}
