import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";

import ReportAPI from "../api/Report.api";
// import ArchiveButton from "../components/ArchiveButton";
import Layout from "../components/Layout";
import { reportStateToName, reportTypeToName } from "../utils";

export default function ReportShowPage() {
  const { id } = useParams();
  const { data } = ReportAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h2">Report Info</Typography>
        {data && (
          <Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="File Name"
                variant="standard"
                value={data.filename}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="File Type"
                variant="standard"
                value={reportTypeToName(data.reportType)}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="State"
                variant="standard"
                value={reportStateToName(data.state)}
                fullWidth
              />
            </Box>
            {data.state === "error" && (
              <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                Error: {data.errorMessage}
              </Box>
            )}
            {data.file && (
              <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <Link target="_blank" to={data.file}>
                  Download File
                </Link>
              </Box>
            )}
            <Box
              sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}
            ></Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

// type RerunChargebackFileProps = {
//   id: number;
// };

// function RerunChargebackFile({ id }: RerunChargebackFileProps) {
//   const { mutateAsync, isLoading } = ChargebackFileAPI.useRerun(id);
//   const { showSnackbar } = useSnackbarContext();

//   const handleClick = async () => {
//     const res = await mutateAsync();
//     if ("errors" in res) {
//       res.errors.forEach((message: string) => showSnackbar(message, "error"));
//     } else {
//       showSnackbar("Reran file successfully", "success");
//     }
//   };

//   return (
//     <Button onClick={handleClick} variant="contained" disabled={isLoading}>
//       Rerun
//     </Button>
//   );
// }

// type ArchiveChargebackFileButtonProps = {
//   id: number;
// };

// function ArchiveChargebackFileButton({ id }: ArchiveChargebackFileButtonProps) {
//   const navigate = useNavigate();

//   const { mutateAsync } = ChargebackFileAPI.useDelete(id);
//   const { showSnackbar } = useSnackbarContext();

//   const onDelete = async () => {
//     const res = await mutateAsync();
//     if ("errors" in res) {
//       res.errors.forEach((message: string) => showSnackbar(message, "error"));
//     } else {
//       showSnackbar("Archived successfully", "success");
//       navigate("/chargebacks/files");
//     }
//   };

//   return <ArchiveButton onDelete={onDelete} />;
// }
