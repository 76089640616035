import { Box } from "@mui/material";
import React from "react";

import SubGrowerAPI from "../api/SubGrower.api";
import { Subgrower } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentSubGrower: (subgrower: Subgrower) => void;
  subgrower: Subgrower;
};

export default function SubGrowerCreatableAutocomplete({
  subgrower,
  setCurrentSubGrower,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(subgrower?.name);
  const [value, setValue] = React.useState<Subgrower | string>(
    subgrower ? subgrower.name : "",
  );

  const { data } = SubGrowerAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a SubGrower"
        dialogTitle="Add a new SubGrower"
        noOptionsText="No results"
        setModel={(subgrower: Subgrower) => {
          setCurrentSubGrower(subgrower);
          setSearchValue(subgrower.name);
          setValue(subgrower.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={SubGrowerAPI.useSave}
      />
    </Box>
  );
}
