import { isEmpty, isNil } from "lodash";
import LogRocket from "logrocket";
import React from "react";

import { Session } from "../types";

// import LogRocket from "logrocket";

type Props = {
  children: JSX.Element;
};

interface ISessionContext {
  session?: Session;
  loadedSessionStorage: boolean;
  setSession: (p: Session | undefined) => void;
}

const SessionContext = React.createContext<Partial<ISessionContext>>({});

export function SessionProvider({ children }: Props) {
  const [loadedSessionStorage, setLoadedSessionStorage] = React.useState(false);
  const [session, setSession] = React.useState<Session | undefined>();

  React.useEffect(() => {
    const data = window.localStorage.getItem(
      process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
    );
    if (!(isNil(data) || isEmpty(data))) {
      setSession(JSON.parse(data));
    }
    setLoadedSessionStorage(true);
  }, []);

  React.useEffect(() => {
    if (session) {
      window.localStorage.setItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        JSON.stringify(session),
      );
      if (process.env.NODE_ENV === "production") {
        LogRocket.identify(session.id, {
          email: session.email,
        });
      }
    }
    if (session === undefined) {
      window.localStorage.removeItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
      );
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        loadedSessionStorage,
        setSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  return React.useContext(SessionContext);
}
