import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

import { Criterium } from "../types";
import EditCriteriumModal from "./EditCriteriumModal";

type Props = {
  criteria: Criterium[];
  setCriteria: (arg: Criterium[]) => void;
  replaceCriterium: (arg: Criterium) => void;
  removeCriterium: (arg: Criterium) => void;
};
export default function CriteriaDragAndDrop({
  criteria,
  setCriteria,
  replaceCriterium,
  removeCriterium,
}: Props) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = criteria.findIndex(
        (criterium: Criterium) => criterium.id === active.id,
      );
      const newIndex = criteria.findIndex(
        (criterium: Criterium) => criterium.id === over?.id,
      );
      const newlyOrdedCriteria = arrayMove(criteria, oldIndex, newIndex);
      const updatedCriteria = newlyOrdedCriteria.map((c, index) => ({
        ...c,
        orderId: index,
      }));
      setCriteria(updatedCriteria);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={criteria} strategy={verticalListSortingStrategy}>
        {criteria
          .sort((a, b) => a.orderId - b.orderId)
          .map((criterium) => (
            <SortableItem
              key={criterium.id}
              criterium={criterium}
              replaceCriterium={replaceCriterium}
              removeCriterium={removeCriterium}
            />
          ))}
      </SortableContext>
    </DndContext>
  );
}

function SortableItem({
  criterium,
  replaceCriterium,
  removeCriterium,
}: {
  criterium: Criterium;
  replaceCriterium: (arg: Criterium) => void;
  removeCriterium: (arg: Criterium) => void;
}) {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: criterium.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <React.Fragment>
      <div ref={setNodeRef} style={style} {...attributes}>
        <Paper
          elevation={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: "0.25rem",
            mb: "0.25rem",
            p: "1rem",
          }}
        >
          <Box sx={{ flex: 1, paddingRight: "0.5rem" }} {...listeners}>
            <ViewHeadlineIcon />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{ cursor: "pointer" }}
              variant="h6"
              onClick={() => setOpenModal(true)}
            >
              {criterium.name}
            </Typography>
          </Box>
        </Paper>
      </div>
      <EditCriteriumModal
        open={openModel}
        onClose={() => setOpenModal(false)}
        criterium={criterium}
        replaceCriterium={replaceCriterium}
        removeCriterium={removeCriterium}
      />
    </React.Fragment>
  );
}
