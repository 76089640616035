import { Box, Divider } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import {
  Commodity,
  HarvestList,
  HarvestStateFilter,
  Order,
  Ranch,
  ReportDateType,
  Shipper,
  TableColumn,
} from "../types";
import HarvestFilters from "./HarvestFilters";
import ListTable from "./ListTable";
import Loading from "./Loading";

type Props = {
  buildTableRow: (arg0: HarvestList) => JSX.Element[];
  buildRowSX?: (arg0: HarvestList) => object;
  columns: TableColumn<HarvestList>[];
  defaultOrderBy: keyof HarvestList;
  harvestStateFilter: HarvestStateFilter;
  modelBaseUrl: string;
  openFilter: boolean;
  setOpenFilter: (arg: boolean) => void;
};

export default function HarvestFilterList({
  modelBaseUrl,
  columns,
  buildTableRow,
  buildRowSX,
  openFilter,
  setOpenFilter,
  defaultOrderBy,
  harvestStateFilter,
}: Props) {
  const navigate = useNavigate();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof HarvestList>(defaultOrderBy); // TODO

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [dateType, setDateType] =
    React.useState<ReportDateType>("est_harvest_date");

  const [commodities, setCommodities] = React.useState<Commodity[]>([]);
  const [ranch, setRanch] = React.useState<Ranch | undefined>();
  const [shipper, setShipper] = React.useState<Shipper | undefined>();

  const { data } = HarvestAPI.useList({
    baseUrl: modelBaseUrl,
    commodities,
    dateType,
    endDate: endDate ? endDate.toDate() : endDate,
    harvestStateFilter,
    order,
    orderBy,
    page,
    ranch,
    shipper,
    startDate: startDate ? startDate.toDate() : startDate,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof HarvestList,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (model: HarvestList) => (event: unknown) => {
    navigate(`/${modelBaseUrl}/${model.id}`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {openFilter && (
        <React.Fragment>
          <HarvestFilters
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateType={dateType}
            setDateType={setDateType}
            commodities={commodities}
            setCommodities={setCommodities}
            shipper={shipper}
            setShipper={setShipper}
            ranch={ranch}
            setRanch={setRanch}
          />
          <Divider />
        </React.Fragment>
      )}
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          buildRowSX={buildRowSX}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </Box>
  );
}
