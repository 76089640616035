import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, TableCell, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import HarvestCreateModal from "../components/HarvestCreateModal";
import HarvestFilterList from "../components/HarvestFilterList";
import Layout from "../components/Layout";
import { HarvestList, TableColumn } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<HarvestList>[] = [
  { field: "commodity", label: "Crop", orderBy: false },
  { field: "shipper", label: "Shipper", orderBy: false },
  { field: "ranch", label: "Ranch", orderBy: false },
  { field: "lotNum", label: "Lot #", orderBy: false },
  { field: "desiredNumAcres", label: "Desired Acres", orderBy: false },
  { field: "transplantDate", label: "Transplant Date", orderBy: true },
  { field: "projectedWetDate", label: "Projected Wet Date", orderBy: true },
  { field: "estHarvestDate", label: "Est. Harvest Date", orderBy: true },
  { field: "fieldVarieties", label: "Variety", orderBy: false },
  { field: "bedWidth", label: "Bed Width", orderBy: false },
  { field: "seedLine", label: "Seed Lines", orderBy: false },
];

function buildTableRow(harvest: HarvestList): JSX.Element[] {
  return [
    <TableCell key={"commodity"}>
      {harvest.commodity ? harvest.commodity.name : "N/A"}
    </TableCell>,
    <TableCell key={"shipper"}>
      {harvest.shipper ? harvest.shipper.name : "N/A"}
    </TableCell>,
    <TableCell key={"ranch"}>
      {harvest.lot ? harvest.lot?.ranch?.name : "N/A"}
    </TableCell>,
    <TableCell key={"lotNum"}>{harvest.lot?.num || "N/A"}</TableCell>,
    <TableCell key={"desiredNumAcres"}>
      {harvest.desiredNumAcres || "N/A"}
    </TableCell>,
    <TableCell key={"transplantDate"}>
      {extractDate(harvest.transplantDate) || "N/A"}
    </TableCell>,
    <TableCell key={"projectedWetDate"}>
      {extractDate(harvest.projectedWetDate) || "N/A"}
    </TableCell>,
    <TableCell key={"estHarvestDate"}>
      {extractDate(harvest.estHarvestDate) || "N/A"}
    </TableCell>,
    <TableCell key={"fieldVarieties"}>
      {harvest.fieldVarieties
        ? harvest.fieldVarieties.map((v) => v?.variety?.name).join(", ")
        : "N/A"}
    </TableCell>,
    <TableCell key={"bedWidth"}>{harvest.bedWidth || "N/A"}</TableCell>,
    <TableCell key={"seedLine"}>{harvest.seedLine || "N/A"}</TableCell>,
  ];
}

function buildRowSX(harvest: HarvestList): object {
  return {
    backgroundColor: harvest?.commodity?.color,
  };
}

export default function PlantingScheduleListPage() {
  const navigate = useNavigate();
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  const { mutateAsync } = HarvestAPI.useCreateScheduled({});

  const handleCreate = async () => {
    try {
      const newHarvest = await mutateAsync();
      return navigate(`/planting-schedule/${newHarvest.id}`);
    } catch (error) {
      console.error(error);
    }
    setOpenModal(true);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h2">Planting Schedule</Typography>
          </Box>
          <Box
            sx={{
              flexShrink: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/*<Button onClick={handleGenerateFile}>
              <FileDownloadIcon />
            </Button>*/}
            <Button onClick={() => setOpenFilter(!openFilter)}>
              <FilterListIcon />
            </Button>
            <Button onClick={handleCreate}>
              <AddCircleIcon />
            </Button>
          </Box>
        </Box>

        <HarvestFilterList
          defaultOrderBy={"projectedWetDate"}
          columns={columns}
          buildTableRow={buildTableRow}
          buildRowSX={buildRowSX}
          harvestStateFilter="scheduled_plant"
          modelBaseUrl={"planting-schedule"}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
        />

        <HarvestCreateModal
          harvestSchedule={"Planting"}
          open={openModel}
          onClose={() => setOpenModal(false)}
        />
      </Box>
    </Layout>
  );
}
