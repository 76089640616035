import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { BaseQueryProps, ListResponseData, User, UserInput } from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({ order, orderBy, page, query }: BaseQueryProps<User>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<User>>({
      cacheTime: 0,
      queryKey: ["users", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "users", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<User>({
      cacheTime: 0,
      queryKey: ["user", id],
      enabled: id !== undefined,
      queryFn: () => handleDetail({ baseUrl: "users", id }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (userInput: UserInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<User>({
      mutationFn: () => handleSave({ baseUrl: "users", input: userInput }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<User>({
      mutationFn: () => handleDelete({ baseUrl: "users", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useUpdateEmail: ({ id, email }: { id: number; email: string }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{ message: string }>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `users/${id}/update_current_email`,
          input: { email },
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useEmailVerification: (token?: string) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{ message: string }>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `users/email_verification`,
          input: { token },
        }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useForgotPassword: (email?: string) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{ message: string }>({
      mutationFn: () =>
        handleBasicPost({ baseUrl: "password/forgot", input: { email } }),
      retry: 2,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useResetPassword: ({
    password,
    passwordConfirmation,
    token,
  }: {
    password?: string;
    passwordConfirmation?: string;
    token?: string;
  }) => {
    return useMutation<{ message: string }>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "password/reset",
          input: { password, passwordConfirmation, token },
        }),
      retry: 2,
    });
  },
};
export default methods;
