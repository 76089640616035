import { Box } from "@mui/material";
import React from "react";

import ShipperAPI from "../api/Shipper.api";
import { Shipper } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentShipper: (shipper: Shipper) => void;
  shipper?: Shipper;
};

export default function ShipperCreatableAutocomplete({
  shipper,
  setCurrentShipper,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(shipper?.name);
  const [value, setValue] = React.useState<Shipper | string>(
    shipper ? shipper.name : "",
  );

  const { data } = ShipperAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Shipper"
        dialogTitle="Add a new Shipper"
        noOptionsText="No results"
        setModel={(shipper: Shipper) => {
          setCurrentShipper(shipper);
          setSearchValue(shipper.name);
          setValue(shipper.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={ShipperAPI.useSave}
      />
    </Box>
  );
}
