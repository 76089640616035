import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import BusinessUnitUsersListTable from "../components/BusinessUnitUsersListTable";
import Layout from "../components/Layout";

export default function BusinessUnitUsersPage() {
  return (
    <Layout>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Groups</Typography>
          <Link to={"/business-unit-users/new"}>
            <IconButton aria-label="Create a Group" size="large">
              <AddCircleIcon color="primary" />
            </IconButton>
          </Link>
        </Box>
        <BusinessUnitUsersListTable />
      </Box>
    </Layout>
  );
}
