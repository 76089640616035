import { Box, Chip, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isEmpty } from "lodash";

import HarvestAPI from "../api/Harvest.api";
import EvaluationForm from "./EvaluationForm";
import Loading from "./Loading";

export default function HarvestEvaluations({
  harvestId,
}: {
  harvestId: number;
}) {
  const theme = useTheme();

  const { data: harvest, isLoading } = HarvestAPI.useDetail({ id: harvestId });

  if (isLoading || !harvest) {
    return <Loading />;
  }
  if (!harvest.commodity) {
    return (
      <Typography sx={{ color: theme.palette.error.main }}>
        Please select a Commodity for this Harvest first
      </Typography>
    );
  }
  return (
    <Box>
      {isEmpty(harvest.fieldVarieties) ? (
        <Typography sx={{ color: theme.palette.error.main }}>
          No Field Varieties
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {harvest.fieldVarieties
              .sort((a, b) => b.rank - a.rank)
              .map((fieldVariety) => (
                <EvaluationForm
                  key={fieldVariety.id}
                  harvest={harvest}
                  fieldTrialVariety={fieldVariety}
                  fieldTrialVarietyType={"Field Variety"}
                />
              ))}
          </Box>
          {!isEmpty(harvest.trialVarieties) && (
            <>
              <Divider>
                <Chip label="Trial Varieties" />
              </Divider>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {harvest.trialVarieties
                  .sort((a, b) => b.rank - a.rank)
                  .map((fieldVariety) => (
                    <EvaluationForm
                      key={fieldVariety.id}
                      harvest={harvest}
                      fieldTrialVariety={fieldVariety}
                      fieldTrialVarietyType={"Field Variety"}
                    />
                  ))}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
