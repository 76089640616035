import {
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

import Layout from "../components/Layout";
import ReportCropRotationForm from "../components/ReportCropRotationForm";
import ReportScheduleForm from "../components/ReportScheduleForm";
import { ReportType } from "../types";

export default function ReportSearchPage() {
  const [reportType, setReportType] = React.useState<ReportType | undefined>();
  const handleReportTypeChange = (event: SelectChangeEvent) => {
    setReportType(event.target.value as ReportType);
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Generate a Report</Typography>
        </Box>
        <Box sx={{ maxWidth: "12rem" }}>
          <FormControl fullWidth>
            <InputLabel id="report-type-select-label">Type</InputLabel>
            <Select
              labelId="report-type-select-label"
              id="report-type-select"
              value={reportType}
              label="Type"
              onChange={handleReportTypeChange}
            >
              <MenuItem value={"active_harvest"}>Active Harvest</MenuItem>
              <MenuItem value={"harvest_planting_schedule"}>
                Planting Schedule
              </MenuItem>
              {/*<MenuItem value={"crop_rotation"}>Crop Rotation</MenuItem>*/}
            </Select>
          </FormControl>
        </Box>
        <Divider>
          <Chip label="Filters" />
        </Divider>
        <ReportForm reportType={reportType} />
      </Box>
    </Layout>
  );
}

function ReportForm({ reportType }: { reportType?: ReportType }) {
  if (
    reportType === "active_harvest" ||
    reportType === "harvest_planting_schedule"
  ) {
    return <ReportScheduleForm reportType={reportType} />;
  } else if (reportType === "crop_rotation") {
    return <ReportCropRotationForm />;
  }
  return <Typography variant="body1">Select a report type</Typography>;
}
