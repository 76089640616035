import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";

import { MaterialApplicableType } from "../types";
import MaterialsUsedList from "./MaterialsUsedList";
import MaterialsUsedModal from "./MaterialsUsedModal";

type Props = {
  materialApplicableId?: number;
  materialApplicableType: MaterialApplicableType;
  actualNumAcres?: number;
};
export default function PrefieldMaterials(
  { actualNumAcres, materialApplicableId, materialApplicableType }: Props,
) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "1rem",
          py: "1rem",
        }}
      >
        <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row" }}>
          <Typography variant="subtitle1">
            Prefield Applied Materials
          </Typography>
          <Button
            onClick={() => setOpenModal(true)}
            disabled={!materialApplicableId}
          >
            <AddCircleIcon />
          </Button>
        </Box>
        <Box sx={{ flex: 1 }}>
          <MaterialsUsedList
            actualNumAcres={actualNumAcres}
            materialApplicableId={materialApplicableId}
            materialApplicableType={materialApplicableType}
            phase={"prefielding"}
          />
        </Box>
      </Paper>
      {materialApplicableId && (
        <MaterialsUsedModal
          actualNumAcres={actualNumAcres}
          open={openModal}
          onClose={() => setOpenModal(false)}
          materialApplicableId={materialApplicableId}
          materialApplicableType={materialApplicableType}
          phase={"prefielding"}
          title={"Prefielding"}
        />
      )}
    </React.Fragment>
  );
}
