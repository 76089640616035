import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  CropRotation,
  Harvest,
  ListResponseData,
  Report,
  SimpleListResponseData,
} from "../types";
import {
  handleBasicFetch,
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSimpleList,
} from "./handler";

const methods = {
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Report>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Report>>({
      cacheTime: 0,
      queryKey: ["reports", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "reports", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useCreateHarvestPlanningScheduleExcelReport: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Report>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "reports/create_harvest_planning_schedule_excel_report",
          input: {},
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useCreateActiveHarvestExcelReport: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Report>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "reports/create_active_harvest_excel_report",
          input: {},
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useRunReport: (input: any) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Report>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "reports",
          input,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Report>({
      cacheTime: 0,
      refetchInterval: 5000,
      queryKey: ["report", id],
      queryFn: () => handleDetail({ baseUrl: "reports", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Report>({
      mutationFn: () => handleDelete({ baseUrl: "reports", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useFetchActualVsDesiredShipperData: ({
    shipperIds,
    year,
  }: {
    shipperIds: number[];
    year: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<SimpleListResponseData<Harvest>>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "reports/actual_vs_desired_shipper",
          input: {
            year,
            shipperIds,
          },
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useCropRotation: ({
    lotId,
    numYearsBack,
  }: {
    lotId: number;
    numYearsBack: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<CropRotation>>({
      queryFn: () =>
        handleSimpleList({
          url: `reports/crop_rotation_by_lot`,
          params: [
            { key: "lot_id", value: lotId },
            { key: "num_years_back", value: numYearsBack },
          ],
        }),
      queryKey: ["crop_rotation_by_lot", lotId.toString(), numYearsBack],
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useCropRotationMeta: ({ numYearsBack }: { numYearsBack: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<{ [year: number]: number }>({
      queryFn: () =>
        handleBasicFetch({
          url: `reports/crop_rotation_meta`,
          params: [{ key: "num_years_back", value: numYearsBack }],
        }),
      queryKey: ["crop_rotation_meta", numYearsBack],
      onError: handleFetchError(enqueueSnackbar),
    });
  },
};

export default methods;
