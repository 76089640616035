import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import SeedCompanyAPI from "../api/SeedCompany.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { SeedCompany, SeedCompanyInput } from "../types";

export default function SeedCompanyShow() {
  const { id } = useParams();
  const { data: seedCompany } = SeedCompanyAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Seed Companies</Typography>
        </Box>

        {seedCompany && <SeedCompanyForm seedCompany={seedCompany} />}
      </Box>
    </Layout>
  );
}

function SeedCompanyForm({ seedCompany }: { seedCompany: SeedCompany }) {
  const [name, setName] = React.useState<string>(seedCompany.name);

  const input: SeedCompanyInput = {
    ...seedCompany,
    name,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={SeedCompanyAPI.useSave}
      modelApiUseDelete={SeedCompanyAPI.useDelete}
      modelInput={input}
    >
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </BasicShowEdit>
  );
}
