import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import AreaAPI from "../api/Area.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { Area, AreaInput } from "../types";

export default function AreaShow() {
  const { id } = useParams();
  const { data: area } = AreaAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Areas</Typography>
        </Box>

        {area && <AreaForm area={area} />}
      </Box>
    </Layout>
  );
}

function AreaForm({ area }: { area: Area }) {
  const [name, setName] = React.useState<string>(area.name);

  const input: AreaInput = {
    ...area,
    name,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={AreaAPI.useSave}
      modelApiUseDelete={AreaAPI.useDelete}
      modelInput={input}
    >
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </BasicShowEdit>
  );
}
