import { Box, Button, Modal, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import PreharvestAPI from "../api/Preharvest.api";
import { PreharvestAnswer, PreharvestAnswerArchive } from "../types";

type Props = {
  open: boolean;
  onClose: () => void;
  preharvestAnswer?: PreharvestAnswer;
  preharvestQuestionId: number;
  replacePreharvestAnswer: (arg: PreharvestAnswer) => void;
  removePreharvestAnswer: (arg: PreharvestAnswer) => void;
};

export default function CreatePreharvestAnswerModal({
  open,
  onClose,
  preharvestAnswer,
  preharvestQuestionId,
  replacePreharvestAnswer,
  removePreharvestAnswer,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [answer, setAnswer] = React.useState<string | undefined>(
    preharvestAnswer?.answer || "",
  );

  const { mutateAsync } = PreharvestAPI.useAnswerSave({
    preharvestQuestionId: preharvestQuestionId,
    answer,
    id: preharvestAnswer?.id,
  });

  const handleSave = async () => {
    try {
      if (isEmpty(answer)) {
        enqueueSnackbar("Requires an Answer.");
        return;
      }
      const preharvestAnswerArchive: PreharvestAnswerArchive =
        await mutateAsync();
      if (preharvestAnswerArchive.archivedPreharvestQuestion) {
        navigate(`/preharvest_questions/${preharvestQuestionId}`);
      } else {
        replacePreharvestAnswer(preharvestAnswerArchive.preharvestAnswer);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteMutateAsync } = PreharvestAPI.useAnswerDelete(
    preharvestQuestionId,
    preharvestAnswer?.id,
  );

  const handleDelete = async () => {
    try {
      const preharvestAnswerArchive: PreharvestAnswerArchive =
        await deleteMutateAsync();
      if (preharvestAnswerArchive.archivedPreharvestQuestion) {
        navigate(`/preharvest_questions/${preharvestQuestionId}`);
      } else {
        removePreharvestAnswer(preharvestAnswerArchive.preharvestAnswer);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-preharvest-answer"
      aria-describedby="modal-preharvest-answer-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Answer"
          value={answer}
          onChange={(event) => {
            setAnswer(event.target.value);
          }}
          fullWidth
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Button onClick={handleSave} variant="contained" fullWidth>
            Save
          </Button>
          {preharvestAnswer?.id && (
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
