import { Alert, Box, Button, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React from "react";

import HarvestAPI from "../api/Harvest.api";
import PreliminaryWorkAPI from "../api/PreliminaryWork.api";

type Props = {
  harvestId: number;
};

export default function PreliminaryWorkHarvestAttachedCheck({
  harvestId,
}: Props) {
  const { mutateAsync, isLoading } =
    PreliminaryWorkAPI.useCreateAndAttachHarvest({
      harvestId,
    });
  const { data: harvest } = HarvestAPI.useDetail({
    id: harvestId.toString(),
  });

  const {
    mutateAsync: mutateAttachPreliminaryWork,
    isLoading: isLoadingAttachment,
  } = PreliminaryWorkAPI.useAttachToHarvest({ harvestId });

  const { data: preliminaryWork } = PreliminaryWorkAPI.useFetchAvailableByLot(
    harvest?.lot?.id,
  );

  if (!harvest || isNil(harvest.lot) || isEmpty(harvest.lot)) {
    return <React.Fragment></React.Fragment>;
  }
  if (harvest.preliminaryWork) {
    return <React.Fragment></React.Fragment>;
  }

  const handleAttach = async () => {
    try {
      await mutateAttachPreliminaryWork({
        preliminaryWorkId: preliminaryWork?.id,
        harvestId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    try {
      await mutateAsync();
      // TODO update Harvest also with the preliminary Work Id
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Alert severity="warning">
        <Typography>
          Certain fields have been disabled as there no available associated
          preliminary work with the current Ranch/Lot attached to the Harvest.
        </Typography>
        {preliminaryWork && (
          <Button
            variant="contained"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            onClick={handleAttach}
            disabled={isLoadingAttachment}
            color={"secondary"}
          >
            Attach Available an Available Preliminary Work
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
          onClick={handleCreate}
          disabled={isLoading}
        >
          Create Preliminary Work for Ranch / Lot
        </Button>
      </Alert>
    </Box>
  );
}
