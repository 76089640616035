import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";

import { MetaPagination, Order, TableColumn } from "../types";
import EmptyTableRows from "./EmptyTableRows";

interface EnhancedTableHeadProps<Type> {
  columns: TableColumn<Type>[];
  order: Order;
  orderBy: keyof Type;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Type,
  ) => void;
}

function EnhancedTableHead<Type>({
  columns,
  onRequestSort,
  order,
  orderBy,
}: EnhancedTableHeadProps<Type>) {
  const createSortHandler =
    (property: keyof Type) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, index) => (
          <TableCell
            key={index}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.field ? order : undefined}
          >
            {headCell.orderBy !== false ? (
              <TableSortLabel
                active={headCell.field === orderBy}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
              >
                {headCell.label}
                {orderBy === headCell.field ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableProps<Type> {
  buildTableRow: (arg0: Type) => JSX.Element[];
  buildRowSX?: (arg0: Type) => object;
  columns: TableColumn<Type>[];
  handleChangePage: (event: unknown, newPage: number) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Type,
  ) => void;
  handleRowClick: (arg0: Type) => (event: unknown) => void;
  meta: MetaPagination;
  order: Order;
  orderBy: keyof Type;
  rows?: Type[];
}

export default function ListTable<Type extends { id?: number }>({
  columns,
  buildTableRow,
  buildRowSX,
  handleChangePage,
  handleRequestSort,
  handleRowClick,
  meta,
  order,
  orderBy,
  rows = [],
}: TableProps<Type>) {
  const tableTitle = "tableTitle";

  const page = meta.current - 1;
  const rowsPerPage = meta.perPage;
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
      <Paper sx={{ mb: 2, px: 2, width: "100%" }}>
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader aria-labelledby={tableTitle}>
            <EnhancedTableHead
              columns={columns}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {rows.map((row, index) => {
                const sx = buildRowSX ? buildRowSX(row) : {};
                return (
                  <TableRow
                    hover
                    onClick={handleRowClick(row)}
                    key={index}
                    tabIndex={-1}
                    sx={{ ...sx, cursor: "poniter" }}
                  >
                    {buildTableRow(row)}
                  </TableRow>
                );
              })}

              <EmptyTableRows colSpan={columns.length} emptyRows={emptyRows} />
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[meta.perPage]}
          component="div"
          count={meta.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
