import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { Box, TextField } from "@mui/material";
import React from "react";

import { SubcriteriumInput } from "../types";

type Props = {
  subcriteria: SubcriteriumInput[];
  setSubcriteria: (arg: SubcriteriumInput[]) => void;
};
export default function SubcriteriaDragAndDropCreation({
  subcriteria,
  setSubcriteria,
}: Props) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = subcriteria.findIndex(
        (subcriterium: SubcriteriumInput) => subcriterium.id === active.id,
      );
      const newIndex = subcriteria.findIndex(
        (subcriterium: SubcriteriumInput) => subcriterium.id === over?.id,
      );
      const newlyOrdedSubcriteria = arrayMove(subcriteria, oldIndex, newIndex);
      const updatedSubcriteria = newlyOrdedSubcriteria.map((c, index) => ({
        ...c,
        orderId: index,
      }));
      setSubcriteria(updatedSubcriteria);
    }
  };

  const setSubcriterium = (subcriterium: SubcriteriumInput) => {
    const newSubcriteria = subcriteria.map((item: SubcriteriumInput) => {
      if (item.id === subcriterium.id) {
        return subcriterium;
      } else {
        return item;
      }
    });
    setSubcriteria(newSubcriteria);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={subcriteria}
        strategy={verticalListSortingStrategy}
      >
        {subcriteria
          .sort((a, b) => a.orderId - b.orderId)
          .map((subcriterium) => (
            <SortableItem
              key={subcriterium.id}
              subcriterium={subcriterium}
              setSubcriterium={setSubcriterium}
            />
          ))}
      </SortableContext>
    </DndContext>
  );
}

type SortableItemProps = {
  subcriterium: SubcriteriumInput;
  setSubcriterium: (arg0: SubcriteriumInput) => void;
};

function SortableItem({ subcriterium, setSubcriterium }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: subcriterium.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ flex: 1, paddingRight: "0.5rem" }} {...listeners}>
          <ViewHeadlineIcon />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <TextField
            label="Name"
            value={subcriterium.name}
            onChange={(event) => {
              setSubcriterium({ ...subcriterium, name: event.target.value });
            }}
            fullWidth
          />
          <TextField
            label="Score"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={subcriterium.score}
            onChange={(event) => {
              setSubcriterium({
                ...subcriterium,
                score: parseInt(event.target.value),
              });
            }}
          />
          <TextField
            label="Weight"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={subcriterium.weight}
            onChange={(event) => {
              setSubcriterium({
                ...subcriterium,
                weight: parseInt(event.target.value),
              });
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
