import { Box, Paper } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import NavigationBar from "./NavigationBar";
import NavigationMenu from "./NavigationMenu";

type Props = {
  children: JSX.Element;
};

export default function Layout({ children }: Props) {
  // const theme = useTheme();
  // const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  // const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  // const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));
  // const matchesXl = useMediaQuery(theme.breakpoints.up("xl"));
  // let maxWidth = "sx";
  // if (matchesXl) {
  //   maxWidth = "xl";
  // } else if (matchesLg) {
  //   maxWidth = "lg";
  // } else if (matchesMd) {
  //   maxWidth = "md";
  // } else if (matchesSm) {
  //   maxWidth = "sm";
  // }
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        // alignItems: "stretch",
        // justifyContent: "stretch",
      }}
    >
      <NavigationMenu />
      <Paper sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <NavigationBar />
        <Box
          // maxWidth={maxWidth}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingBottom: "1rem",
            pl: "1rem",
            pr: "1rem",
          }}
        >
          {children}
        </Box>
      </Paper>
    </Box>
  );
}
