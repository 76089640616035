import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  SimpleListResponseData,
  TrialVariety,
  TrialVarietyInput,
} from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useHarvestList: (harvestId: number) => {
    const navigate = useNavigate();
    return useQuery<SimpleListResponseData<TrialVariety>>({
      cacheTime: 0,
      queryKey: ["harvests", harvestId.toString(), "trial_varieties"],
      queryFn: () =>
        handleSimpleList({ url: `harvests/${harvestId}/trial_varieties` }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<TrialVariety>({
      cacheTime: 0,
      queryKey: ["trial_varieties", id?.toString()],
      queryFn: () => handleDetail({ baseUrl: "trial_varieties", id }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (trialVarietyInput: TrialVarietyInput) => {
    const navigate = useNavigate();
    return useMutation<TrialVariety>({
      mutationFn: () =>
        handleSave({ baseUrl: "trial_varieties", input: trialVarietyInput }),
      onError: handleQueryError(navigate),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "trial_varieties",
        ]);
      },
    });
  },
  useSaveSimple: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: (trialVarietyInput: TrialVarietyInput) =>
        handleSave({
          baseUrl: "trial_varieties",
          input: trialVarietyInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useDelete: (id: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TrialVariety>({
      mutationFn: () => handleDelete({ baseUrl: "trial_varieties", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
        ]);
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "trial_varieties",
        ]);
      },
    });
  },
  useDeleteImage: ({ id, imageId }: { id: number; imageId: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TrialVariety>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `trial_varieties/${id}/image_delete`,
          input: { imageId },
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["trial_varieties", id.toString()]);
      },
    });
  },
};
export default methods;
