import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import TractorAPI from "../api/Tractor.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { Tractor, TractorInput } from "../types";

export default function TractorShow() {
  const { id } = useParams();
  const { data: tractor } = TractorAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Tractors</Typography>
        </Box>

        {tractor && <TractorForm tractor={tractor} />}
      </Box>
    </Layout>
  );
}

function TractorForm({ tractor }: { tractor: Tractor }) {
  const [name, setName] = React.useState<string>(tractor.name);

  const input: TractorInput = {
    ...tractor,
    name,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={TractorAPI.useSave}
      modelApiUseDelete={TractorAPI.useDelete}
      modelInput={input}
    >
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </BasicShowEdit>
  );
}
