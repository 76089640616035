import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import CommodityAPI from "../api/Commodity.api";
import CriteriaShow from "../components/CriteriaShow";
import Layout from "../components/Layout";
import Loading from "../components/Loading";

export default function CommodityShow() {
  const { id } = useParams();
  const { data: commodity } = CommodityAPI.useDetail(id);

  if (!commodity) {
    return <Loading />;
  }

  // save commodity will only save the order
  // create new criterium opens a modal, where we put criterium and subcriteria
  // BACKEND: if being used, we archive the commodity and child elements
  // delete criterium has same effect, of archive the commodity and creating a new one

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {commodity && <CriteriaShow commodity={commodity} />}
      </Box>
    </Layout>
  );
}
