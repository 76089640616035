import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Grid, IconButton, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React from "react";

import { MaterialApplicableType } from "../types";
import MaterialsUsedList from "./MaterialsUsedList";
import MaterialsUsedModal from "./MaterialsUsedModal";

type Props = {
  actualNumAcres: number;
  materialApplicableId?: number;
  materialApplicableType: MaterialApplicableType;
  listingDate: Dayjs | null;
  setListingDate: (value: any) => void;
};
export default function ListingDateWithMaterials(
  {
    actualNumAcres,
    materialApplicableId,
    materialApplicableType,
    listingDate,
    setListingDate,
  }: Props,
) {
  const theme = useTheme();
  const mediumScreenAndAbove = useMediaQuery(theme.breakpoints.up("md"));
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "1rem",
          py: "1rem",
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={10} md={6}>
            <DatePicker
              label="Listing Date"
              value={listingDate}
              onChange={(value) => setListingDate(value)}
              sx={{ width: "100%" }}
              disabled={!materialApplicableId}
            />
          </Grid>
          <Grid item xs={2} md={6}>
            {mediumScreenAndAbove ? (
              <Button
                onClick={() => setOpenModal(true)}
                startIcon={<AddCircleIcon />}
                disabled={!materialApplicableId}
              >
                Edit Materials
              </Button>
            ) : (
              <IconButton
                aria-label="Add Materials"
                onClick={() => setOpenModal(true)}
                color="primary"
              >
                <AddCircleIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Box sx={{ flex: 1, mt: "0.5rem" }}>
          {materialApplicableId && (
            <MaterialsUsedList
              materialApplicableId={materialApplicableId}
              materialApplicableType={materialApplicableType}
              phase={"listing"}
            />
          )}
        </Box>
      </Paper>
      {materialApplicableId && (
        <MaterialsUsedModal
          actualNumAcres={actualNumAcres}
          open={openModal}
          onClose={() => setOpenModal(false)}
          materialApplicableId={materialApplicableId}
          materialApplicableType={materialApplicableType}
          phase={"listing"}
          title={"Listing Date Materials"}
        />
      )}
    </React.Fragment>
  );
}
