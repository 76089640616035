import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import IrrigationAPI from "../api/Irrigation.api";
import {
  IrrigableType,
  Irrigation,
  IrrigationMethod,
  MaterialApplicationPhase,
} from "../types";
import { irrigationMethodToString } from "../utils";
import MaterialsUsed from "./MaterialsUsed";
import MaterialsUsedList from "./MaterialsUsedList";

type IrrigationListProps = {
  actualNumAcres: number;
  title: string;
  isPreirrigation: boolean;
  irrigableId: number;
  irrigableType: IrrigableType;
};

export default function IrrigationList({
  actualNumAcres,
  title,
  isPreirrigation,
  irrigableId,
  irrigableType,
}: IrrigationListProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  const { data } = IrrigationAPI.useList({
    irrigableId,
    irrigableType,
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Button onClick={() => setOpen(true)}>
            <AddCircleIcon />
          </Button>
        </Box>
        <Grid container spacing={2}>
          {data &&
            data.map((irrigation) => (
              <Grid item xs={12} md={6} lg={4} key={irrigation.id}>
                <IrrigationListItem
                  actualNumAcres={actualNumAcres}
                  irrigation={irrigation}
                  phase={isPreirrigation ? "preirrigating" : "irrigating"}
                  irrigableId={irrigableId}
                  irrigableType={irrigableType}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <IrrigationModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        irrigableId={irrigableId}
        irrigableType={irrigableType}
        isPreirrigation={isPreirrigation}
      />
    </React.Fragment>
  );
}

type IrrigationListItemProps = {
  actualNumAcres?: number;
  irrigation: Irrigation;
  phase: MaterialApplicationPhase;
  irrigableId: number;
  irrigableType: IrrigableType;
};
function IrrigationListItem({
  actualNumAcres,
  irrigation,
  phase,
  irrigableId,
  irrigableType,
}: IrrigationListItemProps) {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { mutateAsync } = IrrigationAPI.useDelete({
    id: irrigation.id,
  });

  const handleDelete = async () => {
    try {
      await mutateAsync();
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: 1,
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          borderRadius: 1,
          border: `2px solid ${theme.palette.grey["300"]}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Typography variant="body1">
              {irrigationMethodToString(irrigation.method)} applied for{" "}
              {irrigation.numHours} hours.
            </Typography>
            <Typography variant="subtitle2">
              {dayjs(irrigation.appliedOn).format("MM/DD/YYYY")}
            </Typography>
          </Box>
          <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box>
          <MaterialsUsedList
            materialApplicableId={irrigation.id}
            materialApplicableType={"Irrigation"}
            phase={irrigation.preirrigation ? "preirrigating" : "irrigating"}
          />
        </Box>
      </Box>
      <IrrigationModal
        actualNumAcres={actualNumAcres}
        open={openModal}
        onClose={() => setOpenModal(false)}
        irrigation={irrigation}
        isPreirrigation={irrigation.preirrigation}
        irrigableId={irrigableId}
        irrigableType={irrigableType}
      />
    </React.Fragment>
  );
}

type IrrigationModalProps = {
  actualNumAcres?: number;
  open: boolean;
  onClose: () => void;
  irrigation?: Irrigation;
  irrigableId: number;
  irrigableType: IrrigableType;
  isPreirrigation: boolean;
};

function IrrigationModal({
  actualNumAcres,
  open,
  onClose,
  irrigation,
  irrigableId,
  irrigableType,
  isPreirrigation,
}: IrrigationModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [irrigationId, setIrrigationId] = React.useState<number | undefined>(
    irrigation?.id,
  );
  const [appliedOn, setAppliedOn] = React.useState<Dayjs | null>(
    dayjs(irrigation?.appliedOn),
  );
  const [irrigationMethod, setIrrigationMethod] =
    React.useState<IrrigationMethod>(
      irrigation?.method ? irrigation.method : "sprinkler_solid",
    );
  const [numHours, setNumHours] = React.useState<number>(
    irrigation?.numHours || 2,
  );

  const { mutateAsync: saveMutateAsync } = IrrigationAPI.useSave({
    id: irrigationId,
    irrigableId,
    irrigableType,
    preirrigation: isPreirrigation,
    appliedOn: appliedOn ? appliedOn.toDate() : appliedOn,
    numHours,
    method: irrigationMethod,
  });

  const handleCreate = async () => {
    if (isNil(irrigationMethod)) {
      return enqueueSnackbar("Must select an irrigation method.");
    }
    try {
      await saveMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const saveAndShowMaterials = async () => {
    try {
      const res = await saveMutateAsync();
      setIrrigationId(res.id);
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteMutateAsync } = IrrigationAPI.useDelete({
    id: irrigationId,
  });

  const handleDelete = async () => {
    try {
      await deleteMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    if (isNil(irrigation)) {
      setIrrigationId(undefined);
      setAppliedOn(dayjs(new Date()));
      setNumHours(2);
      setIrrigationMethod("sprinkler_solid");
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-hoedate"
      aria-describedby="modal-hoedate-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography id="input-slider" gutterBottom>
          Irrigation Hours
        </Typography>
        <Slider
          aria-label="Number of irrigation hours"
          valueLabelDisplay="on"
          step={1}
          marks
          min={1}
          max={24}
          value={numHours}
          onChange={(event, newValue) => setNumHours(newValue as number)}
          sx={{ marginTop: 2 }}
        />
        <DatePicker
          label="Date"
          value={appliedOn}
          onChange={(value) => setAppliedOn(value)}
        />
        <FormControl fullWidth>
          <InputLabel id="irrigation-method-select-label">
            Irrigation Method
          </InputLabel>
          <Select
            labelId="irrigation-method-select-label"
            id="irrigation-method-select"
            value={irrigationMethod}
            label="Irrigation Method"
            onChange={(event) => {
              setIrrigationMethod(event.target.value as IrrigationMethod);
            }}
          >
            <MenuItem value={"sprinkler_solid"}>Sprinkler - Solid</MenuItem>
            <MenuItem value={"sprinkler_set_and_move"}>
              Sprinkler - Set and Move
            </MenuItem>
            <MenuItem value={"drip"}>Drip</MenuItem>
            <MenuItem value={"furrow"}>Furrow</MenuItem>
          </Select>
        </FormControl>
        {isNil(irrigationId) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <Typography variant="body1">
              Did you apply any materials?
            </Typography>
            <Button onClick={saveAndShowMaterials}>Yes</Button>
            <Button color="secondary" onClick={handleCreate}>
              No
            </Button>
          </Box>
        ) : (
          <MaterialsUsed
            numAcres={actualNumAcres}
            materialApplicableId={irrigationId} // cultivation does not exist yet
            materialApplicableType={"Irrigation"}
            phase={isPreirrigation ? "preirrigating" : "irrigating"}
          />
        )}
        {!isNil(irrigationId) && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Button onClick={handleCreate} variant="contained" fullWidth>
              Save
            </Button>
            {!isNil(irrigationId) && (
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Delete
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
