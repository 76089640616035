import { useQuery } from "react-query";

import { BusinessUnit, Dashboard, ErrorResult } from "../types";
import { handleBasicFetch, handleDetail } from "./handler";

const methods = {
  useDashboard: () => {
    return useQuery<Dashboard>({
      queryKey: ["dashboard"],
      queryFn: () =>
        handleBasicFetch({
          url: "dashboard",
          params: [],
        }),
    });
  },
  useDetail: (id: string | undefined) => {
    return useQuery<BusinessUnit | ErrorResult>({
      cacheTime: 0,
      queryKey: ["business_units", id],
      queryFn: () =>
        handleDetail<BusinessUnit>({ id, baseUrl: "business_units" }),
    });
  },
};

export default methods;
