import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import LotAPI from "../api/Lot.api";
import PreliminaryWorkAPI from "../api/PreliminaryWork.api";
import Layout from "../components/Layout";
import { Harvest, PreliminaryWork } from "../types";
import { extractDate } from "../utils";

export default function PreliminaryWorkByLotPage() {
  const { lotId } = useParams();
  const { data: lotData } = LotAPI.useDetail(lotId);
  const { data: preliminaryWorkData } = PreliminaryWorkAPI.useListByLot(lotId);

  const { mutateAsync } = PreliminaryWorkAPI.useSave({
    lotId: lotData?.id,
  });

  const navigate = useNavigate();

  const handleRowClick =
    (preliminaryWork: PreliminaryWork) =>
    (e: React.MouseEvent<HTMLElement>) => {
      navigate(`/preliminary-works/${preliminaryWork.id}`);
    };

  const handleHavestClick =
    (harvest: Harvest) => (e: React.MouseEvent<HTMLElement>) => {
      navigate(`/harvests/${harvest.id}`);
    };

  const handleCreatePreliminaryWork = async () => {
    try {
      const newPreliminaryWork = await mutateAsync();
      navigate(`/preliminary-works/${newPreliminaryWork.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2">Preliminary Work</Typography>
          {lotData && (
            <Typography variant="h6">
              Lot #{lotData?.num} in Ranch {lotData?.ranch.name}
            </Typography>
          )}
        </Box>
        <Box>
          <Button onClick={handleCreatePreliminaryWork} variant="outlined">
            Create New Preliminary Work for Lot
          </Button>
        </Box>
        {preliminaryWorkData && (
          <TableContainer sx={{ maxHeight: "70vh" }}>
            <Table stickyHeader aria-labelledby={"Preliminary Works"}>
              <TableHead>
                <TableRow>
                  <TableCell key={"created_at"}>Created On</TableCell>
                  <TableCell key={"harvests"}>Harvests</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preliminaryWorkData.map((preliminaryWork, index) => {
                  return (
                    <TableRow hover key={index} tabIndex={-1}>
                      <TableCell
                        key={"created_at"}
                        onClick={handleRowClick(preliminaryWork)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Typography>
                          {extractDate(preliminaryWork.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell key={"harvests"}>
                        {preliminaryWork.harvests.length === 0 ? (
                          <Typography>
                            Nothing is attached to this Preliminary Work
                          </Typography>
                        ) : (
                          preliminaryWork.harvests.map((harvest) => (
                            <Typography
                              key={harvest.id}
                              onClick={handleHavestClick(harvest)}
                              sx={{ cursor: "pointer" }}
                            >
                              Attached to harvest with crop #:{" "}
                              {harvest.cropNum ? harvest.cropNum : "N/A"}
                            </Typography>
                          ))
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Layout>
  );
}
