import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import { UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import { Harvest } from "../types";
import DeleteHarvestButton from "./DeleteHarvestButton";
import HarvestBasicForm from "./HarvestBasicForm";
import PreliminaryWorkHarvestAttachedCheck from "./PreliminaryWorkHarvestAttachedCheck";
import SplitSublotModal from "./SplitSublotModal";

type Props = {
  harvest: Harvest;
  refetchHarvest: () => Promise<UseQueryResult>;
};

export default function HarvestMainForm({ harvest, refetchHarvest }: Props) {
  const navigate = useNavigate();

  const { mutateAsync: mutateNewStateAsync } = HarvestAPI.useTransitionState({
    harvestId: harvest.id,
    newState: "completed",
  });

  const handleComplete = async () => {
    try {
      await mutateNewStateAsync();
      navigate(`/harvests/${harvest.id}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: mutatePlantingStateAsync } =
    HarvestAPI.useTransitionState({
      harvestId: harvest.id,
      newState: "scheduled_plant",
    });

  const handleRevertToPlantingSchedule = async () => {
    try {
      await mutatePlantingStateAsync();
      navigate(`/planting-schedule/${harvest.id}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Typography variant="h4">Harvest Form</Typography>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleComplete}
            startIcon={<CheckCircleIcon />}
          >
            Completed
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleRevertToPlantingSchedule}
            startIcon={<HistoryIcon />}
          >
            Revert to Planting Schedule
          </Button>
          <DeleteHarvestButton
            id={harvest.id}
            onSuccessCallback={() => navigate("/harvests")}
          />
        </Box>
      </Box>

      <SplitSublotModal harvest={harvest} refetchHarvest={refetchHarvest} />

      <PreliminaryWorkHarvestAttachedCheck harvestId={harvest.id} />

      <Divider>
        <Chip label="Basic Information" />
      </Divider>

      <HarvestBasicForm harvest={harvest} refetchHarvest={refetchHarvest} />
    </Box>
  );
}
