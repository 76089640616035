import camelcaseKeys from "camelcase-keys";
import { isNil, snakeCase } from "lodash";
import snakeCaseKeys from "snakecase-keys";

import {
  HandleSaveProps,
  ListQueryProps,
  ListResponseData,
  MetaPagination,
  Param,
  SearchQueryProps,
} from "../types";
import { HttpError } from "../types/http-error";
import {
  convertDatesToStrings,
  convertUndefinedToNull,
  handleDates,
} from "../utils";
import client from "./client";

export const handleQueryError = (navigate: any) => (err: any) => {
  if (err instanceof HttpError && err.code === 401) {
    return navigate("/login");
  }
};

export const handleFetchError = (enqueueSnackbar: any) => async (err: any) => {
  if (err instanceof HttpError && err.code === 404) {
    enqueueSnackbar("Oops. Something went wrong when fetching");
  } else if (err instanceof HttpError && err.code >= 400) {
    // const text = await reader.read()
    // enqueueSnackbar(`Error: ${text.value}`)
  }
};

export async function handleBasicFetch<Type>(
  { url, params }: { url: string; params?: Param[] },
): Promise<Type> {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      params.forEach(({ key, value }) => {
        searchParams.set(key, value);
      });
    }
    const data = await client.get(url, { searchParams }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleSimpleList<Type>(
  { url, params }: { url: string; params?: Param[] },
): Promise<Type[]> {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      params.forEach(({ key, value }) => {
        searchParams.set(key, value);
      });
    }
    const data: Type[] = await client.get(url, { searchParams }).json();
    const parsedData = data ? data.map((obj) => handleDates(obj)) : [];
    return camelcaseKeys(parsedData || ([] as Type[]), {
      deep: true,
    }) as Type[];
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleList<Type>(
  { order, orderBy, page, query, baseUrl, params }: ListQueryProps<Type>,
): Promise<ListResponseData<Type>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy.toString()));
    }
    if (params && params.length > 0) {
      params.forEach(({ key, value }) => {
        searchParams.set(key, value);
      });
    }

    const response: { data: Type[]; meta: MetaPagination } = await client
      .get(baseUrl, {
        searchParams,
      })
      .json();

    const parsedData = response.data
      ? response.data.map((obj) => handleDates(obj))
      : [];
    return camelcaseKeys(
      {
        data: parsedData || ([] as Type[]),
        meta: response.meta as MetaPagination,
      },
      { deep: true },
    ) as ListResponseData<Type>;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleSearch<Type>(
  { query, baseUrl }: SearchQueryProps,
): Promise<Type[]> {
  try {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("query", query);
    }
    const data: Type[] = await client
      .get(`${baseUrl}/search`, {
        searchParams,
      })
      .json();

    const parsedData = data.map((obj) => handleDates(obj));
    return camelcaseKeys(parsedData, { deep: true }) as Type[];
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleDetail<Type>(
  { id, baseUrl }: { id?: string | number; baseUrl: string },
): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data = await client.get(`${baseUrl}/${id}`).json();
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleFetch<Type>(
  { url }: { url: string },
): Promise<Type> {
  try {
    const data = await client.get(url).json();
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleSave<Type>(
  { baseUrl, input }: HandleSaveProps,
): Promise<Type> {
  try {
    // @ts-expect-error this is a hack to make the type checker happy
    const inputWithCorrectDates: Record<string, unknown> =
      convertDatesToStrings(input);
    const inputWithUndefinedConvertedToNulls: Record<string, unknown> =
      convertUndefinedToNull(inputWithCorrectDates) as Record<string, unknown>;
    const inputSnaked = snakeCaseKeys(inputWithUndefinedConvertedToNulls, {
      deep: true,
    });
    let data: Type;
    if (typeof input.id === "undefined" || isNil(input.id)) {
      data = await client.post(baseUrl, { json: inputSnaked }).json();
    } else {
      data = await client
        .put(`${baseUrl}/${input.id}`, { json: inputSnaked })
        .json();
    }
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleFileUpload<Type>(
  { url, files, paramName }: { url: string; files: any[]; paramName: string },
): Promise<Type> {
  try {
    const formData = new FormData();
    files.forEach((file, i) => {
      formData.append(`${paramName}[${i}][file]`, file);
    });
    const data: Type = await client.post(url, { body: formData }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleBasicPost<Type>(
  { baseUrl, input }: HandleSaveProps,
): Promise<Type> {
  try {
    const inputSnaked = snakeCaseKeys(input, {
      deep: true,
    });
    const data: Type = await client.post(baseUrl, { json: inputSnaked }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const handleMutationError =
  (enqueueSnackbar: any) => async (err: any) => {
    if (err instanceof HttpError && err.code === 404) {
      enqueueSnackbar("Oops. Something went wrong when deleting", {
        variant: "error",
      });
    } else if (err instanceof HttpError && err.code === 406) {
      enqueueSnackbar(err.message, { variant: "error" });
    } else if (err instanceof HttpError && err.code >= 400) {
      // const text = await reader.read()
      // enqueueSnackbar(`Error: ${text.value}`)
      enqueueSnackbar("Oops. Something went wrong", {
        variant: "error",
      });
    }
  };

export async function handleDelete<Type>(
  { baseUrl, id }: { baseUrl: string; id?: string | number },
): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: Type = await client.delete(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}
