import camelcaseKeys from "camelcase-keys";
import { useMutation } from "react-query";

import { Session } from "../types";
import client from "./client";

async function postLogin(
  email: string | undefined,
  password: string | undefined,
): Promise<Session> {
  try {
    if (typeof email === "undefined" || typeof password === "undefined") {
      Promise.reject(new Error("Invalid credentials"));
    }

    const data: Session = await client
      .post("login", {
        json: { email, password },
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    if (error instanceof Object && error.constructor.name === "TypeError") {
      console.error(error);
      throw new Error("Failed to reach the app's API");
    }
    return Promise.reject(error);
  }
}

const methods = {
  useLogin: (
    email: string | undefined,
    password: string | undefined,
    handleSuccess: (arg: Session) => void,
  ) =>
    useMutation<Session>({
      mutationFn: () => postLogin(email, password),
      onSuccess: (result, variables, context) => {
        handleSuccess(result);
      },
    }),
};
export default methods;
