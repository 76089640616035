import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

import HarvestAPI from "../api/Harvest.api";

export default function DeleteHarvestButton({
  id,
  onSuccessCallback,
}: {
  id: number;
  onSuccessCallback: () => void;
}) {
  const [open, setOpen] = React.useState<boolean>(false);

  const { mutateAsync, isLoading } = HarvestAPI.useDelete(id);

  const handleDelete = async () => {
    try {
      await mutateAsync();
      await onSuccessCallback();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will delete all related data linked to this harvest.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Disagree</Button>
          <Button onClick={handleDelete} autoFocus disabled={isLoading}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
