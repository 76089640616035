import { Box } from "@mui/material";
import React from "react";

import RegionApi from "../api/Region.api";
import { Region } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentRegion: (region: Region) => void;
  region?: Region;
};

export default function RegionCreatableAutocomplete({
  setCurrentRegion,
  region,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(region?.name);
  const [value, setValue] = React.useState<Region | string>(
    region ? region.name : "",
  );

  const { data } = RegionApi.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Region"
        dialogTitle="Add a new Region"
        noOptionsText="No results"
        setModel={(region: Region) => {
          setCurrentRegion(region);
          setSearchValue(region.name);
          setValue(region.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={RegionApi.useSave}
      />
    </Box>
  );
}
