import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";

import MaterialApplicationAPI from "../api/MaterialApplication.api";
import {
  MaterialApplicableType,
  MaterialApplication,
  MaterialApplicationPhase,
} from "../types";
import AppliedMaterialModal from "./AppliedMaterialModal";

type Props = {
  materialApplicableId: number;
  materialApplicableType: MaterialApplicableType;
  numAcres?: number;
  phase: MaterialApplicationPhase;
};
export default function MaterialsUsed({
  materialApplicableId,
  materialApplicableType,
  numAcres,
  phase,
}: Props) {
  const { data } = MaterialApplicationAPI.useList({
    materialApplicableId,
    materialApplicableType,
    phase,
  });

  const [showAddMaterial, setShowAddMaterial] = React.useState<boolean>(false);

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        px: "1rem",
        py: "1rem",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <Typography variant="body1">Applied Materials</Typography>
        <Button
          onClick={() => {
            setShowAddMaterial(true);
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>
      <Divider />
      {data && !isEmpty(data) ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {data.map((materialApplication) => (
            <ListMaterialApplication
              key={materialApplication.id}
              materialApplication={materialApplication}
              numAcres={numAcres}
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="body1">
            No materials have been applied.
          </Typography>
        </Box>
      )}
      <AppliedMaterialModal
        phase={phase}
        materialApplicableId={materialApplicableId}
        materialApplicableType={materialApplicableType}
        open={showAddMaterial}
        onClose={() => setShowAddMaterial(false)}
        numAcres={numAcres}
      />
    </Paper>
  );
}

type MaterialApplicationProps = {
  materialApplication: MaterialApplication;
  numAcres?: number;
};

function ListMaterialApplication({
  materialApplication,
  numAcres,
}: MaterialApplicationProps) {
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  const { mutateAsync } = MaterialApplicationAPI.useDelete(
    materialApplication.id,
  );

  const handleDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        mx: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <div
          onClick={() => {
            setShowEdit(true);
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <Typography variant="body1">
              {materialApplication.material?.name}
            </Typography>
            <Typography variant="subtitle2">
              {materialApplication.amount
                ? `Amount ${materialApplication.amount} ${materialApplication?.measuringUnit?.name}`
                : "Missing applied amount"}
            </Typography>
          </Box>
        </div>
        <Box sx={{ flexShrink: 1, mr: "1rem" }}>
          <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <AppliedMaterialModal
        phase={materialApplication.phase}
        materialApplication={materialApplication}
        materialApplicableId={materialApplication.materialApplicableId}
        materialApplicableType={materialApplication.materialApplicableType}
        open={showEdit}
        onClose={() => setShowEdit(false)}
        numAcres={numAcres}
      />
    </Box>
  );
}
