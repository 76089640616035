import React from 'react';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { useSessionContext } from "./contexts/Session.context";
import { Session } from "./types";
import Loading from "./components/Loading";

import LoginPage from './pages/Login.page'

import BusinessUnitUsersPage from './pages/BusinessUnitUsers.page'

import ProfilePage from './pages/Profile.page'
import DashboardPage from './pages/Dashboard.page'

import AreaListPage from './pages/AreaList.page'
import AreaShowPage from './pages/AreaShow.page'
import ApplicatorCompanyListPage from './pages/ApplicatorCompanyList.page'
import ApplicatorCompanyShowPage from './pages/ApplicatorCompanyShow.page'
import CommodityListPage from './pages/CommodityList.page'
import CommodityShowPage from './pages/CommodityShow.page'
import CompletedHarvestListPage from './pages/CompletedHarvestList.page'
import CompletedHarvestShowPage from './pages/CompletedHarvestShow.page'
import EmailConfirmationPage from "./pages/EmailConfirmation.page"
import EquipmentListPage from './pages/EquipmentList.page'
import EquipmentShowPage from './pages/EquipmentShow.page'
import ForgotPasswordPage from './pages/ForgotPassword.page'
import GroundPrepListPage from './pages/GroundPrepList.page'
import GroundPrepShowPage from './pages/GroundPrepShow.page'
import HarvestListPage from './pages/HarvestList.page'
import PlantingScheduleListPage from './pages/PlantingScheduleList.page'
import HarvestCombinedScheduleListPage from './pages/HarvestCombinedScheduleList.page'
import PlantingScheduleShowPage from './pages/PlantingScheduleShow.page'
import HarvestShowPage from './pages/HarvestShow.page'
import MaterialListPage from './pages/MaterialList.page'
import MaterialShowPage from './pages/MaterialShow.page'
import MeasuringUnitListPage from './pages/MeasuringUnitList.page'
import MeasuringUnitShowPage from './pages/MeasuringUnitShow.page'
import PreharvestQuestionListPage from "./pages/PreharvestQuestionList.page"
import PreharvestQuestionShowPage from "./pages/PreharvestQuestionShow.page"
import PreliminaryWorkList from './pages/PreliminaryWorkList.page'
import PreliminaryWorkByRanchPage from './pages/PreliminaryWorkByRanch.page';
import PreliminaryWorkByLotPage from './pages/PreliminaryWorkByLot.page'
import PreliminaryWorkShowPage from './pages/PreliminaryWorkShow.page'
import RanchListPage from './pages/RanchList.page'
import RanchShowPage from './pages/RanchShow.page'
import RegionListPage from './pages/RegionList.page'
import RegionShowPage from './pages/RegionShow.page'
import ReportActualVsDesiredShipperPage from './pages/ReportActualVsDesiredShipper.page'
import ReportCropRotationPage from './pages/ReportCropRotation.page'
import ReportListPage from './pages/ReportList.page'
import ReportShowPage from './pages/ReportShow.page'
import ReportSearchPage from './pages/ReportSearch.page'
import ResetPasswordPage from './pages/ResetPassword.page'
import SeedCompanyListPage from "./pages/SeedCompanyList.page"
import SeedCompanyShowPage from "./pages/SeedCompanyShow.page"
import ShipperListPage from './pages/ShipperList.page'
import ShipperShowPage from './pages/ShipperShow.page'
import SubGrowerListPage from './pages/SubGrowerList.page'
import SubGrowerShowPage from './pages/SubGrowerShow.page'
import TractorListPage from './pages/TractorList.page'
import TractorShowPage from './pages/TractorShow.page'
import UserListPage from "./pages/UserList.page"
import UserShowPage from "./pages/UserShow.page"
import VarietyListPage from './pages/VarietyList.page'
import VarietyShowPage from './pages/VarietyShow.page'

import MatchAllRoute from './pages/MatchAllRoute.page'

type PropsSessionProtectedRoute = {
  children: JSX.Element;
  loadedSessionStorage?: boolean;
  session?: Session;
};

const BusinessUnitSessionProtectedRoute = ({
  session, loadedSessionStorage, children
}: PropsSessionProtectedRoute) => {
  if (!loadedSessionStorage) {
    return <Loading />;
  }
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  if (!(session.role === 'admin' || session.role === "business_admin")){
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}

const SessionProtectedRoute = ({
  session,
  loadedSessionStorage,
  children,
}: PropsSessionProtectedRoute) => {
  if (!loadedSessionStorage) {
    return <Loading />;
  }
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

type PropsLoggedInRoute = {
  children: JSX.Element;
  loadedSessionStorage?: boolean;
  session?: Session;
};

const LoggedInRoute = ({
  session,
  loadedSessionStorage,
  children,
}: PropsLoggedInRoute) => {
  if (!loadedSessionStorage) {
    return <Loading />;
  }
  // TODO, check if session is expired

  if (session && session?.exp && new Date() < new Date(session.exp)) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

function App() {
  const { session, loadedSessionStorage } = useSessionContext();

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <Navigate to="/login" replace={true} />
        }
      />
      <Route
        path="/login"
        element={
          <LoggedInRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <LoginPage />
          </LoggedInRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <DashboardPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ProfilePage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/business-unit-users"
        element={
          <BusinessUnitSessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <BusinessUnitUsersPage />
          </BusinessUnitSessionProtectedRoute>
        }
      />

      <Route
        path="/areas"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <AreaListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/areas/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <AreaShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/applicator_companies"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ApplicatorCompanyListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/applicator_companies/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ApplicatorCompanyShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/commodities"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <CommodityListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/commodities/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <CommodityShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/completed-harvests"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <CompletedHarvestListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/completed-harvests/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <CompletedHarvestShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/email_confirmation/:token"
        element={<EmailConfirmationPage />}
      />
      <Route
        path="/equipments"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <EquipmentListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/equipments/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <EquipmentShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <LoggedInRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ForgotPasswordPage />
          </LoggedInRoute>
        }
      />
      <Route
        path="/ground_preps"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <GroundPrepListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/ground_preps/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <GroundPrepShowPage />
          </SessionProtectedRoute>
        }
      />

      <Route
        path="/harvests"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <HarvestListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/harvests/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <HarvestShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/materials"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <MaterialListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/materials/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <MaterialShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/measuring_units"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <MeasuringUnitListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/measuring_units/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <MeasuringUnitShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/planting-schedule"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PlantingScheduleListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/planting-schedule/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PlantingScheduleShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/combined-schedule"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <HarvestCombinedScheduleListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preharvest_questions"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreharvestQuestionListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preharvest_questions/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreharvestQuestionShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preliminary-works"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreliminaryWorkList />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preliminary-works-by-ranch/:ranchId"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreliminaryWorkByRanchPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preliminary-works-by-lot/:lotId"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreliminaryWorkByLotPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/preliminary-works/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <PreliminaryWorkShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/ranches"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <RanchListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/ranches/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <RanchShowPage />
          </SessionProtectedRoute>
        }
      />

      <Route
        path="/regions"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <RegionListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/regions/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <RegionShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <SessionProtectedRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ReportListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reports/actual-vs-desired-shippers"
        element={
          <SessionProtectedRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ReportActualVsDesiredShipperPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reports/crop-rotation"
        element={
          <SessionProtectedRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ReportCropRotationPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reports/search"
        element={
          <SessionProtectedRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ReportSearchPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reports/:id"
        element={
          <SessionProtectedRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ReportShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <LoggedInRoute
           loadedSessionStorage={loadedSessionStorage}
           session={session}
          >
            <ResetPasswordPage />
          </LoggedInRoute>
        }
      />

      <Route
        path="/seed_companies"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <SeedCompanyListPage />
          </SessionProtectedRoute>
        }
      />

      <Route
        path="/seed_companies/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <SeedCompanyShowPage />
          </SessionProtectedRoute>
        }
      />

      <Route
        path="/shippers"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ShipperListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/shippers/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ShipperShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/sub_growers"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <SubGrowerListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/sub_growers/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <SubGrowerShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/tractors"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <TractorListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/tractors/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <TractorShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <UserListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <UserShowPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/varieties"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <VarietyListPage />
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/varieties/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <VarietyShowPage />
          </SessionProtectedRoute>
        }
      />


      <Route path="*" element={<MatchAllRoute />} />
    </Routes>
  )
}
export default App;