import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  Commodity,
  CommodityArchive,
  CommodityInput,
  FieldTrialVarietyInput,
  ListResponseData,
} from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
} from "./handler";

type ResultProps = {
  estHarvestDate?: Date;
  projectedWetDate?: Date;
};

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Commodity[]>({
      queryKey: ["commodities", query],
      queryFn: () => handleSearch({ baseUrl: "commodities", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (commodityInput: CommodityInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Commodity>({
      mutationFn: () =>
        handleSave({ baseUrl: "commodities", input: commodityInput }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useSaveAndArchiveOnEdit: (commodityInput: CommodityInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<CommodityArchive>({
      mutationFn: () =>
        handleSave({ baseUrl: "commodities", input: commodityInput }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Commodity>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Commodity>>({
      cacheTime: 0,
      queryKey: ["commodities", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "commodities", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Commodity>({
      cacheTime: 0,
      enabled: id !== undefined,
      queryKey: ["commodity", id],
      queryFn: () => handleDetail({ baseUrl: "commodities", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: () => handleDelete({ baseUrl: "commodities", id }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useAttachCommodityToFieldTrialVariety: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: (fieldTrialVarietyInput: FieldTrialVarietyInput) =>
        handleSave({
          baseUrl: "commodities/attach",
          input: fieldTrialVarietyInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useWetVsHarvestDateEstimator: () => {
    return useMutation({
      mutationFn: (
        {
          commodityId,
          estHarvestDate,
          projectedWetDate,
        }: {
          commodityId?: number;
          estHarvestDate?: Date;
          projectedWetDate?: Date;
        },
      ) =>
        handleBasicPost<ResultProps>({
          baseUrl: `commodities/${commodityId}/wet_vs_harvest_date`,
          input: {
            estHarvestDate,
            projectedWetDate,
          },
        }),
    });
  },
};
export default methods;
