import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
} from "@mui/material";
import React from "react";

import FieldVarietyAPI from "../api/FieldVariety.api";
import TrialVarietyAPI from "../api/TrialVariety.api";
import { FieldVariety, TrialVariety } from "../types";

function srcset(
  image: string,
  width: number,
  height: number,
  rows = 1,
  cols = 1,
) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

type Props = {
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
export default function FieldTrialVarietyImageList({
  fieldTrialVariety,
  fieldTrialVarietyType,
}: Props) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Button variant="contained" fullWidth onClick={() => setOpenModal(true)}>
        <CollectionsIcon />
      </Button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="field-trial-variety-image-list-modal-title"
        aria-describedby="field-trial-variety-image-list-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              flexWrap: "wrap",
              listStyle: "none",
              gap: "1rem",
            }}
          >
            <ImageList
              sx={{ width: 500, height: 450, transform: "translateZ(0)" }}
              rowHeight={200}
              gap={1}
            >
              {fieldTrialVariety.images.map((image, index) => {
                const cols = 1;
                const rows = 1;
                return (
                  <ImageListItem
                    key={image.id}
                    cols={cols}
                    rows={rows}
                    sx={{
                      "& .MuiImageListItem-img": {
                        height: "100%",
                        objectFit: "cover",
                      },
                    }}
                  >
                    <img
                      {...srcset(
                        `${process.env.REACT_APP_API_HOST}${image.url}`,
                        250,
                        200,
                        rows,
                        cols,
                      )}
                      alt={`${image.id}`}
                      loading="lazy"
                    />
                    <DeleteImageListBar
                      fieldTrialVariety={fieldTrialVariety}
                      fieldTrialVarietyType={fieldTrialVarietyType}
                      imageId={image.id}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </Box>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={() => setOpenModal(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

type DeleteImageListBarProps = {
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
  imageId: number;
};
function DeleteImageListBar({
  fieldTrialVariety,
  fieldTrialVarietyType,
  imageId,
}: DeleteImageListBarProps) {
  const modelAPI =
    fieldTrialVarietyType === "TrailVariety"
      ? TrialVarietyAPI
      : FieldVarietyAPI;
  const { mutateAsync } = modelAPI.useDeleteImage({
    id: fieldTrialVariety.id,
    imageId,
  });

  const deleteImage = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ImageListItemBar
      title={"N/A"}
      position="bottom"
      actionPosition="right"
      actionIcon={
        <IconButton
          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
          aria-label={`info about ${imageId}`}
          onClick={deleteImage}
        >
          <DeleteIcon />
        </IconButton>
      }
    />
  );
}
