import { isNil } from "lodash";
import React from "react";

import VarietyAPI from "../api/Variety.api";
import { Variety } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  setVariety: (variety: Variety | undefined) => void;
  variety: Variety | undefined;
  label: string;
};

export default function VarietySearchableAutocomplete({
  label,
  variety,
  setVariety,
}: Props) {
  const [searchValue, setSearchValue] = React.useState<string>("");

  const { data } = VarietyAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  const value = isNil(variety)
    ? { label: searchValue, value: searchValue }
    : { label: variety.name, value: variety };

  return (
    <SearchBarAutocomplete
      label={label}
      inputValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
      noOptionsText={"Variety not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue)) {
          setVariety(undefined);
        } else {
          setVariety(newValue.value);
        }
      }}
    />
  );
}
