import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import CriteriumAPI from "../api/Criterium.api";
import SubcriteriaDragAndDropCreation from "../components/SubcriteriaDragAndDropCreation";
import { Criterium, CriteriumArchive, SubcriteriumInput } from "../types";

type Props = {
  open: boolean;
  onClose: () => void;
  commodityId: number;
  addCriterium: (arg: Criterium) => void;
};
export default function CreateCriteriumModal({
  open,
  onClose,
  commodityId,
  addCriterium,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [criteriumName, setCriteriumName] = React.useState<string>("");
  const [subcriteria, setSubcriteria] = React.useState<SubcriteriumInput[]>([]);

  const { mutateAsync } = CriteriumAPI.useCreate({
    commodityId,
    criteriumInput: {
      subcriteria,
      name: criteriumName,
    },
  });

  const handleCreate = async () => {
    try {
      if (isEmpty(criteriumName)) {
        enqueueSnackbar("Requires a name for the criterium.");
        return;
      }
      const commodityArchive: CriteriumArchive = await mutateAsync();
      if (commodityArchive.archivedCommodity) {
        navigate(`/commodities/${commodityArchive.commodity.id}`);
      } else {
        addCriterium(commodityArchive.criterium);
        setCriteriumName("");
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSubcriterium = () => {
    const nextIndex = subcriteria.length;
    setSubcriteria([
      ...subcriteria,
      { name: "", id: Math.random() * 1000, orderId: nextIndex },
    ]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-hoedate"
      aria-describedby="modal-hodate-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Criterium Name"
          value={criteriumName}
          onChange={(event) => {
            setCriteriumName(event.target.value);
          }}
          fullWidth
        />
        <Paper
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          elevation={2}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Evaluation Options</Typography>
            <Button onClick={handleAddSubcriterium}>
              <AddCircleIcon />
            </Button>
          </Box>
          <Divider />
          {/* add the DND */}
          {isEmpty(subcriteria) ? (
            <Typography variant="subtitle1">
              No evaluation options currently
            </Typography>
          ) : (
            <SubcriteriaDragAndDropCreation
              subcriteria={subcriteria}
              setSubcriteria={setSubcriteria}
            />
          )}
        </Paper>
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
