import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  Equipment,
  EquipmentInput,
  ListResponseData,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
} from "./handler";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Equipment[]>({
      queryKey: ["equipments", query],
      queryFn: () => handleSearch({ baseUrl: "equipments", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (equipmentInput: EquipmentInput) => {
    const navigate = useNavigate();
    return useMutation<Equipment>({
      mutationFn: () =>
        handleSave({ baseUrl: "equipments", input: equipmentInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Equipment>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Equipment>>({
      cacheTime: 0,
      queryKey: ["equipments", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "equipments", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Equipment>({
      cacheTime: 0,
      queryKey: ["equipment", id],
      queryFn: () => handleDetail({ baseUrl: "equipments", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Equipment>({
      mutationFn: () => handleDelete({ baseUrl: "equipments", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
