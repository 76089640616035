import { Box, TableCell, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import RanchAPI from "../api/Ranch.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import { Ranch, TableColumn } from "../types";

const columns: TableColumn<Ranch>[] = [{ field: "name", label: "Ranch Name" }];

function buildTableRow(ranch: Ranch): JSX.Element[] {
  return [<TableCell key={"name"}>{ranch.name}</TableCell>];
}

export default function PreliminaryWorkList() {
  const navigate = useNavigate();
  const handleRowClick = (model: Ranch) => (event: unknown) => {
    navigate(`/preliminary-works-by-ranch/${model.id}`);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h2">Preliminary Work</Typography>
          </Box>
        </Box>

        <ListModel
          defaultOrderBy={"name"}
          buildTableRow={buildTableRow}
          columns={columns}
          handleRowClick={handleRowClick}
          modelApiUseList={RanchAPI.useList}
          modelBaseUrl={"ranches"}
        />
      </Box>
    </Layout>
  );
}
