import { Box } from "@mui/material";
import React from "react";

import ApplicatorCompanyAPI from "../api/ApplicatorCompany.api";
import { ApplicatorCompany } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentApplicatorCompany: (applicatorCompany: ApplicatorCompany) => void;
  applicatorCompany?: ApplicatorCompany;
};

export default function ApplicatorCompanyCreatableAutocomplete({
  applicatorCompany,
  setCurrentApplicatorCompany,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(applicatorCompany?.name);
  const [value, setValue] = React.useState<ApplicatorCompany | string>(
    applicatorCompany ? applicatorCompany.name : "",
  );

  const { data } = ApplicatorCompanyAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Applicator"
        dialogTitle="Add a new Applicator"
        noOptionsText="No results"
        setModel={(applicatorCompany: ApplicatorCompany) => {
          setCurrentApplicatorCompany(applicatorCompany);
          setSearchValue(applicatorCompany.name);
          setValue(applicatorCompany.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={ApplicatorCompanyAPI.useSave}
      />
    </Box>
  );
}
