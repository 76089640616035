import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useSessionContext } from "../contexts/Session.context";

export default function ProfileNavigationMenu() {
  const { setSession, session } = useSessionContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setSession && setSession(undefined);
    navigate("/login");
  };

  const handleBusinessUnitUsers = () => {
    navigate("/business-unit-users");
  };

  const handleEditProfile = () => {
    navigate("/profile");
  };

  const handleUserAdmin = () => {
    navigate("/users");
  };

  return (
    <>
      <IconButton
        size="large"
        aria-controls={open ? "basic-menu" : undefined}
        aria-label="account of current user"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color="inherit"
        onClick={handleClick}
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEditProfile}>Profile</MenuItem>
        <MenuItem onClick={handleUserAdmin}>Users</MenuItem>
        {session &&
          (session?.role === "business_admin" || session?.role === "admin") && (
            <MenuItem onClick={handleBusinessUnitUsers}>
              Business Unit Users
            </MenuItem>
          )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
