import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { isEmpty, isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ReportAPI from "../api/Report.api";
import Layout from "../components/Layout";
import ShipperMultiSelectSearch from "../components/ShipperMultiSelectSearch";
import { Harvest, Shipper } from "../types";

export default function ReportActualVsDesiredShipperPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [shippers, setShippers] = React.useState<Shipper[]>([]);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [harvests, setHarvests] = React.useState<Harvest[]>([]);

  const { mutateAsync } = ReportAPI.useFetchActualVsDesiredShipperData({
    shipperIds: shippers.map((s) => s.id),
    year,
  });

  const handleCreateReport = async () => {
    if (isEmpty(shippers)) {
      enqueueSnackbar("Must select which shippers");
      return;
    }
    if (isNil(year)) {
      enqueueSnackbar("Must select year");
      return;
    }
    try {
      const data = await mutateAsync();
      setHarvests(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h2">Actual vs Desired Shippers</Typography>

        <Box
          sx={{
            marginBottom: "1rem",
            marginTop: "2rem",
            maxWidth: "60rem",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Box sx={{ flex: 1 }}>
              <ShipperMultiSelectSearch
                shippers={shippers}
                setShippers={setShippers}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year.toString()}
                  label="Year"
                  onChange={(event: SelectChangeEvent) => {
                    setYear(parseInt(event.target.value) as number);
                  }}
                >
                  {arrayOfYearsFromCurrentYear(10).map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Button fullWidth variant="contained" onClick={handleCreateReport}>
              Run Report
            </Button>
          </Box>
        </Box>

        <Box>{!isEmpty(harvests) && <DataTable harvests={harvests} />}</Box>
      </Box>
    </Layout>
  );
}

function arrayOfYearsFromCurrentYear(yearsBack: number) {
  const currentYear = new Date().getFullYear();
  const arr = [];
  for (let i = currentYear; i >= currentYear - yearsBack; i--) {
    arr.push(i);
  }
  return arr;
}

function DataTable({ harvests }: { harvests: Harvest[] }) {
  const shippers = {} as { [k: string]: string };
  const commodities = {} as { [k: string]: string };
  harvests.forEach((harvest) => {
    shippers[harvest.shipper.id] = harvest.shipper.name;
    commodities[harvest.commodity.id] = harvest.commodity.name;
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ mindWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell
              align="center"
              colSpan={Object.values(shippers).length + 1}
            >
              Shipper
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Crop</TableCell>
            <TableCell align="right">Values</TableCell>
            {Object.values(shippers).map((shipperName) => (
              <TableCell>{shipperName}</TableCell>
            ))}
            <TableCell>Grand Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(commodities).map((commodityId) => {
            let totalDesiredNumAcres = 0;
            let totalActualNumAcres = 0;
            return (
              <React.Fragment>
                <TableRow>
                  <TableCell>{commodities[commodityId]}</TableCell>
                  <TableCell align="right">Total Desired Acres</TableCell>
                  {Object.keys(shippers).map((shipperId) => {
                    const desiredNumOfAcres = harvestDesiredNumOfAcres({
                      harvests,
                      shipperId,
                      commodityId,
                    });
                    totalDesiredNumAcres += desiredNumOfAcres;
                    return <TableCell>{desiredNumOfAcres}</TableCell>;
                  })}
                  <TableCell>{totalDesiredNumAcres}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    Total Actual Acres
                  </TableCell>
                  {Object.keys(shippers).map((shipperId) => {
                    const actualNumOfAcres = harvestActualNumOfAcres({
                      harvests,
                      shipperId,
                      commodityId,
                    });
                    totalActualNumAcres += actualNumOfAcres;
                    return <TableCell>{actualNumOfAcres}</TableCell>;
                  })}
                  <TableCell>{totalActualNumAcres}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    Variance
                  </TableCell>
                  {Object.keys(shippers).map((shipperId) => {
                    const actualNumOfAcres = harvestActualNumOfAcres({
                      harvests,
                      shipperId,
                      commodityId,
                    });
                    const desiredNumOfAcres = harvestDesiredNumOfAcres({
                      harvests,
                      shipperId,
                      commodityId,
                    });
                    const variance = actualNumOfAcres - desiredNumOfAcres;
                    return <TableCell>{variance}</TableCell>;
                  })}
                  <TableCell>
                    {totalActualNumAcres - totalDesiredNumAcres}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} align="right">
              Total Total Desired Acres
            </TableCell>
            {Object.keys(shippers).map((shipperId) => {
              const total = Object.keys(commodities)
                .map((commodityId) =>
                  harvestDesiredNumOfAcres({
                    harvests,
                    shipperId,
                    commodityId,
                  }),
                )
                .reduce((a, b) => a + b, 0);
              return <TableCell>{total}</TableCell>;
            })}
            <TableCell>
              {harvests
                .map((harvest) =>
                  parseFloat(harvest.desiredNumAcres?.toString()),
                )
                .reduce((a, b) => a + b, 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} align="right">
              Total Total Actual Acres
            </TableCell>
            {Object.keys(shippers).map((shipperId) => {
              const total = Object.keys(commodities)
                .map((commodityId) =>
                  harvestActualNumOfAcres({
                    harvests,
                    shipperId,
                    commodityId,
                  }),
                )
                .reduce((a, b) => a + b, 0);
              return <TableCell>{total}</TableCell>;
            })}
            <TableCell>
              {harvests
                .map((harvest) =>
                  parseFloat(harvest.lot.numOfAcres?.toString()),
                )
                .reduce((a, b) => a + b, 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} align="right">
              Total Variance
            </TableCell>
            {Object.keys(shippers).map((shipperId) => {
              const desiredTotal = Object.keys(commodities)
                .map((commodityId) =>
                  harvestDesiredNumOfAcres({
                    harvests,
                    shipperId,
                    commodityId,
                  }),
                )
                .reduce((a, b) => a + b, 0);
              const actualTotal = Object.keys(commodities)
                .map((commodityId) =>
                  harvestActualNumOfAcres({
                    harvests,
                    shipperId,
                    commodityId,
                  }),
                )
                .reduce((a, b) => a + b, 0);
              const total = actualTotal - desiredTotal;
              return <TableCell>{total}</TableCell>;
            })}
            <TableCell>
              {harvests
                .map(
                  (harvest) =>
                    parseFloat(harvest.lot.numOfAcres?.toString()) -
                    parseFloat(harvest.desiredNumAcres?.toString()),
                )
                .reduce((a, b) => a + b, 0)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function harvestDesiredNumOfAcres({
  harvests,
  shipperId,
  commodityId,
}: {
  harvests: Harvest[];
  shipperId: string;
  commodityId: string;
}) {
  return harvests
    .filter((harvest) => {
      if (
        harvest.commodity.id?.toString() === commodityId &&
        harvest.shipper.id?.toString() === shipperId
      ) {
        return true;
      }
      return false;
    })
    .map((harvest) => parseFloat(harvest.desiredNumAcres?.toString()))
    .reduce((a, b) => a + b, 0);
}

function harvestActualNumOfAcres({
  harvests,
  shipperId,
  commodityId,
}: {
  harvests: Harvest[];
  shipperId: string;
  commodityId: string;
}) {
  return harvests
    .filter((harvest) => {
      if (
        harvest.commodity.id?.toString() === commodityId &&
        harvest.shipper.id?.toString() === shipperId
      ) {
        return true;
      }
      return false;
    })
    .map((harvest) => harvest.lot.numOfAcres)
    .reduce((a, b) => a + b, 0);
}
