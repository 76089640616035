import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import MeasuringUnitAPI from "../api/MeasuringUnit.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import {
  MeasuringUnit,
  MeasuringUnitInput,
  MeasuringUnitUsage,
  TableColumn,
} from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<MeasuringUnit>[] = [
  { field: "name", label: "Name" },
  { field: "usage", label: "Type" },
  { field: "createdAt", label: "Created At Date" },
];

function usageFriendlyName(usage: string | undefined) {
  if (usage === "seeds") {
    return "Seeds";
  } else if (usage === "applied_materials") {
    return "Applied Materials";
  }
  return null;
}

function buildTableRow(measuringUnit: MeasuringUnit): JSX.Element[] {
  return [
    <TableCell key={"name"}>{measuringUnit.name}</TableCell>,
    <TableCell key={"usage"}>
      {usageFriendlyName(measuringUnit.usage)}
    </TableCell>,
    <TableCell key={"createdAt"}>
      {extractDate(measuringUnit.createdAt)}
    </TableCell>,
  ];
}

export default function MeasuringUnitList() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Measuring Units</Typography>
          <Button onClick={() => setOpenModal(true)}>
            <AddCircleIcon />
          </Button>
        </Box>

        <ListModel
          defaultOrderBy={"name"}
          modelApiUseList={MeasuringUnitAPI.useList}
          modelBaseUrl={"measuring_units"}
          columns={columns}
          buildTableRow={buildTableRow}
        />
        <MeasuringUnitCreateModal
          open={openModel}
          onClose={() => setOpenModal(false)}
        />
      </Box>
    </Layout>
  );
}

type MeasuringUnitCreateModalProps = {
  open: boolean;
  onClose: () => void;
};
function MeasuringUnitCreateModal({
  open,
  onClose,
}: MeasuringUnitCreateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [usage, setUsage] = React.useState<MeasuringUnitUsage>("seeds");

  const { mutateAsync } = MeasuringUnitAPI.useSave({
    name,
    usage,
  } as MeasuringUnitInput);

  const handleCreate = async () => {
    try {
      if (isEmpty(name) || isEmpty(usage)) {
        enqueueSnackbar("Missing required field", {
          variant: "error",
        });
      }
      const model: MeasuringUnit = await mutateAsync();
      onClose();
      return navigate(`/measuring_units/${model.id}`);
    } catch (error) {
      let message = "Unknown Error";
      if (error instanceof Error) {
        message = error.message;
      }
      enqueueSnackbar(message, { variant: "error" });
      console.log(error);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value as string)
          }
        />
        <FormControl fullWidth>
          <InputLabel id="measuring-unit-usage-select-label">Type</InputLabel>
          <Select
            labelId="measuring-unit-usage-select-label"
            id="measuring-unit-usage-select"
            value={usage}
            label="Type"
            onChange={(event) => {
              setUsage(event.target.value as MeasuringUnitUsage);
            }}
          >
            <MenuItem value={"applied_materials"}>Applied Materials</MenuItem>
            <MenuItem value={"seeds"}>Seeds</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
