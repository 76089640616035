import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

import { CriteriumArchive, CriteriumInput } from "../types";
import { handleDelete, handleMutationError, handleSave } from "./handler";
import queryClient from "./queryClient";

const methods = {
  useCreate: ({
    commodityId,
    criteriumInput,
  }: {
    commodityId: number;
    criteriumInput: CriteriumInput;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<CriteriumArchive>({
      mutationFn: () =>
        handleSave({
          baseUrl: `commodities/${commodityId}/criteria`,
          input: criteriumInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: (data) => {
        enqueueSnackbar("Successfully created");
        queryClient.invalidateQueries({
          queryKey: ["commodity", data.commodity.id.toString()],
        });
      },
    });
  },
  useUpdate: (criterium: CriteriumInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<CriteriumArchive>({
      mutationFn: () =>
        handleSave({
          baseUrl: `commodities/${criterium.commodityId}/criteria`,
          input: criterium,
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: (data) => {
        enqueueSnackbar("Successfully updated");
        queryClient.invalidateQueries({
          queryKey: ["commodity", data.commodity.id.toString()],
        });
      },
    });
  },
  useDelete: (commodityId: number, id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<CriteriumArchive>({
      mutationFn: () =>
        handleDelete({
          baseUrl: `commodities/${commodityId}/criteria`,
          id,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
