import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import HarvestAPI from "../api/Harvest.api";
import LotAPI from "../api/Lot.api";
import ReportAPI from "../api/Report.api";
import CommodityCreatableAutocomplete from "../components/CommodityCreatableAutocomplete";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import RanchSearch from "../components/RanchSearch";
import ShipperCreatableAutocomplete from "../components/ShipperCreatableAutocomplete";
import {
  Commodity,
  CropRotation,
  Lot,
  Ranch,
  Shipper
} from "../types";

const cropWidth = 10;

export default function ReportCropRotationPage() {
  const [numYearsBack, setNumYearsBack] = React.useState<number>(1);
  const [ranch, setRanch] = React.useState<Ranch | undefined>();

  const { data: cropRotationMeta } = ReportAPI.useCropRotationMeta({
    numYearsBack,
  });

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
          ml: "auto",
          mr: "auto",
          minWidth: "85vw",
          maxWidth: "85vw",
        }}
      >
        <Typography variant="h2">Crop Rotation</Typography>

        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Number of years back"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={numYearsBack}
              onChange={(e) => setNumYearsBack(parseFloat(e.target.value))}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RanchSearch ranch={ranch} setRanch={setRanch} />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          {cropRotationMeta ? (
            <LotDataInfinite
              numYearsBack={numYearsBack}
              ranch={ranch}
              cropRotationMeta={cropRotationMeta}
            />
          ) : (
            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
              <Loading />
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export function LotDataInfinite({
  cropRotationMeta,
  numYearsBack,
  ranch,
}: {
  cropRotationMeta: { [years: number]: number };
  numYearsBack: number;
  ranch?: Ranch;
}) {
  // Order by Ranch name
  // filter by Ranch
  // leverage useInfiniteQuery for fetching all the lots
  const [page, setPage] = React.useState<number>(0);
  const { data, isLoading } = LotAPI.useListCropRotation({
    page,
    ranchId: ranch ? ranch.id : undefined,
  });
  // const meta = data?.meta;
  const currentDate = new Date();

  if (isLoading || !data?.data) {
    return (
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Loading />
      </Box>
    );
  }

  const lots = data && data.data;
  data.data.sort((lotA, lotB) => {
    const nameRanchA = lotA.ranch.name.toUpperCase();
    const nameRanchB = lotB.ranch.name.toUpperCase();
    if (nameRanchA < nameRanchB) {
      return -1;
    }
    if (nameRanchA > nameRanchB) {
      return 1;
    }
    // isNaN(lotA.num)
    if (parseInt(lotA.num) < parseInt(lotB.num)) {
      return -1;
    }
    if (parseInt(lotA.num) > parseInt(lotB.num)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0.3rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              alignSelf: "flex-end",
              minHeight: "4.55rem",
              borderBottom: `1px solid ${grey[500]}`,
            }}
          >
            <Box sx={{ minWidth: "7rem" }}>
              <Typography variant="h6" align="center">
                Ranch / Lot
              </Typography>
            </Box>
            <Box sx={{ minWidth: "7rem" }}>
              <Typography variant="h6" align="center">
                # of acres
              </Typography>
            </Box>
          </Box>

          {lots &&
            lots.map((lot, rowIndex) => (
              <Box
                key={rowIndex}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "4rem",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "7rem" }}>
                  <Typography align={"center"} variant="body1">
                    {lot.ranch.name} {lot.num}
                  </Typography>
                </Box>
                <Box sx={{ minWidth: "7rem" }}>
                  <Typography align={"center"} variant="body1">
                    {lot.numOfAcres} acres
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            flexWrap: "nowrap",
            gap: "0.5rem",
            // maxHeight: "90vh"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {Array.from({ length: numYearsBack + 1 }, (value, index) => index)
              .sort((a, b) => b - a)
              .map((yearKey) => (
                <Box
                  key={yearKey}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      minWidth: `${
                        (cropRotationMeta[yearKey] === 0
                          ? 1
                          : cropRotationMeta[yearKey]) * cropWidth
                      }rem`,
                    }}
                  >
                    <Typography variant="h6" align="center">
                      Year {currentDate.getFullYear() - yearKey}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {Array.from({ length: numYearsBack + 1 }, (value, index) => index)
              .sort((a, b) => b - a)
              .map((yearKey) => (
                <Box
                  key={yearKey}
                  sx={{
                    borderBottom: `1px solid ${grey[500]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {Array.from(
                    {
                      length:
                        cropRotationMeta[yearKey] === 0
                          ? 1
                          : cropRotationMeta[yearKey],
                    },
                    (value, index) => index,
                  ).map((cropRotationIndexByYear, indexByYear) => (
                    <Box
                      sx={{
                        minWidth: `${cropWidth}rem`,
                      }}
                      key={indexByYear}
                    >
                      <Typography variant="h6" align="center">
                        Crop {cropRotationIndexByYear + 1}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
          </Box>
          {lots &&
            lots.map((lot, rowIndex) => (
              <LotCropRotation
                key={lot.id}
                lot={lot}
                numYearsBack={numYearsBack}
                alternate={rowIndex % 2 === 0}
                cropRotationMeta={cropRotationMeta}
              />
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row" }}>
          <IconButton
            disabled={page <= 0}
            onClick={() => setPage((p) => p - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={page + 1 >= data?.meta["pages"]}
            onClick={() => setPage((p) => p + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export function LotCropRotation({
  lot,
  numYearsBack,
  alternate,
  cropRotationMeta,
}: {
  alternate: boolean;
  lot: Lot;
  numYearsBack: number;
  cropRotationMeta: { [years: number]: number };
}) {
  const yearsArr = Array.from(
    { length: numYearsBack + 1 },
    (value, index) => index,
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "0.5rem",
        flex: 1,
      }}
    >
      {yearsArr
        .sort((a, b) => b - a)
        .map((year) => (
          <LotCropRotationByYear
            key={year}
            lot={lot}
            year={year}
            maxHarvests={cropRotationMeta[year]}
          />
        ))}

      <AddHarvestToLot lot={lot} />
    </Box>
  );
}

function LotCropRotationByYear({
  lot,
  year,
  maxHarvests,
}: {
  lot: Lot;
  year: number;
  maxHarvests: number;
}) {
  const { data } = ReportAPI.useCropRotation({
    lotId: lot.id,
    numYearsBack: year,
  });

  const combineSubgroups = (data?: CropRotation[]) => {
    if (!data) return [];
    const sublotGroups = {};
    const lots: (Array<CropRotation> | CropRotation)[] = [];
    data.forEach((harvest) => {
      if (harvest.isSublot) {
        // @ts-expect-error
        if (!sublotGroups[harvest.lotable.sublotGroupId]) {
          // @ts-expect-error
          sublotGroups[harvest.lotable.sublotGroupId] = [];
        }
        // @ts-expect-error
        sublotGroups[harvest.lotable.sublotGroupId].push(harvest);
      } else {
        lots.push(harvest);
      }
    });
    // combine the two
    Object.values(sublotGroups).forEach((sublots) => {
      lots.push(sublots as CropRotation[]);
    });
    return lots;
  };

  const lots = data ? combineSubgroups(data) : [];

  let fillMissing = lots ? maxHarvests - lots.length : maxHarvests;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {lots &&
        lots.map((cropRotation, index) => {
          if (cropRotation instanceof Array) {
            return (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.25rem",
                }}
              >
                {cropRotation.map((obj, i) => (
                  <CropRotationBlock key={obj.id} cropRotation={obj} />
                ))}
              </Box>
            );
          }
          return <CropRotationBlock key={index} cropRotation={cropRotation} />;
        })}
      {fillMissing && fillMissing > 0 ? (
        Array.from(Array(fillMissing)).map((val, i) => (
          <Box
            key={i}
            sx={{ width: `${cropWidth}rem`, backgroundColor: grey[100] }}
          ></Box>
        ))
      ) : (
        <React.Fragment />
      )}
    </Box>
  );
}

function CropRotationBlock({ cropRotation }: { cropRotation: CropRotation }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);

  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    cropRotation.commodity,
  );
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    cropRotation.estHarvestDate ? dayjs(cropRotation.estHarvestDate) : null,
  );
  const [projectedWetDate, setProjectedWetDate] = React.useState<Dayjs | null>(
    cropRotation.projectedWetDate ? dayjs(cropRotation.projectedWetDate) : null,
  );
  const [shipper, setShipper] = React.useState<Shipper | undefined>(
    cropRotation.shipper,
  );

  const { mutateAsync } = HarvestAPI.useUpdate({
    id: cropRotation.id,
    estHarvestDate: estHarvestDate ? estHarvestDate.toDate() : undefined,
    projectedWetDate: projectedWetDate ? projectedWetDate.toDate() : undefined,
    commodityId: commodity?.id,
    shipperId: shipper?.id,
  });

  const handleSave = async () => {
    try {
      if (isNil(projectedWetDate) && isNil(estHarvestDate)) {
        enqueueSnackbar(
          "Must select an estimated harvest date or a projected wet date",
          {
            variant: "error",
          },
        );
        return;
      }
      if (isNil(commodity)) {
        enqueueSnackbar("Must select a Commodity", {
          variant: "error",
        });
        return;
      }
      await mutateAsync();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      key={cropRotation.id}
      sx={{
        width: `${cropWidth}rem`,
        backgroundColor: `${commodity?.cropRotationColor}`,
        cursor: "pointer",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          gap: "0.1rem",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography align="center" variant="body2">
            {commodity?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography align="center" variant="body2">
            {estHarvestDate ? dayjs(estHarvestDate).format("MM/DD/YYYY") : null}
          </Typography>
        </Box>
      </Box>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                label="Projected Wet Date"
                value={projectedWetDate}
                onChange={(value) => setProjectedWetDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setProjectedWetDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Est Harvest Date"
                value={estHarvestDate}
                onChange={(value) => setEstHarvestDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setEstHarvestDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommodityCreatableAutocomplete
                setCurrentCommodity={(commodity: Commodity) =>
                  setCommodity(commodity)
                }
                commodity={commodity}
              />
            </Grid>
            <Grid item xs={12}>
              <ShipperCreatableAutocomplete
                setCurrentShipper={(shipper: Shipper) => setShipper(shipper)}
                shipper={shipper}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button onClick={handleSave} variant="contained" fullWidth>
              Save
            </Button>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

function AddHarvestToLot({ lot }: { lot: Lot }) {
  const { enqueueSnackbar } = useSnackbar();

  const [commodity, setCommodity] = React.useState<Commodity | undefined>();
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    null,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [projectedWetDate, setProjectedWetDate] = React.useState<Dayjs | null>(
    null,
  );
  const [shipper, setShipper] = React.useState<Shipper | undefined>();

  const { mutateAsync } = HarvestAPI.useCreateScheduled({
    lotableId: lot.id,
    lotableType: "Lot",
    estHarvestDate: estHarvestDate ? estHarvestDate.toDate() : undefined,
    projectedWetDate: projectedWetDate
      ? projectedWetDate.toDate()
      : projectedWetDate,
    commodityId: commodity?.id,
    shipperId: shipper?.id,
  });

  const handleCreate = async () => {
    try {
      if (isNil(projectedWetDate) && isNil(estHarvestDate)) {
        enqueueSnackbar(
          "Must select an estimated harvest date or a projected wet date",
          {
            variant: "error",
          },
        );
        return;
      }
      if (isNil(commodity)) {
        enqueueSnackbar("Must select a Commodity", {
          variant: "error",
        });
        return;
      }
      await mutateAsync();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton aria-label="create" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                label="Projected Wet Date"
                value={projectedWetDate}
                onChange={(value) => setProjectedWetDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setProjectedWetDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Est Harvest Date"
                value={estHarvestDate}
                onChange={(value) => setEstHarvestDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setEstHarvestDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommodityCreatableAutocomplete
                setCurrentCommodity={(commodity: Commodity) =>
                  setCommodity(commodity)
                }
                commodity={commodity}
              />
            </Grid>
            <Grid item xs={12}>
              <ShipperCreatableAutocomplete
                setCurrentShipper={(shipper: Shipper) => setShipper(shipper)}
                shipper={shipper}
              />
            </Grid>
          </Grid>
          <Button onClick={handleCreate} variant="contained" fullWidth>
            Create
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
