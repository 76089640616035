import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { UseQueryResult } from "react-query";

import HarvestAPI from "../api/Harvest.api";
import { Commodity, Harvest, HarvestInput, Shipper, Subgrower } from "../types";
import Autosave from "./Autosave";
import CommodityCreatableAutocomplete from "./CommodityCreatableAutocomplete";
import ShipperCreatableAutocomplete from "./ShipperCreatableAutocomplete";
import SubGrowerCreatableAutocomplete from "./SubGrowerCreatableAutocomplete";

type Props = {
  harvest: Harvest;
  refetchHarvest: () => Promise<UseQueryResult>;
};

export default function HarvestBasicForm({ harvest, refetchHarvest }: Props) {
  const [projectedWetDate, setProjectedWetDate] = React.useState<Dayjs | null>(
    harvest.projectedWetDate ? dayjs(harvest.projectedWetDate) : null,
  );
  const [desiredNumAcres, setDesiredNumAcres] = React.useState<number>(
    harvest.desiredNumAcres || 0,
  );
  const [transplantDate, setTransplantDate] = React.useState<Dayjs | null>(
    harvest.transplantDate ? dayjs(harvest.transplantDate) : null,
  );
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    harvest.estHarvestDate ? dayjs(harvest.estHarvestDate) : null,
  );
  const [hasSubgrower, setHasSubgrower] = React.useState<boolean>(
    harvest.hasSubgrower,
  );
  const [commodity, setCommodity] = React.useState<Commodity>(
    harvest.commodity,
  );
  const [subgrower, setSubgrower] = React.useState<Subgrower>(
    harvest.subgrower,
  );
  const [shipper, setShipper] = React.useState<Shipper>(harvest.shipper);

  const harvestInput: HarvestInput = {
    id: harvest.id,
    desiredNumAcres,
    projectedWetDate: projectedWetDate
      ? projectedWetDate.toDate()
      : projectedWetDate,
    transplantDate: transplantDate ? transplantDate.toDate() : transplantDate,
    estHarvestDate: estHarvestDate ? estHarvestDate.toDate() : estHarvestDate,
    hasSubgrower,
    commodityId: commodity?.id,
    subgrowerId: subgrower?.id,
    shipperId: shipper?.id,
  };

  // await refetchHarvest();
  const { mutateAsync } = HarvestAPI.useUpdateAuto();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <Autosave
        data={harvestInput}
        patchApiCall={mutateAsync}
        showPopup={true}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CommodityCreatableAutocomplete
            setCurrentCommodity={(commodity: Commodity) =>
              setCommodity(commodity)
            }
            commodity={commodity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ShipperCreatableAutocomplete
            setCurrentShipper={(shipper: Shipper) => setShipper(shipper)}
            shipper={shipper}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Desired Number of Acres"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={desiredNumAcres}
            onChange={(e) => setDesiredNumAcres(parseFloat(e.target.value))}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Projected Wet Date"
            value={projectedWetDate}
            onChange={(value) => setProjectedWetDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Transplant Date"
            value={transplantDate}
            onChange={(value) => setTransplantDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Est. Harvest Date"
            value={estHarvestDate}
            onChange={(value) => setEstHarvestDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={hasSubgrower}
                  onChange={(event) => {
                    setHasSubgrower(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Is this from a subgrower?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          {hasSubgrower && (
            <SubGrowerCreatableAutocomplete
              setCurrentSubGrower={(subgrower: Subgrower) =>
                setSubgrower(subgrower)
              }
              subgrower={subgrower}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
