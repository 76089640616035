import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import CommodityAPI from "../api/Commodity.api";
import EvaluationAPI from "../api/Evaluation.api";
import FieldVarietyAPI from "../api/FieldVariety.api";
import TrialVarietyAPI from "../api/TrialVariety.api";
import VarietyAPI from "../api/Variety.api";
import {
  Commodity,
  Criterium,
  Evaluation,
  FieldVariety,
  Harvest,
  Subcriterium,
  TrialVariety,
} from "../types";
import DeleteConfirmation from "./DeleteConfirmation";
import FieldTrialVarietyImageList from "./FieldTrialVarietyImageList";
import FieldTrialVarietyImageUpload from "./FieldTrialVarietyImageUpload";
import Loading from "./Loading";

type EvaluationCommodityVarietySelectProps = {
  fieldTrialVariety: FieldVariety | TrialVariety;
  commodityId?: string;
  selectCommodity: (arg: string) => void;
};
function EvaluationCommodityVarietySelect({
  commodityId,
  selectCommodity,
  fieldTrialVariety,
}: EvaluationCommodityVarietySelectProps) {
  const [allowEdit, setAllowEdit] = React.useState<boolean>(isNil(commodityId));
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);
  const { data: commoditiesList } = VarietyAPI.useAttachedCommodities(
    fieldTrialVariety.variety.id,
  );
  const { data: selectedCommodity } = CommodityAPI.useDetail(commodityId);

  const handleChange = (event: SelectChangeEvent) => {
    selectCommodity(event.target.value);
  };

  if (!allowEdit) {
    if (!commoditiesList) {
      return <Loading />;
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="body1">{selectedCommodity?.name}</Typography>
        <Button onClick={() => setOpenDeleteConfirmation(true)}>Edit</Button>
        <DeleteConfirmation
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          onAccept={() => setAllowEdit(true)}
          title={"Delete Confirmation"}
          text={"Are you sure you want to delete the evaluations?"}
        />
      </Box>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="evaluation-commodity-variety-select-label">
        Commodity
      </InputLabel>
      {commoditiesList && (
        <Select
          labelId="evaluation-commodity-variety-select-label"
          id="evaluation-commodity-variety-select"
          value={commodityId}
          label="Commodity"
          onChange={handleChange}
        >
          {commoditiesList
            .filter((commodity) => !commodity.archivedOn)
            .map((commodityOption) => (
              <MenuItem key={commodityOption.id} value={commodityOption.id}>
                {commodityOption.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
}

type Props = {
  harvest: Harvest;
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
export default function EvaluationForm({
  harvest,
  fieldTrialVariety,
  fieldTrialVarietyType,
}: Props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);
  const [commodityId, setCommodityId] = React.useState<string | undefined>(
    fieldTrialVariety?.commodityId?.toString(),
  );
  const [comments, setComments] = React.useState<string>(
    fieldTrialVariety?.comments || "",
  );

  const [actualHarvestDate, setActualHarvestDate] =
    React.useState<Dayjs | null>(
      fieldTrialVariety.actualHarvestDate
        ? dayjs(fieldTrialVariety.actualHarvestDate)
        : null,
    );
  const [desiredHarvestDate, setDesiredHarvestDate] =
    React.useState<Dayjs | null>(
      fieldTrialVariety.desiredHarvestDate
        ? dayjs(fieldTrialVariety.desiredHarvestDate)
        : dayjs(new Date()),
    );
  const [yieldPerAcre, setYieldPerAcre] = React.useState<number>(
    fieldTrialVariety.yieldPerAcre || 0,
  );
  const [rank, setRank] = React.useState<number>(fieldTrialVariety.rank || 0);

  const fieldTrialVarietyAPI =
    fieldTrialVarietyType.replace(" ", "") === "TrialVariety"
      ? TrialVarietyAPI
      : FieldVarietyAPI;
  const { mutateAsync: fieldTrialVarietyMutateAsync } =
    fieldTrialVarietyAPI.useSaveSimple();

  const updateFieldTrialVarietyAttributes = async () => {
    try {
      await fieldTrialVarietyMutateAsync({
        id: fieldTrialVariety.id,
        actualHarvestDate: actualHarvestDate
          ? actualHarvestDate.toDate()
          : actualHarvestDate,
        desiredHarvestDate: desiredHarvestDate
          ? desiredHarvestDate.toDate()
          : desiredHarvestDate,
        rank,
        yieldPerAcre,
      });
      enqueueSnackbar("Saved evaluation.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to save evaluation information.", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const { data: selectedCommodity } = CommodityAPI.useDetail(commodityId);

  const { mutateAsync } = CommodityAPI.useAttachCommodityToFieldTrialVariety();

  const selectCommodity = async (selectedCommodityId: string) => {
    setCommodityId(selectedCommodityId);
    try {
      await mutateAsync({
        fieldTrialVarietyId: fieldTrialVariety.id,
        fieldTrialVarietyType: fieldTrialVarietyType.replace(" ", ""),
        commodityId: selectedCommodityId,
      });
      enqueueSnackbar("Saved the commodity selection.");
    } catch (error) {
      setCommodityId(commodityId);
      enqueueSnackbar("Failed to save the commodity selection.");
      console.error(error);
    }
  };

  return (
    <Paper
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1">
            {fieldTrialVarietyType}:{" "}
            <strong>{fieldTrialVariety.variety.name}</strong>
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <TextField
            label="Rank"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={rank}
            onChange={(e) => {
              const val = e.target.value;
              if (isEmpty(val)) {
                setRank(0);
              } else {
                setRank(parseInt(val));
              }
            }}
            fullWidth
          />
        </Box>
      </Box>
      {/* Select Commodity from Variety associated Commodities available */}
      <EvaluationCommodityVarietySelect
        commodityId={commodityId}
        selectCommodity={selectCommodity}
        fieldTrialVariety={fieldTrialVariety}
      />

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Actual Harvest Date"
            value={actualHarvestDate}
            onChange={(value) => setActualHarvestDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Desired Harvest Date"
            value={desiredHarvestDate}
            onChange={(value) => setDesiredHarvestDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Yield per Acre"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={yieldPerAcre}
            onChange={(e) => {
              const val = e.target.value;
              if (isEmpty(val)) {
                setYieldPerAcre(0);
              } else {
                setYieldPerAcre(parseInt(val));
              }
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      {isNil(commodityId) ? (
        <Typography sx={{ color: theme.palette.error.main }}>
          No attached commodity to the {fieldTrialVarietyType}
        </Typography>
      ) : (
        <React.Fragment>
          {isNil(selectedCommodity) ? (
            <Loading />
          ) : (
            <EvaluationCommodityForm
              fieldTrialVariety={fieldTrialVariety}
              fieldTrialVarietyType={fieldTrialVarietyType.replace(" ", "")}
              commodity={selectedCommodity}
            />
          )}
        </React.Fragment>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <TextField
          label="Evaluation Comments"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          fullWidth
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <FieldTrialVarietyImageUpload
          fieldTrialVariety={fieldTrialVariety}
          fieldTrialVarietyType={fieldTrialVarietyType.replace(" ", "")}
        />
        <FieldTrialVarietyImageList
          fieldTrialVariety={fieldTrialVariety}
          fieldTrialVarietyType={fieldTrialVarietyType.replace(" ", "")}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={updateFieldTrialVarietyAttributes}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Save
        </Button>
        {/*<DeleteConfirmation*/}
        {/*  open={openDeleteConfirmation}*/}
        {/*  setOpen={setOpenDeleteConfirmation}*/}
        {/*  onAccept={archiveEvaluation}*/}
        {/*  title={"Delete Confirmation"}*/}
        {/*  text={"Are you sure you want to delete the evaluation?"}*/}
        {/*/>*/}
      </Box>
    </Paper>
  );
}

type EvaluationCommodityFormProps = {
  commodity: Commodity;
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
function EvaluationCommodityForm({
  commodity,
  fieldTrialVariety,
  fieldTrialVarietyType,
}: EvaluationCommodityFormProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography variant="body1">Evaluation Criteria</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "begin",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {isEmpty(commodity.criteria) ? (
          <Typography variant="subtitle1">
            No criteria attached to the commodity
          </Typography>
        ) : (
          <React.Fragment>
            {commodity.criteria.map((criterium) => (
              <EvaluationCriteriumForm
                fieldTrialVarietyType={fieldTrialVarietyType}
                fieldTrialVariety={fieldTrialVariety}
                criterium={criterium}
                key={criterium.id}
              />
            ))}
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}

type EvaluationCriteriumFormProps = {
  criterium: Criterium;
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
function EvaluationCriteriumForm({
  fieldTrialVarietyType,
  fieldTrialVariety,
  criterium,
}: EvaluationCriteriumFormProps) {
  // TODO fetch the currently selected subcriterium by usering commodity, harvest, and criterium id
  const { data: evaluation, isLoading } = EvaluationAPI.useDetail({
    evaluableId: fieldTrialVariety.id,
    evaluableType: fieldTrialVarietyType,
    commodityId: criterium.commodityId,
    criteriumId: criterium.id,
  });

  return (
    <Box sx={{ flex: 1, minWidth: "12rem" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <EvaluationSubcriteriumForm
          criterium={criterium}
          evaluation={evaluation}
          fieldTrialVariety={fieldTrialVariety}
          fieldTrialVarietyType={fieldTrialVarietyType}
        />
      )}
    </Box>
  );
}

type EvaluationSubcriteriumFormProps = {
  criterium: Criterium;
  evaluation?: Evaluation;
  fieldTrialVariety: FieldVariety | TrialVariety;
  fieldTrialVarietyType: string;
};
function EvaluationSubcriteriumForm({
  criterium,
  evaluation,
  fieldTrialVariety,
  fieldTrialVarietyType,
}: EvaluationSubcriteriumFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSubcriterium, setSelectedSubcriterium] =
    React.useState<Subcriterium | null>(evaluation?.subcriterium || null);

  const { mutate: mutateEvaluation } = EvaluationAPI.useSave();

  const handleChange = async (event: any, newValue: Subcriterium | null) => {
    setSelectedSubcriterium(newValue);
    try {
      await mutateEvaluation({
        commodityId: criterium.commodityId,
        criteriumId: criterium.id,
        subcriteriumId: newValue?.id,
        evaluableId: fieldTrialVariety.id,
        evaluableType: fieldTrialVarietyType,
      });
      enqueueSnackbar("Saved");
    } catch (error) {
      enqueueSnackbar("Failed to save the evaluation.");
      console.error(error);
    }
  };

  return (
    <Autocomplete
      id={`evaluation-criterium-${criterium.id}-select`}
      value={selectedSubcriterium}
      onChange={handleChange}
      options={criterium.subcriteria}
      renderInput={(params) => <TextField {...params} label={criterium.name} />}
      getOptionLabel={(obj) => obj.name}
      sx={{ width: "100%" }}
      isOptionEqualToValue={(option: Subcriterium, value: Subcriterium) => {
        if (value === undefined) {
          return false;
        }
        return option?.id === value?.id;
      }}
    />
  );
}
