import { Autocomplete, TextField } from "@mui/material";

import MeasuringUnitAPI from "../api/MeasuringUnit.api";
import { MeasuringUnit, MeasuringUnitUsage } from "../types";

type Props = {
  usage: MeasuringUnitUsage;
  measuringUnit?: MeasuringUnit;
  setMeasuringUnit: (arg?: MeasuringUnit) => void;
};

export default function MeasuringUnitSelect({
  usage,
  measuringUnit,
  setMeasuringUnit,
}: Props) {
  const { data: measuringUnits, isLoading: measuringUnitsIsLoading } =
    MeasuringUnitAPI.useAll(usage);

  return (
    <Autocomplete
      disablePortal
      id="measuring-unit-select"
      options={
        measuringUnits
          ? measuringUnits.map((measuringUnit) => ({
              label: measuringUnit.name,
              value: measuringUnit,
            }))
          : []
      }
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Measuring Unit" />}
      onChange={(event: any, newValue) => {
        setMeasuringUnit(newValue?.value);
      }}
      value={
        measuringUnit
          ? { label: measuringUnit?.name, value: measuringUnit }
          : undefined
      }
      loading={measuringUnitsIsLoading}
    />
  );
}
