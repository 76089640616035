import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import {
  IrrigableType,
  Irrigation,
  IrrigationInput,
  SimpleListResponseData,
} from "../types";
import {
  handleDelete,
  handleFetchError,
  handleMutationError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    irrigableId,
    irrigableType,
  }: {
    irrigableId?: number;
    irrigableType: IrrigableType;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<Irrigation>>({
      cacheTime: 0,
      queryKey: ["irrigations", irrigableType, irrigableId],
      queryFn: () =>
        handleSimpleList({
          url: `irrigations`,
          params: [
            { key: "irrigable_id", value: irrigableId },
            { key: "irrigable_type", value: irrigableType },
          ],
        }),
      onError: handleFetchError(enqueueSnackbar),
      enabled: !!irrigableId,
    });
  },
  useSave: (irrigationInput: IrrigationInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Irrigation>({
      mutationFn: () =>
        handleSave({
          baseUrl: `irrigations`,
          input: irrigationInput,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["irrigations"]);
        await queryClient.refetchQueries([
          "irrigations",
          data.irrigableType,
          data.irrigableId?.toString(),
        ]);
      },
    });
  },
  useDelete: ({ id }: { id?: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Irrigation>({
      mutationFn: () => handleDelete({ baseUrl: `irrigations`, id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["irrigations"]);
        await queryClient.refetchQueries([
          "irrigations",
          data.irrigableType,
          data.irrigableId?.toString(),
        ]);
      },
    });
  },
};

export default methods;
