import { Box } from "@mui/material";
import React from "react";

import EquipmentAPI from "../api/Equipment.api";
import { Equipment } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentEquipment: (equipment: Equipment) => void;
  equipment?: Equipment;
};

export default function EquipmentCreatableAutocomplete({
  setCurrentEquipment,
  equipment,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(equipment?.name);
  const [value, setValue] = React.useState<Equipment | string>(
    equipment ? equipment.name : "",
  );

  const { data } = EquipmentAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find an Equipment"
        dialogTitle="Add a new Equipment"
        noOptionsText="No results"
        setModel={(equipment: Equipment) => {
          setCurrentEquipment(equipment);
          setSearchValue(equipment.name);
          setValue(equipment.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={EquipmentAPI.useSave}
      />
    </Box>
  );
}
