import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, TableCell, Typography } from "@mui/material";
import React from "react";

import SeedCompanyAPI from "../api/SeedCompany.api";
import BasicCreateModal from "../components/BasicCreateModal";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import { SeedCompany, TableColumn } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<SeedCompany>[] = [
  { field: "name", label: "Name" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(seedCompany: SeedCompany): JSX.Element[] {
  return [
    <TableCell key={"name"}>{seedCompany.name}</TableCell>,
    <TableCell key={"createdAt"}>
      {extractDate(seedCompany.createdAt)}
    </TableCell>,
  ];
}

export default function SeedCompanyList() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Seed Companies</Typography>
          <Button onClick={() => setOpenModal(true)}>
            <AddCircleIcon />
          </Button>
        </Box>

        <ListModel
          defaultOrderBy={"name"}
          modelApiUseList={SeedCompanyAPI.useList}
          modelBaseUrl={"seed_companies"}
          columns={columns}
          buildTableRow={buildTableRow}
        />

        <BasicCreateModal
          open={openModel}
          onClose={() => setOpenModal(false)}
          modelApiUseSave={SeedCompanyAPI.useSave}
          navigateUrl="/seed_companies"
        />
      </Box>
    </Layout>
  );
}
