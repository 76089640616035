import AgricultureIcon from "@mui/icons-material/Agriculture";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

import BusinessUnitAPI from "../api/BusinessUnit.api";
import Layout from "../components/Layout";

export default function DashboardPage() {
  const { data } = BusinessUnitAPI.useDashboard();
  return (
    <Layout>
      <Box
        sx={{
          gap: "2rem",
          display: "flex",
          flexDirection: "row",
          py: "2rem",
          px: "1rem",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "2rem",
            minWidth: "15rem",
          }}
          elevation={2}
        >
          <Box>
            <AgricultureIcon color={"primary"} />
          </Box>
          <Box>
            <Typography variant="h6">Harvests</Typography>
          </Box>
          <Box>
            {data && (
              <Typography variant="h3">
                {data["harvestScheduleCount"]}
              </Typography>
            )}
          </Box>
        </Paper>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "2rem",
            minWidth: "15rem",
          }}
          elevation={2}
        >
          <Box>
            <CalendarMonthIcon color={"primary"} />
          </Box>
          <Box>
            <Typography variant="h6">Planting Schedule</Typography>
          </Box>
          <Box>
            {data && (
              <Typography variant="h3">
                {data["plantingScheduleCount"]}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Layout>
  );
}
