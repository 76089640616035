import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
  Lot,
  LotInput,
  Ranch,
  RanchBaseQueryProps,
  SimpleListResponseData,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Lot[]>({
      queryKey: ["lots", query],
      queryFn: () => handleSearch({ baseUrl: "lots", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSearchByRanch: ({ query, ranch }: { query: string; ranch: Ranch }) => {
    const navigate = useNavigate();
    return useQuery<Lot[]>({
      queryKey: ["lots", query],
      queryFn: () =>
        handleSimpleList({
          url: "lots/search_by_ranch",
          params: [
            { key: "query", value: query },
            { key: "ranch_id", value: ranch.id },
          ],
        }),
      onError: handleQueryError(navigate),
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<Lot>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Lot>>({
      cacheTime: 0,
      queryKey: ["lots", order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: `lots`,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useListByRanch: ({
    ranchId,
    order,
    orderBy,
    page,
    query,
  }: RanchBaseQueryProps<Lot>) => {
    const navigate = useNavigate();
    const params = ranchId ? [{ key: "ranch_id", value: ranchId }] : undefined;
    return useQuery<ListResponseData<Lot>>({
      cacheTime: 0,
      queryKey: ["lots", ranchId, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: `lots/by_ranch`,
          order,
          orderBy,
          page,
          query,
          params,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useListCropRotation: ({
    ranchId,
    page,
  }: {
    ranchId?: number;
    page: number;
  }) => {
    const params = ranchId ? [{ key: "ranch_id", value: ranchId }] : undefined;
    return useQuery<ListResponseData<Lot>>({
      cacheTime: 0,
      queryKey: ["lots_crop_rotation", ranchId, page],
      queryFn: () =>
        handleList({
          baseUrl: "lots/crop_rotation_paginate",
          page,
          params,
        }),
    });
  },
  useAll: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<Lot>>({
      cacheTime: 0,
      queryKey: ["lots_all"],
      queryFn: () =>
        handleSimpleList({
          url: `lots/all`,
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Lot>({
      cacheTime: 0,
      queryKey: ["lot", id],
      queryFn: () => handleDetail({ baseUrl: "lots", id }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (lotInput: LotInput) => {
    const navigate = useNavigate();
    return useMutation<Lot>({
      mutationFn: () => handleSave({ baseUrl: "lots", input: lotInput }),
      onError: handleQueryError(navigate),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["lots", data.ranchId.toString()]);
      },
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Lot>({
      mutationFn: () => handleDelete({ baseUrl: "lots", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["lots", data.ranchId.toString()]);
      },
    });
  },
};
export default methods;
