import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import { HoeDate, HoeDateInput, SimpleListResponseData } from "../types";
import {
  handleDelete,
  handleFetchError,
  handleMutationError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (harvestId: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<HoeDate>>({
      cacheTime: 0,
      queryKey: ["harvests", harvestId.toString(), "hoe_dates"],
      queryFn: () =>
        handleSimpleList({ url: `harvests/${harvestId}/hoe_dates` }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useSave: (hoeDateInput: HoeDateInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<HoeDate>({
      mutationFn: () =>
        handleSave({
          baseUrl: `harvests/${hoeDateInput.harvestId}/hoe_dates`,
          input: hoeDateInput,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "hoe_dates",
        ]);
      },
    });
  },
  useDelete: ({ harvestId, id }: { harvestId: number; id: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<HoeDate>({
      mutationFn: () =>
        handleDelete({ baseUrl: `harvests/${harvestId}/hoe_dates`, id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "hoe_dates",
        ]);
      },
    });
  },
};

export default methods;
