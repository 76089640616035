import { Box, Typography } from "@mui/material";

import { Harvest } from "../types";
import SprayList from "./SprayList";

type Props = {
  harvest: Harvest;
};
export default function HarvestSprayTab({ harvest }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>

      <SprayList
        actualNumAcres={harvest?.lot?.numOfAcres}
        title="Sprays"
        sprayableId={harvest.id}
        sprayableType="Harvest"
      />
    </Box>
  );
}
