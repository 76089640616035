import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import {
  Cultivation,
  CultivationInput,
  SimpleListResponseData,
} from "../types";
import {
  handleDelete,
  handleFetchError,
  handleMutationError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (harvestId: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<Cultivation>>({
      cacheTime: 0,
      queryKey: ["harvests", harvestId.toString(), "cultivations"],
      queryFn: () =>
        handleSimpleList({ url: `harvests/${harvestId}/cultivations` }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useSave: (cultivationInput: CultivationInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Cultivation>({
      mutationFn: () =>
        handleSave({
          baseUrl: `harvests/${cultivationInput.harvestId}/cultivations`,
          input: cultivationInput,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "cultivations",
        ]);
      },
    });
  },
  useDelete: ({ harvestId, id }: { harvestId: number; id?: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Cultivation>({
      mutationFn: () =>
        handleDelete({ baseUrl: `harvests/${harvestId}/cultivations`, id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          "harvests",
          data.harvestId.toString(),
          "cultivations",
        ]);
      },
    });
  },
};

export default methods;
