import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  ListResponseData,
  MeasuringUnit,
  MeasuringUnitInput,
  MeasuringUnitUsage,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
  handleSimpleList,
} from "./handler";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<MeasuringUnit[]>({
      queryKey: ["measuring_units", query],
      queryFn: () => handleSearch({ baseUrl: "measuring_units", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (measuringUnitInput: MeasuringUnitInput) => {
    const navigate = useNavigate();
    return useMutation<MeasuringUnit>({
      mutationFn: () =>
        handleSave({ baseUrl: "measuring_units", input: measuringUnitInput }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useAll: (measuringUnitUsage: MeasuringUnitUsage) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<MeasuringUnit[]>({
      cacheTime: 5000,
      queryKey: ["measuring_units", "all"],
      queryFn: () =>
        handleSimpleList({
          url: `measuring_units/all`,
          params: [{ key: "usage", value: measuringUnitUsage }],
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<MeasuringUnit>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<MeasuringUnit>>({
      cacheTime: 0,
      queryKey: ["measuring_units", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "measuring_units", order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<MeasuringUnit>({
      cacheTime: 0,
      queryKey: ["measuring_unit", id],
      queryFn: () => handleDetail({ baseUrl: "measuring_units", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<MeasuringUnit>({
      mutationFn: () => handleDelete({ baseUrl: "measuring_units", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
