import SearchIcon from "@mui/icons-material/Search";
import { Box, TextField } from "@mui/material";
import React from "react";
import { UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ListQueryProps,
  ListResponseData,
  Model,
  Order,
  TableColumn,
} from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

interface ListTableProps<Type> {
  defaultOrderBy: keyof Type;
  modelApiUseList: (
    queryProps: ListQueryProps<Type>,
  ) => UseQueryResult<ListResponseData<Type>>;
  modelBaseUrl: string;
  columns: TableColumn<Type>[];
  buildTableRow: (arg0: Type) => JSX.Element[];
  buildRowSX?: (arg0: Type) => object;
  handleRowClick?: (arg0: Type) => (event: unknown) => void;
  showSearchBar?: boolean;
}

const defaultProps = {
  showSearchBar: true,
};

export default function ListModel<Type extends Model>({
  defaultOrderBy,
  modelApiUseList,
  modelBaseUrl,
  columns,
  buildTableRow,
  buildRowSX,
  showSearchBar,
  handleRowClick,
}: ListTableProps<Type>) {
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Type>(defaultOrderBy); // TODO
  const [query, setQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const { data } = modelApiUseList({
    query,
    order,
    orderBy,
    page,
    baseUrl: modelBaseUrl,
  });

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Type,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const defaultHandleRowClick = (model: Type) => (event: unknown) => {
    navigate(`/${modelBaseUrl}/${model.id}`);
  };

  return (
    <>
      {showSearchBar && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            mb: "1rem",
          }}
        >
          <SearchIcon sx={{ mr: "1rem" }} />
          <TextField
            id="search-model-table"
            value={query}
            onChange={handleQueryChange}
            label="Search"
            variant="standard"
            fullWidth
          />
        </Box>
      )}
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          buildRowSX={buildRowSX}
          handleChangePage={handleChangePage}
          handleRowClick={
            handleRowClick ? handleRowClick : defaultHandleRowClick
          }
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

ListModel.defaultProps = defaultProps;
