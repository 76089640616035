import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserAPI from "../api/User.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import { useSessionContext } from "../contexts/Session.context";
import { TableColumn, User, UserRole } from "../types";
import { extractDate, userRoleValue } from "../utils";

const columns: TableColumn<User>[] = [
  { field: "email", label: "Email" },
  { field: "confirmed", label: "Confirmed Email" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(user: User): JSX.Element[] {
  return [
    <TableCell key={"email"}>{user.email}</TableCell>,
    <TableCell key={"confirmed"}>{user.confirmed}</TableCell>,
    <TableCell key={"createdAt"}>{extractDate(user.createdAt)}</TableCell>,
  ];
}

export default function UserListPage() {
  const { session } = useSessionContext();
  const [openModel, setOpenModal] = React.useState<boolean>(false);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Users</Typography>
          {userRoleValue(session?.role) > userRoleValue("basic") && (
            <Button onClick={() => setOpenModal(true)}>
              <AddCircleIcon />
            </Button>
          )}
        </Box>

        <ListModel
          defaultOrderBy={"createdAt"}
          modelApiUseList={UserAPI.useList}
          modelBaseUrl={"users"}
          columns={columns}
          buildTableRow={buildTableRow}
        />
        {session && (
          <UserModal
            open={openModel}
            onClose={() => setOpenModal(false)}
            currentUserRole={session.role}
          />
        )}
      </Box>
    </Layout>
  );
}

type UserModalProps = {
  open: boolean;
  onClose: () => void;
  currentUserRole: UserRole;
};
function UserModal({ open, onClose, currentUserRole }: UserModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [userRole, setUserRole] = React.useState<UserRole>("basic");
  const [tempPassword, setTempPassword] = React.useState<string>("");

  const { mutateAsync } = UserAPI.useSave({
    email,
    role: userRole,
    firstName,
    lastName,
    password: tempPassword,
  });

  const handleCreate = async () => {
    try {
      if (isEmpty(email)) {
        enqueueSnackbar("Missing required field", {
          variant: "error",
        });
      }
      const model: User = await mutateAsync();
      onClose();
      return navigate(`/users/${model.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserRoleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value as UserRole);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value as string)
          }
        />
        <TextField
          label="First Name"
          variant="outlined"
          value={firstName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFirstName(event.target.value as string)
          }
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={lastName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setLastName(event.target.value as string)
          }
        />
        <FormControl fullWidth>
          <InputLabel id="user-permissions-select-label">
            User Permissions
          </InputLabel>
          <Select
            labelId="user-permissions-select-label"
            id="user-permissions-select"
            value={userRole}
            label="User Permissions"
            onChange={handleUserRoleChange}
          >
            {userRoleValue(currentUserRole) >= userRoleValue("admin") && (
              <MenuItem value={"admin"}>Admin</MenuItem>
            )}
            {userRoleValue(currentUserRole) >=
              userRoleValue("business_admin") && (
              <MenuItem value={"business_admin"}>Farmer Admin</MenuItem>
            )}
            <MenuItem value={"basic"}>Farmer Employee</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Temp. Password"
          variant="outlined"
          value={tempPassword}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setTempPassword(event.target.value as string)
          }
          type="password"
        />

        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
