import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PreharvestAPI from "../api/Preharvest.api";
import CreatePreharvestAnswerModal from "../components/CreatePreharvestAnswerModal";
import Layout from "../components/Layout";
import {
  PreharvestAnswer,
  PreharvestQuestion,
  PreharvestQuestionArchive,
} from "../types";

export default function PreharvestQuestionShowPage() {
  const { id } = useParams();
  const { data: preharvestQuestion, refetch } =
    PreharvestAPI.useQuestionDetail(id);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Preharvest Question</Typography>
        </Box>

        {preharvestQuestion && (
          <PreharvestQuestionForm
            preharvestQuestion={preharvestQuestion}
            refetch={refetch}
          />
        )}
      </Box>
    </Layout>
  );
}

function PreharvestQuestionForm({
  preharvestQuestion,
  refetch,
}: {
  preharvestQuestion: PreharvestQuestion;
  refetch: () => Promise<UseQueryResult>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const [question, setQuestion] = React.useState<string>(
    preharvestQuestion.question,
  );

  const [preharvestAnswers, setPreharvestAnswers] = React.useState<
    PreharvestAnswer[]
  >(preharvestQuestion?.preharvestAnswers || []);

  const replacePreharvestAnswer = (preharvestAnswer: PreharvestAnswer) => {
    const preharvestAnswerIndex = preharvestAnswers.findIndex(
      (a) => a.id === preharvestAnswer.id,
    );
    if (!preharvestAnswerIndex) {
      setPreharvestAnswers([...preharvestAnswers, preharvestAnswer]);
      return;
    }
    preharvestAnswers[preharvestAnswerIndex] = preharvestAnswer;
    const updatedPreharvestAnswer = preharvestAnswers.map((a, index) => ({
      ...a,
      orderId: index,
    }));

    setPreharvestAnswers([...updatedPreharvestAnswer]);
  };

  const removePreharvestAnswer = (preharvestAnswer: PreharvestAnswer) => {
    const updatedPreharvestAnswers = preharvestAnswers.filter(
      (a) => a.id !== preharvestAnswer.id,
    );
    setPreharvestAnswers(updatedPreharvestAnswers);
  };

  const { mutateAsync } = PreharvestAPI.useQuestionSaveAndArchiveOnEdit({
    id: preharvestQuestion?.id,
    question,
  });

  const handleSave = async () => {
    try {
      if (isEmpty(question)) {
        enqueueSnackbar("Requires a question for the Preharvest Question.");
        return;
      }
      const preharvestQuestionArchive: PreharvestQuestionArchive =
        await mutateAsync();
      if (preharvestQuestionArchive.archivedPreharvestQuestion) {
        navigate(
          `/preharvest_questions/${preharvestQuestionArchive.preharvestQuestion.id}`,
        );
      } else {
        enqueueSnackbar("Saved successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        label="Question"
        value={question}
        onChange={(event) => setQuestion(event.target.value)}
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Typography variant="h6">Preharvest Answers</Typography>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>
      <Box>
        {preharvestAnswers.map((preharvestAnswer) => (
          <PreharvestAnswerRow
            key={preharvestAnswer.id}
            preharvestAnswer={preharvestAnswer}
            replacePreharvestAnswer={replacePreharvestAnswer}
            removePreharvestAnswer={removePreharvestAnswer}
            preharvestQuestionId={preharvestQuestion.id}
          />
        ))}
      </Box>
      <Button onClick={handleSave} variant="contained" fullWidth>
        Save
      </Button>
      <CreatePreharvestAnswerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        replacePreharvestAnswer={replacePreharvestAnswer}
        removePreharvestAnswer={removePreharvestAnswer}
        preharvestQuestionId={preharvestQuestion.id}
      />
    </Box>
  );
}

function PreharvestAnswerRow({
  preharvestQuestionId,
  preharvestAnswer,
  replacePreharvestAnswer,
  removePreharvestAnswer,
}: {
  preharvestQuestionId: number;
  preharvestAnswer: PreharvestAnswer;
  replacePreharvestAnswer: (arg: PreharvestAnswer) => void;
  removePreharvestAnswer: (arg: PreharvestAnswer) => void;
}) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Typography variant="body1">{preharvestAnswer.answer}</Typography>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Edit
        </Button>
      </Box>
      <CreatePreharvestAnswerModal
        preharvestQuestionId={preharvestQuestionId}
        preharvestAnswer={preharvestAnswer}
        open={openModal}
        onClose={() => setOpenModal(false)}
        replacePreharvestAnswer={replacePreharvestAnswer}
        removePreharvestAnswer={removePreharvestAnswer}
      />
    </React.Fragment>
  );
}
