import { css } from "@emotion/css";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ConstructionIcon from "@mui/icons-material/Construction";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import ExpandIcon from "@mui/icons-material/Expand";
import ForestRoundedIcon from "@mui/icons-material/ForestRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TerrainIcon from "@mui/icons-material/Terrain";
import { Box, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

const cssClassName = css`
  color: #f50057;
  text-decoration: none;
`;
const cssMenuClassName = css`
  height: 100%;
  width: inherit;
`;
export default function NavigationMenu() {
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Sidebar defaultCollapsed={!matches} collapsedWidth={"70px"}>
      {!showSettings ? (
        <DisplayMainMenu onOpen={() => setShowSettings(true)} />
      ) : (
        <DisplaySettingsMenu onClose={() => setShowSettings(false)} />
      )}
    </Sidebar>
  );
}

function DisplayMainMenu({ onOpen }: { onOpen: () => void }) {
  const { collapsed } = useProSidebar();
  return (
    <Menu
      className={cssMenuClassName}
      rootStyles={{
        [`nav`]: {},
        [`ul`]: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <MenuItem component={<Link to={"/harvests"} />}>
          {collapsed ? (
            <AgricultureIcon color={"secondary"} />
          ) : (
            <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
              <AgricultureIcon color={"secondary"} />
              <Typography className={cssClassName}>Harvest Schedule</Typography>
            </Box>
          )}
        </MenuItem>
        <MenuItem component={<Link to={"/planting-schedule"} />}>
          {collapsed ? (
            <CalendarMonthIcon color={"secondary"} />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CalendarMonthIcon color={"secondary"} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className={cssClassName}>
                  Planting Schedule
                </Typography>
              </Box>
            </Box>
          )}
        </MenuItem>
        <SubMenu
          defaultOpen={true}
          label={
            collapsed ? (
              <SummarizeIcon color={"secondary"} />
            ) : (
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <SummarizeIcon color={"secondary"} />
                <Typography className={cssClassName}>Reports</Typography>
              </Box>
            )
          }
        >
          <MenuItem component={<Link to={"/reports"} />}>
            <Typography className={cssClassName}>Processed Reports</Typography>
          </MenuItem>
          <MenuItem component={<Link to={"/reports/search"} />}>
            <Typography className={cssClassName}>Generate Report</Typography>
          </MenuItem>
          <MenuItem
            component={<Link to={"/reports/actual-vs-desired-shippers"} />}
          >
            <Typography className={cssClassName}>Actual vs Desired</Typography>
          </MenuItem>

          <MenuItem component={<Link to={"/reports/crop-rotation"} />}>
            <Typography className={cssClassName}>Crop Rotation</Typography>
          </MenuItem>
        </SubMenu>
        <MenuItem component={<Link to={"/combined-schedule"} />}>
          {collapsed ? (
            <AllInboxIcon color={"secondary"} />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <AllInboxIcon color={"secondary"} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className={cssClassName}>
                  Combined Schedule
                </Typography>
              </Box>
            </Box>
          )}
        </MenuItem>
        <MenuItem component={<Link to={"/preliminary-works"} />}>
          {collapsed ? (
            <ChecklistRtlIcon color={"secondary"} />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ChecklistRtlIcon color={"secondary"} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className={cssClassName}>
                  Preliminary Work
                </Typography>
              </Box>
            </Box>
          )}
        </MenuItem>
        <MenuItem component={<Link to={"/completed-harvests"} />}>
          {collapsed ? (
            <CheckCircleIcon color={"secondary"} />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon color={"secondary"} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className={cssClassName}>
                  Completed Harvests
                </Typography>
              </Box>
            </Box>
          )}
        </MenuItem>
      </Box>
      <Box
        sx={{
          flexShrink: 1,
        }}
      >
        <Divider />
        <MenuItem onClick={onOpen}>
          {collapsed ? (
            <SettingsIcon color={"secondary"} />
          ) : (
            <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
              <SettingsIcon color={"secondary"} />
              <Typography className={cssClassName}>Settings</Typography>
            </Box>
          )}
        </MenuItem>
      </Box>
    </Menu>
  );
}

function DisplaySettingsMenu({ onClose }: { onClose: () => void }) {
  const { collapsed } = useProSidebar();
  return (
    <Menu
      className={cssMenuClassName}
      rootStyles={{
        [`nav`]: {},
        [`ul`]: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <MenuItem onClick={onClose}>
        {collapsed ? (
          <SettingsIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <SettingsIcon color={"secondary"} />
            <Typography className={cssClassName}>Hide Settings</Typography>
          </Box>
        )}
      </MenuItem>
      <Divider />
      <MenuItem component={<Link to={"/areas"} />}>
        {collapsed ? (
          <TerrainIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <TerrainIcon color={"secondary"} />
            <Typography className={cssClassName}>Areas</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/applicator_companies"} />}>
        {collapsed ? (
          <ForestRoundedIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ForestRoundedIcon color={"secondary"} />
            <Typography className={cssClassName}>Applicators</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/commodities"} />}>
        {collapsed ? (
          <StoreIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <StoreIcon color={"secondary"} />
            <Typography className={cssClassName}>Commodities</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/equipments"} />}>
        {collapsed ? (
          <ConstructionIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ConstructionIcon color={"secondary"} />
            <Typography className={cssClassName}>Equipments</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/ground_preps"} />}>
        {collapsed ? (
          <StoreIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <StoreIcon color={"secondary"} />
            <Typography className={cssClassName}>GroundPreps</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/materials"} />}>
        {collapsed ? (
          <ForestRoundedIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ForestRoundedIcon color={"secondary"} />
            <Typography className={cssClassName}>Materials</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/measuring_units"} />}>
        {collapsed ? (
          <ForestRoundedIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ForestRoundedIcon color={"secondary"} />
            <Typography className={cssClassName}>Measuring Units</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/preharvest_questions"} />}>
        {collapsed ? (
          <ForestRoundedIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ForestRoundedIcon color={"secondary"} />
            <Typography className={cssClassName}>
              Preharvest Questions
            </Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/ranches"} />}>
        {collapsed ? (
          <EmojiNatureIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <EmojiNatureIcon color={"secondary"} />
            <Typography className={cssClassName}>Ranches</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/regions"} />}>
        {collapsed ? (
          <MapIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <MapIcon color={"secondary"} />
            <Typography className={cssClassName}>Regions</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/seed_companies"} />}>
        {collapsed ? (
          <LocalShippingIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <LocalShippingIcon color={"secondary"} />
            <Typography className={cssClassName}>Seed Companies</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/shippers"} />}>
        {collapsed ? (
          <LocalShippingIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <LocalShippingIcon color={"secondary"} />
            <Typography className={cssClassName}>Shippers</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/sub_growers"} />}>
        {collapsed ? (
          <ExpandIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <ExpandIcon color={"secondary"} />
            <Typography className={cssClassName}>SubGrowers</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/tractors"} />}>
        {collapsed ? (
          <AgricultureIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <AgricultureIcon color={"secondary"} />
            <Typography className={cssClassName}>Tractors</Typography>
          </Box>
        )}
      </MenuItem>
      <MenuItem component={<Link to={"/varieties"} />}>
        {collapsed ? (
          <StoreIcon color={"secondary"} />
        ) : (
          <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
            <StoreIcon color={"secondary"} />
            <Typography className={cssClassName}>Varieties</Typography>
          </Box>
        )}
      </MenuItem>
    </Menu>
  );
}
