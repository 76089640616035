import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { UseMutationResult } from "react-query";

import { HttpError } from "../types/http-error";

interface IdInterface {
  id?: number;
}
type Props<TypeInput, TypeModel> = {
  modelApiUseSave: (
    arg0: TypeInput,
  ) => UseMutationResult<TypeModel, unknown, void, unknown>;
  modelApiUseDelete: (
    arg0?: number,
  ) => UseMutationResult<TypeModel, unknown, void, unknown>;
  modelInput: TypeInput;
  children: JSX.Element;
};
export default function BasicShowEdit<
  TypeInput extends IdInterface,
  TypeModel extends { id: number },
>({
  modelInput,
  modelApiUseSave,
  modelApiUseDelete,
  children,
}: Props<TypeInput, TypeModel>) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = modelApiUseSave(modelInput);
  const { mutateAsync: archiveMutateAsync } = modelApiUseDelete(modelInput?.id);

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved Successfully");
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
    }
  };

  const onArchive = async () => {
    try {
      await archiveMutateAsync();
      enqueueSnackbar("Archived Successfully");
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {children}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={onSave} fullWidth variant="contained">
          Save
        </Button>
        <Button onClick={onArchive} fullWidth variant="outlined">
          Delete
        </Button>
      </Box>
    </Box>
  );
}
