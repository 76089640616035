import { Box, TableCell, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import LotAPI from "../api/Lot.api";
import RanchAPI from "../api/Ranch.api";
import Layout from "../components/Layout";
import ListTable from "../components/ListTable";
import Loading from "../components/Loading";
import { Lot, Order, Ranch, TableColumn } from "../types";

const columns: TableColumn<Lot>[] = [
  { field: "num", label: "Lot Num" },
  { field: "numOfAcres", label: "Number of Acres" },
];

function buildTableRow(lot: Lot): JSX.Element[] {
  return [
    <TableCell key={"num"}>{lot.num}</TableCell>,
    <TableCell key={"numOfAcres"}>{lot.numOfAcres}</TableCell>,
  ];
}

export default function PreliminaryWorkByRanch() {
  const { ranchId } = useParams();
  const { data: ranch } = RanchAPI.useDetail(ranchId);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h2">Preliminary Work</Typography>
            {ranch && (
              <Typography variant="h6">Ranch {ranch.name} Selected</Typography>
            )}
          </Box>
        </Box>

        {ranch && (
          <ListLotsByRanch
            ranch={ranch}
            defaultOrderBy={"num"}
            modelBaseUrl={"preliminary-works-by-lot"}
            columns={columns}
            buildTableRow={buildTableRow}
          />
        )}
      </Box>
    </Layout>
  );
}

type ListLotsByRanchProps = {
  defaultOrderBy: keyof Lot;
  ranch?: Ranch;
  modelBaseUrl: string;
  columns: TableColumn<Lot>[];
  buildTableRow: (arg0: Lot) => JSX.Element[];
};

function ListLotsByRanch({
  buildTableRow,
  columns,
  defaultOrderBy,
  modelBaseUrl,
  ranch,
}: ListLotsByRanchProps) {
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Lot>(defaultOrderBy); // TODO

  const navigate = useNavigate();

  const { data } = LotAPI.useListByRanch({
    ranchId: ranch?.id,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Lot,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (model: Lot) => (event: unknown) => {
    navigate(`/${modelBaseUrl}/${model.id}`);
  };

  return (
    <>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
