import { Box } from "@mui/material";
import React from "react";

import TractorAPI from "../api/Tractor.api";
import { Tractor } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  disabled?: boolean;
  setCurrentTractor: (tractor: Tractor) => void;
  tractor?: Tractor;
};

export default function TractorCreatableAutocomplete({
  disabled,
  setCurrentTractor,
  tractor,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(tractor?.name);
  const [value, setValue] = React.useState<Tractor | string>(
    tractor ? tractor.name : "",
  );

  const { data } = TractorAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find an Tractor"
        dialogTitle="Add a new Tractor"
        disabled={disabled}
        noOptionsText="No results"
        setModel={(tractor: Tractor) => {
          setCurrentTractor(tractor);
          setSearchValue(tractor.name);
          setValue(tractor.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={TractorAPI.useSave}
      />
    </Box>
  );
}
