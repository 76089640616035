import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import HarvestMainForm from "../components/HarvestMainForm";
import HarvestTabs from "../components/HarvestTabs";
import Layout from "../components/Layout";

export default function HarvestShow() {
  const { id } = useParams();
  const { data: harvest, refetch: refetchHarvest } = HarvestAPI.useDetail({
    id,
  });

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          paddingTop: "1rem",
        }}
      >
        {harvest && (
          <HarvestMainForm harvest={harvest} refetchHarvest={refetchHarvest} />
        )}

        {harvest && (
          <HarvestTabs harvest={harvest} refetchHarvest={refetchHarvest} />
        )}
      </Box>
    </Layout>
  );
}
