import { isEmpty, isNil } from "lodash";
import React from "react";

import LotAPI from "../api/Lot.api";
import { Lot } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  lot?: Lot;
  setLot: (lot?: Lot) => void;
};

export default function LotSearch({ lot, setLot }: Props) {
  const [query, setQuery] = React.useState<string>("");

  const { data } = LotAPI.useSearch({ query });

  const options = data
    ? data.map((o) => ({ label: `${o.ranch?.name}, ${o.num}`, value: o }))
    : [];

  const value =
    isNil(lot) || isEmpty(lot)
      ? { label: query, value: query }
      : { label: `${lot.ranch?.name}, ${lot.num}`, value: lot };

  return (
    <SearchBarAutocomplete
      label={"Ranch - Lot"}
      inputValue={query}
      setSearchValue={setQuery}
      options={options}
      noOptionsText={"Lot not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue) || isEmpty(newValue)) {
          setLot(undefined);
        } else {
          setLot(newValue.value);
        }
      }}
    />
  );
}
