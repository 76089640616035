import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import React from "react";

import FieldVarietyAPI from "../api/FieldVariety.api";
import HarvestAPI from "../api/Harvest.api";
import TrialVarietyAPI from "../api/TrialVariety.api";
import { Equipment, Harvest, HarvestInput, Tractor } from "../types";
import Autosave from "./Autosave";
import EquipmentCreatableAutocomplete from "./EquipmentCreatableAutocomplete";
import MaterialsUsed from "./MaterialsUsed";
import PlantedVarietyList from "./PlantedVarietyList";
import PreliminaryWorkHarvestWrapper from "./PreliminaryWorkHarvestWrapper";
import TractorCreatableAutocomplete from "./TractorCreatableAutocomplete";

type Props = {
  harvest: Harvest;
};

export default function HarvestPlantingReportTab({ harvest }: Props) {
  const [actualWetDate, setActualWetDate] = React.useState<Dayjs | null>(
    harvest.actualWetDate ? dayjs(harvest.actualWetDate) : null,
  );
  const [bedWidth, setBedWidth] = React.useState<number>(harvest.bedWidth || 0);

  const [plantingReportComments, setPlantingReportComments] =
    React.useState<string>(harvest.plantingReportComments || "");
  const [seedLine, setSeedLine] = React.useState<number>(harvest.seedLine || 0);
  const [tractor, setTractor] = React.useState<Tractor | undefined>(
    harvest?.preliminaryWork?.tractor,
  );
  const [equipment, setEquipment] = React.useState<Equipment | undefined>(
    harvest?.equipment,
  );

  const harvestInput: HarvestInput = {
    id: harvest.id,
    actualWetDate: actualWetDate ? actualWetDate.toDate() : actualWetDate,
    bedWidth,
    equipmentId: equipment?.id,
    plantingReportComments,
    seedLine,
  };

  const { mutateAsync } = HarvestAPI.useUpdateAuto();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Autosave
        data={harvestInput}
        patchApiCall={mutateAsync}
        showPopup={true}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Bed Width"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={bedWidth}
            onChange={(e) => setBedWidth(parseInt(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Seed Line"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={seedLine}
            onChange={(e) => {
              const str = e.target.value;
              if (isEmpty(str)) {
                setSeedLine(0);
              } else {
                setSeedLine(parseInt(str));
              }
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <EquipmentCreatableAutocomplete
            setCurrentEquipment={(equipment: Equipment) =>
              setEquipment(equipment)
            }
            equipment={equipment}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PreliminaryWorkHarvestWrapper
            input={{
              id: harvest.preliminaryWorkId,
              tractorId: tractor?.id,
            }}
          >
            <TractorCreatableAutocomplete
              setCurrentTractor={(tractor: Tractor) => setTractor(tractor)}
              tractor={tractor}
              disabled={!harvest.preliminaryWorkId}
            />
          </PreliminaryWorkHarvestWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Actual Wet Date"
            value={actualWetDate}
            onChange={(value) => setActualWetDate(value)}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <PlantedVarietyList
          harvestId={harvest.id}
          modelAPI={FieldVarietyAPI}
          type={"FieldVariety"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <PlantedVarietyList
          harvestId={harvest.id}
          modelAPI={TrialVarietyAPI}
          type={"TrialVariety"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <MaterialsUsed
          numAcres={harvest?.lot?.numOfAcres}
          materialApplicableId={harvest.id}
          materialApplicableType={"Harvest"}
          phase={"planting"}
        />
      </Box>
      <TextField
        label="Comments"
        multiline
        rows={4}
        value={plantingReportComments}
        onChange={(e) => setPlantingReportComments(e.target.value)}
      />
    </Box>
  );
}
