import { Box, TableCell, Typography } from "@mui/material";
import React from "react";

import ReportAPI from "../api/Report.api";
import Layout from "../components/Layout";
import ListModel from "../components/ListModel";
import { Report, TableColumn } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<Report>[] = [
  { field: "filename", label: "File Name" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(report: Report): JSX.Element[] {
  return [
    <TableCell key={"filename"}>{report.filename}</TableCell>,
    <TableCell key={"createdAt"}>{extractDate(report.createdAt)}</TableCell>,
  ];
}

export default function ReportListPage() {
  return (
    <Layout>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Reports Processed</Typography>
        </Box>
        <ListModel
          defaultOrderBy={"createdAt"}
          modelApiUseList={ReportAPI.useList}
          modelBaseUrl={"reports"}
          columns={columns}
          buildTableRow={buildTableRow}
        />
      </Box>
    </Layout>
  );
}
