import { Autocomplete, Chip, TextField } from "@mui/material";
import { filter } from "lodash";
import React from "react";

import ShipperAPI from "../api/Shipper.api";
import { Shipper } from "../types";

type Props = {
  shippers: Shipper[];
  setShippers: (shippers: Shipper[]) => void;
};

type ShipperOption = { label: string; value: Shipper };

export default function ShipperMultiSelectSearch({
  shippers,
  setShippers,
}: Props) {
  const [query, setQuery] = React.useState<string>("");
  const { data } = ShipperAPI.useSearch({ query });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Autocomplete
      freeSolo
      multiple
      autoHighlight
      filterSelectedOptions
      id="shipper-search-multi-select-outlined"
      options={options}
      filterOptions={(options, params) => {
        const selectedShipperIds = shippers.map((shipper) => shipper.id);
        const filtered = filter(
          options,
          (option: ShipperOption) =>
            !selectedShipperIds.includes(option.value.id),
        );
        return filtered;
      }}
      renderTags={(value: readonly ShipperOption[], getTagProps) =>
        value.map((option: ShipperOption, index: number) => (
          <Chip
            variant="outlined"
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(event: any, newValues: (ShipperOption | string)[]) => {
        const shipperOptions = filter(
          newValues,
          (obj: ShipperOption | string) => typeof obj !== "string",
        ) as ShipperOption[];
        setShippers(shipperOptions.map((obj) => obj.value));
      }}
      value={shippers.map((shipper) => ({
        label: shipper.name,
        value: shipper,
      }))}
      onInputChange={(event, newQuery) => {
        setQuery(newQuery);
      }}
      disableCloseOnSelect
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Shippers"
          placeholder="Filter by shippers"
        />
      )}
    />
  );
}
