import { isNil } from "lodash";
import React from "react";

import ShipperAPI from "../api/Shipper.api";
import { Shipper } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  shipper?: Shipper;
  setShipper: (shipper?: Shipper) => void;
};

export default function ShipperSearch({ shipper, setShipper }: Props) {
  const [query, setQuery] = React.useState<string>("");

  const { data } = ShipperAPI.useSearch({ query });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  const value = isNil(shipper)
    ? { label: query, value: query }
    : { label: shipper.name, value: shipper };

  return (
    <SearchBarAutocomplete
      label={"Shipper"}
      inputValue={query}
      setSearchValue={setQuery}
      options={options}
      noOptionsText={"Shipper not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue)) {
          setShipper(undefined);
        } else {
          setShipper(newValue.value);
        }
      }}
    />
  );
}
