import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import { Evaluation, EvaluationInput } from "../types";
import {
  handleDelete,
  handleFetch,
  handleFetchError,
  handleMutationError,
  handleSave,
} from "./handler";

const methods = {
  useSave: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: (evaluationInput: EvaluationInput) =>
        handleSave({
          baseUrl: "evaluations",
          input: evaluationInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useDetail: ({
    evaluableId,
    evaluableType,
    commodityId,
    criteriumId,
  }: {
    evaluableId: number;
    evaluableType: string;
    commodityId: number;
    criteriumId: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const url = `evaluations?commodity_id=${commodityId}&criterium_id=${criteriumId}&evaluable_id=${evaluableId}&evaluable_type=${evaluableType}`;
    return useQuery<Evaluation>({
      cacheTime: 0,
      queryKey: [
        "evaluation",
        evaluableId,
        evaluableType,
        commodityId,
        criteriumId,
      ],
      queryFn: () => handleFetch({ url }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useDelete: (id: number | string) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: () => handleDelete({ baseUrl: "evaluations", id }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
};

export default methods;
