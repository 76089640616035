import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { isEmpty, isNil, xor } from "lodash";
import React from "react";

import PreharvestAPI from "../api/Preharvest.api";
import { useSessionContext } from "../contexts/Session.context";
import {
  Harvest,
  PreharvestAnswer,
  PreharvestEvaluation,
  PreharvestQuestion,
} from "../types";
import Loading from "./Loading";

type Props = {
  harvest: Harvest;
};
export default function PreharvestEvaluationMultiselect({ harvest }: Props) {
  const { session } = useSessionContext();
  const { data: preharvestQuestions, isLoading } =
    PreharvestAPI.useListQuestionsWithAnswers({
      businessUnitId: session && session.businessUnitId,
    });

  if (isNil(preharvestQuestions) || isLoading) {
    return <Loading />;
  }

  //reference GroundPrepSelect
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {preharvestQuestions.map((preharvestQuestion) => (
        <PreharvestEvaluations
          key={preharvestQuestion.id}
          preharvestQuestion={preharvestQuestion}
          harvest={harvest}
        />
      ))}
    </Box>
  );
}

function PreharvestEvaluations({
  harvest,
  preharvestQuestion,
}: {
  harvest: Harvest;
  preharvestQuestion: PreharvestQuestion;
}) {
  const { data, isLoading } = PreharvestAPI.useListEvaluations({
    harvestId: harvest.id,
    preharvestQuestionId: preharvestQuestion.id,
  });

  if (isNil(data) || isLoading) {
    return <Loading />;
  }

  return (
    <PreharvestQuestionAnswer
      harvest={harvest}
      preharvestQuestion={preharvestQuestion}
      preharvestEvaluations={data}
    />
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: PreharvestAnswer) => option.answer,
});

type PreharvestQuestionAnswerProps = {
  preharvestEvaluations: PreharvestEvaluation[];
  preharvestQuestion: PreharvestQuestion;
  harvest: Harvest;
};
function PreharvestQuestionAnswer({
  harvest,
  preharvestQuestion,
  preharvestEvaluations,
}: PreharvestQuestionAnswerProps) {
  const [selectedAnswers, setSelectedAnswers] = React.useState<
    PreharvestAnswer[]
  >(
    preharvestEvaluations.map(
      (preharvestEvaluation) => preharvestEvaluation.preharvestAnswer,
    ) || [],
  );

  const { mutate } = PreharvestAPI.useCreateEvaluations({
    harvestId: harvest.id,
    preharvestQuestionId: preharvestQuestion.id,
    preharvestAnswerIds: selectedAnswers.map((answer) => answer.id),
  });

  React.useEffect(() => {
    const previousAnswerIds = preharvestEvaluations.map(
      (evaluation) => evaluation.preharvestAnswerId,
    );
    const newAnswerIds = selectedAnswers.map((answer) => answer.id);
    if (!isEmpty(xor(previousAnswerIds, newAnswerIds))) {
      mutate();
    }
  }, [harvest, preharvestQuestion, selectedAnswers, mutate]);

  return (
    <Autocomplete
      multiple
      options={preharvestQuestion.preharvestAnswers}
      value={selectedAnswers}
      onChange={(event: any, newValue: PreharvestAnswer[]) => {
        setSelectedAnswers(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      getOptionLabel={(option) => option.answer}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.answer}
        </li>
      )}
      style={{ width: "100%" }}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={preharvestQuestion.question}
          placeholder="Preharvest Evaluation"
        />
      )}
    />
  );
}
