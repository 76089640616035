import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Button, Paper } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useDropzone } from "react-dropzone";
import { UseMutationResult } from "react-query";

import { File } from "../types";

type Props<TypeModel> = {
  parentId?: number;
  soilAnalysisFiles?: File[];
  useUploadFile: ({
    id,
    files,
  }: {
    id?: number;
    files: any;
  }) => UseMutationResult<TypeModel, unknown, void, unknown>;
};

export default function SoilAnalysis<TypeModel extends { id: number }>({
  parentId,
  soilAnalysisFiles,
  useUploadFile,
}: Props<TypeModel>) {
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = React.useState<any[]>();

  const onDrop = React.useCallback(
    async (acceptedFiles: any[]) => {
      if (acceptedFiles.length >= 1 && acceptedFiles.length <= 10) {
        setFiles(acceptedFiles);
      }
      if (acceptedFiles.length > 10) {
        enqueueSnackbar(
          "Exceeded maxium number of files uploaded. No more than a 10 files at a time.",
          { variant: "error" },
        );
      }
    },
    [enqueueSnackbar],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
      },
      disabled: !parentId,
    });

  const { isLoading, mutateAsync } = useUploadFile({
    files,
    id: parentId,
  });

  const throttledOnSave = React.useCallback(
    debounce(async () => {
      const res = await mutateAsync();
      if ("errors" in res) {
        // res.errors.forEach((message: string) =>
        //   enqueueSnackbar(message, { variant: "error" }),
        // );
      } else {
        enqueueSnackbar("Saved successfully");
      }
    }, 1000),
    [enqueueSnackbar, mutateAsync],
  );

  if (fileRejections.length > 0) {
    fileRejections.forEach(({ file, errors }) => {
      enqueueSnackbar(`Failed to upload file: ${errors[0].message}`, {
        variant: "error",
      });
    });
  }

  const openFile = (file: File) => () => {
    window.open(file.url, "_blank", "noreferrer");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          listStyle: "none",
        }}
      >
        {soilAnalysisFiles &&
          soilAnalysisFiles.map((file) => (
            <Button
              key={file.id}
              size="large"
              color="primary"
              variant="contained"
              onClick={openFile(file)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <InsertDriveFileIcon />
                {file.filename}
              </Box>
            </Button>
          ))}
      </Box>
      <Paper
        elevation={3}
        sx={{ padding: "2rem", justifyContent: "center", display: "flex" }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <BoxText
          isDragActive={isDragActive}
          fileLoaded={!!files}
          fileName={
            files && files.length > 0 ? files.map((f) => f.name).join(", ") : ""
          }
          disabled={!parentId}
        />
      </Paper>
      {!!files && (
        <Button
          fullWidth
          onClick={throttledOnSave}
          variant="contained"
          color="primary"
          disabled={isLoading || !parentId}
        >
          UPLOAD
        </Button>
      )}
    </Box>
  );
}

type PropsBoxText = {
  isDragActive: boolean;
  fileLoaded: boolean;
  fileName?: string;
  disabled: boolean;
};
function BoxText({
  isDragActive,
  fileLoaded,
  fileName,
  disabled,
}: PropsBoxText) {
  if (disabled) {
    return <p>File upload is disabled.</p>;
  } else if (isDragActive) {
    return <p>Drop the file here ...</p>;
  } else if (fileLoaded) {
    return (
      <p>
        File uploaded: <strong>{fileName}</strong>
      </p>
    );
  }
  return (
    <p>Drag 'n' drop a file here, or click to select the soil analysis file</p>
  );
}
