import { TableCell, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserAPI from "../api/User.api";
import { Order, TableColumn, User } from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<User>[] = [
  { field: "email", label: "Email" },
  { field: "firstName", label: "First Name" },
  { field: "lastName", label: "Last Name" },
  { field: "role", label: "Role" },
];

function buildTableRow(user: User): JSX.Element[] {
  return [
    <TableCell key={"email"}>{user.email}</TableCell>,
    <TableCell key={"firstName"}>{user.firstName}</TableCell>,
    <TableCell key={"lastName"}>{user.lastName}</TableCell>,
    <TableCell key={"role"}>{user.role}</TableCell>,
  ];
}

export default function BusinessUnitUsersListTable() {
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof User>("createdAt"); // TODO
  const [query, setQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const { data } = UserAPI.useList({
    query,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof User,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (user: User) => (event: unknown) => {
    navigate(`/business-unit-users/${user.id}`);
  };

  return (
    <>
      <TextField
        id="UserListTable-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
