import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import LotSearch from "../components/LotSearch";
import { Lot, SublotInput } from "../types";

type Props = {
  open: boolean;
  onClose: () => void;
  harvestSchedule: "Harvest" | "Planting";
};
export default function HarvestCreateModal({
  harvestSchedule,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [lot, setLot] = React.useState<Lot | undefined>();
  const [showSublots, setShowSublots] = React.useState<boolean>(false);
  const [sublots, setSublots] = React.useState<SublotInput[]>([]);

  // const { mutateAsync } = HarvestAPI.useCreate({ ranchId: ranch?.id, lotNum });

  const handleCreate = async () => {
    try {
      if (isNil(lot)) {
        enqueueSnackbar("Must select a ranch lot", {
          variant: "error",
        });
      }
      // const newHarvest = await mutateAsync();
      onClose();
      // return navigate(`/harvests/${newHarvest.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSublot = () => {
    setSublots((sublots) => [
      ...sublots,
      {
        num: "",
        numOfAcres: 0,
      } as SublotInput,
    ]);
  };

  const handleSublotAttribute =
    ({ sublotIndex, key }: { sublotIndex: number; key: keyof SublotInput }) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSublots((sublots) => {
        sublots[sublotIndex] = {
          ...(sublots[sublotIndex] ?? {}),
          [key]: event.target.value,
        };
        return [...sublots];
      });
    };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LotSearch lot={lot} setLot={setLot} />

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1">Does this lot have sublots?</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Button onClick={() => setShowSublots(true)}>Yes</Button>
            <Button onClick={() => setShowSublots(false)}>No</Button>
          </Box>
        </Box>
        {showSublots && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Sublets</Typography>
              <Button onClick={handleAddSublot} variant="contained">
                Add a sublot
              </Button>
            </Box>
            <Divider />
            {sublots.map((sublot, sublotIndex) => (
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <TextField
                  label="Sublot Number"
                  value={sublot.num}
                  onChange={handleSublotAttribute({ sublotIndex, key: "num" })}
                />
                <TextField
                  label="Number of Acres"
                  value={sublot.numOfAcres}
                  onChange={handleSublotAttribute({
                    sublotIndex,
                    key: "numOfAcres",
                  })}
                />
              </Box>
            ))}
          </Box>
        )}
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
