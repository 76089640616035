import { isNil } from "lodash";
import React from "react";

import SeedCompanyAPI from "../api/SeedCompany.api";
import { SeedCompany } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  setSeedCompany: (seedCompany: SeedCompany | undefined) => void;
  seedCompany: SeedCompany | undefined;
  label: string;
};

export default function SeedCompanySearchableAutocomplete({
  label,
  seedCompany,
  setSeedCompany,
}: Props) {
  const [searchValue, setSearchValue] = React.useState<string>("");

  const { data } = SeedCompanyAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  const value = isNil(seedCompany)
    ? { label: searchValue, value: searchValue }
    : { label: seedCompany.name, value: seedCompany };

  return (
    <SearchBarAutocomplete
      label={label}
      inputValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
      noOptionsText={"Seed Company not found."}
      value={value}
      onChange={(event, newValue) => {
        if (isNil(newValue)) {
          setSeedCompany(undefined);
        } else {
          setSeedCompany(newValue.value);
        }
      }}
    />
  );
}
