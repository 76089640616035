import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import DeleteConfirmation from "../components/DeleteConfirmation";
import DeleteHarvestButton from "../components/DeleteHarvestButton";
import HarvestFieldWorkReportTab from "../components/HarvestFieldWorkReportTab";
import HarvestScheduledForm from "../components/HarvestScheduledForm";
import Layout from "../components/Layout";
import { Harvest } from "../types";
import { HttpError } from "../types/http-error";

export default function PlantingScheduleShowPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: harvest, refetch: refetchHarvest } = HarvestAPI.useDetail({
    id,
  });

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Scheduled Plant</Typography>
          {harvest && (
            <DeleteHarvestButton
              id={harvest.id}
              onSuccessCallback={() => navigate("/planting-schedule")}
            />
          )}
        </Box>
        {harvest && (
          <HarvestScheduledForm
            harvest={harvest}
            refetchHarvest={refetchHarvest}
          />
        )}
        {harvest && (
          <Box sx={{ marginTop: "1rem" }}>
            <HarvestScheduledToPlanted harvest={harvest} />
          </Box>
        )}
        <Box sx={{ marginTop: "1rem" }}>
          {harvest && <HarvestFieldWorkReportTab harvest={harvest} />}
        </Box>
      </Box>
    </Layout>
  );
}

function HarvestScheduledToPlanted({ harvest }: { harvest: Harvest }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [actualWetDate, setActualWetDate] = React.useState<Dayjs | null>(
    harvest.actualWetDate ? dayjs(harvest.actualWetDate) : null,
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState<boolean>(false);

  const { mutateAsync } = HarvestAPI.useScheduledToHarvest({
    id: harvest.id,
    actualWetDate: actualWetDate ? actualWetDate.toDate() : actualWetDate,
  });

  const updateHarvest = async () => {
    try {
      await mutateAsync();
      navigate(`/harvests/${harvest.id}`);
    } catch (error) {
      console.error(error);
      if ("HttpError" && typeof error) {
        const httpError = error as HttpError;
        enqueueSnackbar(httpError?.message, { variant: "error" });
      }
      setActualWetDate(null);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <DatePicker
          label="Actual Wet Date"
          value={actualWetDate}
          onChange={(value) => {
            setActualWetDate(value);
            setOpenDeleteConfirmation(true);
          }}
          sx={{ width: "100%" }}
        />
        <DeleteConfirmation
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          onAccept={updateHarvest}
          title={"Harvest Planted Confirmation"}
          text={
            "Are you sure you want to move harvet from scheduled to planted?"
          }
        />
      </Box>
    </React.Fragment>
  );
}
