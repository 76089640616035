import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import HarvestAPI from "../api/Harvest.api";
import HarvestBasicForm from "../components/HarvestBasicForm";
import HarvestTabs from "../components/HarvestTabs";
import Layout from "../components/Layout";

export default function CompletedHarvestShowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: harvest, refetch: refetchHarvest } = HarvestAPI.useDetail({
    id,
  });

  const { mutateAsync: mutateNewStateAsync } = HarvestAPI.useTransitionState({
    harvestId: harvest?.id,
    newState: "harvesting",
  });

  const handleUncomplete = async () => {
    try {
      await mutateNewStateAsync();
      navigate(`/harvests/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
            mb: "1rem",
          }}
        >
          <Typography variant="h4">Completed Harvest</Typography>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUncomplete}
              startIcon={<CheckBoxOutlineBlankIcon />}
            >
              Return to Harvesting
            </Button>
          </Box>
        </Box>

        {harvest && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <TextField
              label="Ranch - Lot"
              value={`${harvest.lot.ranch?.name}, ${harvest.lot.num}`}
              disabled={true}
              fullWidth
            />
            <TextField
              label="Number of Acres"
              value={`${harvest.lot.numOfAcres}`}
              disabled={true}
              fullWidth
            />
          </Box>
        )}

        {harvest && (
          <HarvestBasicForm harvest={harvest} refetchHarvest={refetchHarvest} />
        )}

        {harvest && (
          <HarvestTabs harvest={harvest} refetchHarvest={refetchHarvest} />
        )}
      </Box>
    </Layout>
  );
}
