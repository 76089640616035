import { Box, Button, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

import ReportAPI from "../api/Report.api";
import { Commodity, Ranch, ReportType, Shipper } from "../types";
import CommodityMultiSelectSearch from "./CommodityMultiSelectSearch";
import RanchSearch from "./RanchSearch";
import ShipperSearch from "./ShipperSearch";

type ReportDateType =
  | "projected_wet_date"
  | "est_harvest_date"
  | "actual_wet_date";

type Props = {
  reportType: ReportType;
};

export default function ReportScheduleForm({ reportType }: Props) {
  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [dateType, setDateType] = React.useState<ReportDateType | undefined>();

  const [commodities, setCommodities] = React.useState<Commodity[]>([]);
  const [ranch, setRanch] = React.useState<Ranch | undefined>();
  const [shipper, setShipper] = React.useState<Shipper | undefined>();

  const handleDateTypeChange = (event: SelectChangeEvent) => {
    setDateType(event.target.value as ReportDateType);
  };

  const { mutateAsync } = ReportAPI.useRunReport({
    reportType,
    startDate,
    endDate,
  });

  const handleSubmit = async () => {
    try {
      const report = await mutateAsync();
      return navigate(`/reports/${report.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1, maxWidth: "12rem" }}>
          <FormControl fullWidth>
            <InputLabel id="date-filter-select-label">Date Type</InputLabel>
            <Select
              labelId="date-filter-select-label"
              id="date-filter-select"
              value={dateType}
              label="Date Type"
              onChange={handleDateTypeChange}
            >
              <MenuItem value={"est_harvest_date"}>Est. Harvest Date</MenuItem>
              <MenuItem value={"projected_wet_date"}>Project Wet Date</MenuItem>
              <MenuItem value={"actual_wet_date"}>Wet Date</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(value) => setStartDate(value)}
            sx={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(value) => setEndDate(value)}
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <CommodityMultiSelectSearch
            commodities={commodities}
            setCommodities={setCommodities}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ShipperSearch shipper={shipper} setShipper={setShipper} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <RanchSearch ranch={ranch} setRanch={setRanch} />
        </Box>
      </Box>
      <Button onClick={handleSubmit} variant="contained">
        Run Report
      </Button>
    </Box>
  );
}
