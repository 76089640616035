import { Box } from "@mui/material";
import React from "react";

import CommodityAPI from "../api/Commodity.api";
import { Commodity } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  setCurrentCommodity: (commodity: Commodity) => void;
  commodity?: Commodity;
};

export default function CommodityCreatableAutocomplete({
  commodity,
  setCurrentCommodity,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(commodity?.name);
  const [value, setValue] = React.useState<Commodity | string>(
    commodity ? commodity.name : "",
  );

  const { data } = CommodityAPI.useSearch({
    query: searchValue,
  });

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Commodity"
        dialogTitle="Add a new Commodity"
        noOptionsText="No results"
        setModel={(commodity: Commodity) => {
          setCurrentCommodity(commodity);
          setSearchValue(commodity.name);
          setValue(commodity.name);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={CommodityAPI.useSave}
      />
    </Box>
  );
}
