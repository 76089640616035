import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

import {
  SimpleListResponseData,
  Spray,
  SprayInput,
  SprayableType,
} from "../types";
import {
  handleDelete,
  handleFetchError,
  handleMutationError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    sprayableId,
    sprayableType,
  }: {
    sprayableId: number;
    sprayableType: SprayableType;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<SimpleListResponseData<Spray>>({
      cacheTime: 0,
      queryKey: ["sprays"],
      queryFn: () =>
        handleSimpleList({
          url: `sprays`,
          params: [
            { key: "sprayable_id", value: sprayableId },
            { key: "sprayable_type", value: sprayableType },
          ],
        }),
      onError: handleFetchError(enqueueSnackbar),
    });
  },
  useSave: (sprayInput: SprayInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Spray>({
      mutationFn: () =>
        handleSave({
          baseUrl: `sprays`,
          input: sprayInput,
        }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["sprays"]);
      },
    });
  },
  useDelete: ({ id }: { id?: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Spray>({
      mutationFn: () => handleDelete({ baseUrl: `sprays`, id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["sprays"]);
      },
    });
  },
};

export default methods;
