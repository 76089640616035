import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BaseQueryProps,
  BaseSearchQueryProps,
  GroundPrep,
  GroundPrepAttachableType,
  GroundPrepInput,
  ListResponseData,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleFetchError,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleSearch,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useSearch: ({ query }: BaseSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<GroundPrep[]>({
      queryKey: ["ground_preps", query],
      queryFn: () => handleSearch({ baseUrl: "ground_preps", query }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (groundPrepInput: GroundPrepInput) => {
    const navigate = useNavigate();
    return useMutation<GroundPrep>({
      mutationFn: () =>
        handleSave({ baseUrl: "ground_preps", input: groundPrepInput }),
      onError: handleQueryError(navigate),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["ground_preps", data.businessUnitId]);
      },
    });
  },
  useAll: (businessUnitId: number | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<GroundPrep[]>({
      cacheTime: 5000,
      queryKey: ["ground_preps", businessUnitId],
      queryFn: () => handleSimpleList({ url: "ground_preps/all" }),
      onError: handleFetchError(enqueueSnackbar),
      enabled: !isNil(businessUnitId),
    });
  },
  useList: ({ order, orderBy, page, query }: BaseQueryProps<GroundPrep>) => {
    return useQuery<ListResponseData<GroundPrep>>({
      cacheTime: 0,
      queryKey: ["ground_preps", order, orderBy, page, query],
      queryFn: () =>
        handleList({ baseUrl: "ground_preps", order, orderBy, page, query }),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<GroundPrep>({
      cacheTime: 0,
      queryKey: ["ground_prep", id],
      queryFn: () => handleDetail({ baseUrl: "ground_preps", id }),
      onError: handleQueryError(navigate),
    });
  },
  useAttachToGroundPrepAttachable: ({
    groundPrepIds,
    groundPrepAttachableId,
    groundPrepAttachableType,
  }: {
    groundPrepIds: number[];
    groundPrepAttachableType: GroundPrepAttachableType;
    groundPrepAttachableId?: number;
  }) => {
    return useMutation<GroundPrep>({
      mutationFn: () =>
        handleSave({
          baseUrl: `ground_preps/attachments`,
          input: {
            groundPrepAttachableId,
            groundPrepAttachableType,
            groundPrepIds,
          },
        }),
    });
  },
  useDelete: (id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<GroundPrep>({
      mutationFn: () => handleDelete({ baseUrl: "ground_preps", id }),
      retry: 1,
      onError: handleMutationError(enqueueSnackbar),
    });
  },
};

export default methods;
