import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import MaterialApplicationAPI from "../api/MaterialApplication.api";
import {
  Harvest,
  Material,
  MaterialApplicableType,
  MaterialApplication,
  MaterialApplicationInput,
  MaterialApplicationPhase,
  MeasuringUnit,
} from "../types";
import {
  materialApplicationDataToString,
  materialApplicationPhaseToString,
} from "../utils";
import MaterialSearchableAutocomplete from "./MaterialSearchableAutocomplete";
import MeasuringUnitSelect from "./MeasuringUnitSelect";

type Props = {
  harvest: Harvest;
};

export default function HarvestFieldWorkReportTab({ harvest }: Props) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { data } = MaterialApplicationAPI.useListByHarvest(harvest.id);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">
          Info explaining to the user what this phase is about and when to add
          items here.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography>Material Applications</Typography>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          flexWrap: "wrap",
          listStyle: "none",
          gap: "1rem",
        }}
      >
        {data &&
          data.map((materialApplication) => (
            <MaterialApplicationLineItem
              key={materialApplication.id}
              materialApplication={materialApplication}
              harvest={harvest}
            />
          ))}
      </Box>
      <MaterialApplicationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        harvestId={harvest.id}
        materialApplicableType={"Harvest"}
        materialApplicableId={harvest.id}
        phase={"extra"}
      />
    </Box>
  );
}

function MaterialApplicationLineItem({
  materialApplication,
  harvest,
}: {
  materialApplication: MaterialApplication;
  harvest: Harvest;
}) {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Box
        key={materialApplication.id}
        sx={{
          display: "flex",
          flexDirection: "row",
          p: "1rem",
          border: `2px solid ${theme.palette.grey["300"]}`,
          borderRadius: 1,
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <Typography variant="body1">
            {materialApplicationDataToString(materialApplication)}
          </Typography>
          <Typography variant="subtitle1">
            In {materialApplicationPhaseToString(materialApplication.phase)}{" "}
            phase
          </Typography>
          <Typography variant="subtitle1">
            On {dayjs(materialApplication.appliedOn).format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <Box
          sx={{
            flexShrink: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <MaterialApplicationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        materialApplication={materialApplication}
        harvestId={harvest.id}
        materialApplicableType={materialApplication.materialApplicableType}
        materialApplicableId={materialApplication.materialApplicableId}
        phase={materialApplication.phase}
      />
    </React.Fragment>
  );
}

type MaterialApplicationModalProps = {
  open: boolean;
  onClose: () => void;
  materialApplication?: MaterialApplication;
  harvestId: number;
  materialApplicableType: MaterialApplicableType;
  materialApplicableId: number;
  phase: MaterialApplicationPhase;
};
function MaterialApplicationModal({
  open,
  onClose,
  materialApplication,
  harvestId,
  materialApplicableType,
  materialApplicableId,
  phase,
}: MaterialApplicationModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchedMaterial, setSearchedMaterial] = React.useState<Material>();
  const [showEditMaterial, setShowEditMaterial] = React.useState<boolean>(
    materialApplication?.material ? false : true,
  );

  const [appliedOn, setAppliedOn] = React.useState<Dayjs | null>(
    materialApplication?.appliedOn
      ? dayjs(materialApplication?.appliedOn)
      : dayjs(new Date()),
  );
  const [amount, setAmount] = React.useState<number | undefined>(
    materialApplication?.amount || 0,
  );
  const [measuringUnit, setMeasuringUnit] = React.useState<
    MeasuringUnit | undefined
  >(materialApplication?.measuringUnit);

  let dataToSave: MaterialApplicationInput = {
    id: materialApplication?.id,
    materialApplicableId,
    materialApplicableType,
    phase,
    appliedOn: appliedOn ? appliedOn.toDate() : appliedOn,
    amount,
    measuringUnitId: measuringUnit?.id,
  };

  if (searchedMaterial && searchedMaterial.id) {
    dataToSave = { ...dataToSave, materialId: searchedMaterial.id };
  }

  const { mutateAsync } = MaterialApplicationAPI.useSaveByHarvest({
    materialApplicationInput: dataToSave,
    harvestId: harvestId,
  });

  const handleSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved material application successfully");
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteMutateAsync } = MaterialApplicationAPI.useDelete(
    materialApplication?.id,
  );

  const handleDelete = async () => {
    try {
      await deleteMutateAsync();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const showSelectedMaterial = () => {
    if (searchedMaterial) {
      return searchedMaterial.name;
    } else if (materialApplication?.material) {
      return materialApplication?.material?.name;
    } else {
      return "No Material currently selected";
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-material-application"
      aria-describedby="modal-material-application-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <Box>
          <Typography variant="h6">Applied Material</Typography>
        </Box>
        <Divider />
        {showEditMaterial ? (
          <Box>
            <MaterialSearchableAutocomplete
              label={"Select a material to proceed"}
              material={searchedMaterial}
              setMaterial={setSearchedMaterial}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2">Material</Typography>
              <Typography variant="body1">{showSelectedMaterial()}</Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setShowEditMaterial(true);
                }}
              >
                Edit Material
              </Button>
            </Box>
          </Box>
        )}
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DatePicker
              label="Applied On"
              value={appliedOn}
              onChange={(value) => setAppliedOn(value)}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Amount of Material"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNil(e.target.value) || e.target.value.length === 0) {
                  setAmount(undefined);
                } else {
                  const newVal = parseFloat(e.target.value);
                  if (newVal >= 0) setAmount(newVal);
                  if (newVal < 0) setAmount(0);
                }
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <MeasuringUnitSelect
              usage={"applied_materials"}
              measuringUnit={measuringUnit}
              setMeasuringUnit={setMeasuringUnit}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Button onClick={handleSave} variant="outlined" fullWidth>
              Save Material Application
            </Button>
          </Box>
          {materialApplication?.id && (
            <Box sx={{ flex: 1 }}>
              <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
