import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Modal,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";

import LotAPI from "../api/Lot.api";
import { Lot, Order, Ranch, TableColumn } from "../types";
import ListTableWithCustomRow from "./ListTableWithCustomRow";
import Loading from "./Loading";

const columns: TableColumn<Lot>[] = [
  { field: "num", label: "Lot #", orderBy: true },
  { field: "numOfAcres", label: "Number of Acres", orderBy: false },
];

function buildTableRow(lot: Lot): JSX.Element[] {
  return [
    <TableCell key={"num"}>{lot.num}</TableCell>,
    <TableCell key={"numOfAcres"}>{lot.numOfAcres}</TableCell>,
  ];
}

type Props = {
  ranch: Ranch;
};
export default function RanchLotList({ ranch }: Props) {
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Lot>("num"); // TODO
  const [query, setQuery] = React.useState<string>("");
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { data } = LotAPI.useListByRanch({
    ranchId: ranch.id,
    order,
    orderBy,
    page,
    query,
  });

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Lot,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          mb: "1rem",
        }}
      >
        <SearchIcon sx={{ mr: "1rem" }} />
        <TextField
          id="search-model-table"
          value={query}
          onChange={handleQueryChange}
          label="Search"
          variant="standard"
          fullWidth
        />
        <Button onClick={() => setOpenModal(true)}>
          <AddCircleIcon />
        </Button>
      </Box>

      {data ? (
        <ListTableWithCustomRow
          columns={columns}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        >
          {(data.data || []).map((model) => (
            <CustomRow model={model} key={model.id} />
          ))}
        </ListTableWithCustomRow>
      ) : (
        <Loading />
      )}
      <LotEditModal
        ranchId={ranch.id}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

function CustomRow({ model }: { model: Lot }) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() => setOpenModal(true)}
        tabIndex={-1}
        sx={{ cursor: "poniter" }}
      >
        {buildTableRow(model)}
      </TableRow>
      <LotEditModal
        ranchId={model.ranchId}
        lot={model}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </React.Fragment>
  );
}

type LotEditModalProps = {
  open: boolean;
  onClose: () => void;
  ranchId: number;
  lot?: Lot;
};
function LotEditModal({ ranchId, open, onClose, lot }: LotEditModalProps) {
  // const { enqueueSnackbar } = useSnackbar();

  const [num, setNum] = React.useState<string>(lot?.num || "");
  const [numOfAcres, setNumOfAcres] = React.useState<number | undefined>(
    lot?.numOfAcres,
  );

  const { mutateAsync } = LotAPI.useSave({
    id: lot?.id,
    ranchId,
    num,
    numOfAcres,
  });

  const handleCreate = async () => {
    try {
      await mutateAsync();
      if (!lot?.id) {
        setNum("");
        setNumOfAcres(undefined);
      }
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Lot Number"
          variant="outlined"
          value={num}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNum(event.target.value as string)
          }
        />
        <TextField
          label="Number of Acres"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={numOfAcres}
          onChange={(e) => setNumOfAcres(parseFloat(e.target.value))}
          sx={{ width: "100%" }}
        />
        <Button onClick={handleCreate} variant="contained" fullWidth>
          {lot?.id ? "Update" : "Create"}
        </Button>
        <Button onClick={onClose} variant="contained" color="info" fullWidth>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
