import { parseISO } from "date-fns";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import moment from "moment";

import {
  IrrigationMethod,
  MaterialApplication,
  MaterialApplicationPhase,
  ReportState,
  ReportType,
  UserRole,
} from "../types";

export function extractDate(arg0?: Date): string {
  if (isNil(arg0)) {
    return "";
  }
  return moment(arg0).format("MM/DD/YYYY");
}

export function extractDateAPI(arg0?: Date): string | undefined {
  if (isNil(arg0)) {
    return undefined;
  }
  return moment(arg0).format("YYYY-MM-DD");
}

const ISODateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === "string" && ISODateFormat.test(value);
};

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return parseISO(data);
  if (typeof data === "string" && dayjs(data, "YYYY-MM-DD", true).isValid())
    return dayjs(data).toDate();
  if (data === null || data === undefined || typeof data !== "object")
    return data;

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = parseISO(val);
    else if (
      typeof val === "string" &&
      dayjs(val, "YYYY-MM-DD", true).isValid()
    )
      // @ts-expect-error this is a hack to make the type checker happy
      data[key] = dayjs(val).toDate();
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};

export const convertDatesToStrings = (data: unknown) => {
  if (data instanceof Date) return dayjs(data).format("YYYY-MM-DD");
  if (data === null || data === undefined || typeof data !== "object")
    return data;
  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (val instanceof Date) data[key] = dayjs(val).format("YYYY-MM-DD");
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};

export const convertUndefinedToNull = (data: unknown) => {
  if (data === undefined) return null;
  if (data === null || typeof data !== "object") return data;
  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (val === undefined) data[key] = null;
    else if (typeof val === "object") {
      // @ts-expect-error
      data[key as keyof typeof data] = convertUndefinedToNull(val);
    } else {
      // @ts-expect-error
      data[key] = val;
    }
  }

  return data;
};

export function centsToDollars(priceCents: number | undefined): string {
  if (!priceCents) return "$0.00";
  // const priceCentsNum =
  //   priceCents instanceof "number" ? priceCents : parseInt(priceCents);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(priceCents * 0.01);
}

export async function formatErrorResponse(error: any) {
  try {
    if ("response" in error) {
      const errorData = await error.response.json();
      return Promise.reject(errorData.exception);
    }
  } catch (e) {}
  return Promise.reject(error);
}

export function isDigit(arg0: string): boolean {
  return /^\d+$/.test(arg0);
}

export function userRoleValue(userRole?: UserRole) {
  if (userRole === "admin") {
    return 3;
  } else if (userRole === "business_admin") {
    return 2;
  } else {
    return 1;
  }
}

export function materialApplicationDataToString(
  materialApplication: MaterialApplication,
) {
  return `Applied${" "}
            ${
              materialApplication.amount
                ? materialApplication.amount
                : "a missing amount"
            }${" "}
            ${
              materialApplication?.measuringUnit
                ? materialApplication.measuringUnit.name
                : "of missing units"
            }${" "}
            of${" "}
            ${
              materialApplication?.material?.name
                ? materialApplication?.material?.name
                : "missing material"
            }`;
}

export function irrigationMethodToString(arg: IrrigationMethod) {
  if (arg === "sprinkler_solid") {
    return "Sprinkler - Solid";
  } else if (arg === "sprinkler_set_and_move") {
    return "Sprinkler - Set and Move";
  } else if (arg === "drip") {
    return "Drip";
  } else if (arg === "furrow") {
    return "Furrow";
  }
  return "";
}

export function materialApplicationPhaseToString(
  arg: MaterialApplicationPhase,
) {
  if (arg === "cultivating") {
    return "Cultivation";
  } else if (arg === "planting") {
    return "Planting";
  } else if (arg === "fielding") {
    return "Field Work";
  } else if (arg === "preirrigating") {
    return "Preirrigation";
  } else if (arg === "irrigating") {
    return "Irrigation";
  } else if (arg === "spraying") {
    return "Spray";
  } else if (arg === "listing") {
    return "Listing";
  } else if (arg === "prefielding") {
    return "Prefield Work";
  } else if (arg === "extra") {
    return "Uncategorized";
  }
  return "Uknown";
}

export function reportStateToName(reportState: ReportState) {
  if (reportState === "unprocessed") {
    return "In queue";
  } else if (reportState === "processing") {
    return "Processing";
  } else if (reportState === "completed") {
    return "Ready to download";
  } else if (reportState === "error") {
    return "Error processing report";
  }
}

export function reportTypeToName(reportType: ReportType) {
  if (reportType === "active_harvest") {
    return "In Harvest Excel Report";
  } else if (reportType === "harvest_planting_schedule") {
    return "Planting Schedule Excel Report";
  }
  return "N/A";
}

export function getSessionToken() {
  const data = window.localStorage.getItem(
    process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
  );
  if (!(isNil(data) || isEmpty(data))) {
    const sessionData = JSON.parse(data) || {};
    const expDate = new Date(sessionData["exp"]);
    if (sessionData["token"] && sessionData["exp"] && expDate > new Date()) {
      return sessionData["token"];
    }
  }
  return null;
}
