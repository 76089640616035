import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import ReportAPI from "../api/Report.api";
import { Lot, Ranch } from "../types";
import LotByRanchSearch from "./LotByRanchSearch";
import RanchSearch from "./RanchSearch";

export default function ReportCropRotationForm() {
  const navigate = useNavigate();
  const [numYearsBack, setNumYearsBack] = React.useState<number>(5);
  const [ranch, setRanch] = React.useState<Ranch | undefined>();
  const [lot, setLot] = React.useState<Lot | undefined>();

  const { mutateAsync } = ReportAPI.useRunReport({
    reportType: "crop_rotation",
    numYearsBack,
    ranchId: ranch?.id,
    lotId: lot?.id,
  });

  const handleSubmit = async () => {
    try {
      const report = await mutateAsync();
      return navigate(`/reports/${report.id}`);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            label="Number of years back"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={numYearsBack}
            onChange={(e) => setNumYearsBack(parseFloat(e.target.value))}
            fullWidth
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <RanchSearch ranch={ranch} setRanch={setRanch} />
        </Box>
        <Box sx={{ flex: 1 }}>
          {ranch && (
            <LotByRanchSearch ranch={ranch} lot={lot} setLot={setLot} />
          )}
        </Box>
      </Box>

      <Button
        onClick={handleSubmit}
        variant="contained"
        // disabled={isNil(ranch)}
      >
        Run Report
      </Button>
    </Box>
  );
}
