import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import MaterialAPI from "../api/Material.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { Material, MaterialInput } from "../types";

export default function MaterialShow() {
  const { id } = useParams();
  const { data: material } = MaterialAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Materials</Typography>
        </Box>

        {material && <MaterialForm material={material} />}
      </Box>
    </Layout>
  );
}

function MaterialForm({ material }: { material: Material }) {
  const [name, setName] = React.useState<string>(material.name);

  const input: MaterialInput = {
    ...material,
    name,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={MaterialAPI.useSave}
      modelApiUseDelete={MaterialAPI.useDelete}
      modelInput={input}
    >
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </BasicShowEdit>
  );
}
