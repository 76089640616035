import { Box, Modal, Typography } from "@mui/material";
import React from "react";

import { MaterialApplicableType, MaterialApplicationPhase } from "../types";
import MaterialsUsed from "./MaterialsUsed";

type Props = {
  actualNumAcres?: number;
  open: boolean;
  onClose: () => void;
  materialApplicableId: number;
  materialApplicableType: MaterialApplicableType;
  phase: MaterialApplicationPhase;
  title: string;
};

export default function MaterialsUsedModal(
  {
    actualNumAcres,
    open,
    onClose,
    materialApplicableId,
    materialApplicableType,
    phase,
    title,
  }: Props,
) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-materials-used"
      aria-describedby="modal-materials-used-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h5" id="input-slider" gutterBottom>
          {title}
        </Typography>
        {materialApplicableId && (
          <MaterialsUsed
            numAcres={actualNumAcres}
            materialApplicableId={materialApplicableId} // cultivation does not exist yet
            materialApplicableType={materialApplicableType}
            phase={phase}
          />
        )}
      </Box>
    </Modal>
  );
}
