import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, TableCell, Typography } from "@mui/material";
import React from "react";

// import ReportAPI from "../api/Report.api";
import HarvestCreateModal from "../components/HarvestCreateModal";
import HarvestFilterList from "../components/HarvestFilterList";
import Layout from "../components/Layout";
import { HarvestList, TableColumn } from "../types";
import { extractDate } from "../utils";

const columns: TableColumn<HarvestList>[] = [
  { field: "commodity", label: "Crop", orderBy: false },
  { field: "shipper", label: "Shipper", orderBy: false },
  { field: "ranch", label: "Ranch", orderBy: false },
  { field: "lotNum", label: "Lot #", orderBy: false },
  { field: "actualNumAcres", label: "Acres", orderBy: false },
  { field: "desiredNumAcres", label: "Desired Acres", orderBy: false },
  { field: "projectedWetDate", label: "Projected Wet Date", orderBy: true },
  { field: "actualWetDate", label: "Actual Wet Dates", orderBy: true },
  { field: "estHarvestDate", label: "Est. Harvest Date", orderBy: true },
  { field: "fieldVarieties", label: "Variety", orderBy: false },
  { field: "bedWidth", label: "Bed Width", orderBy: false },
  { field: "seedLine", label: "Seed Lines", orderBy: false },
  { field: "bedCount", label: "Bed Count", orderBy: false },
  { field: "harvestCompleted", label: "Harvested", orderBy: false },
];

function buildTableRow(harvest: HarvestList): JSX.Element[] {
  return [
    <TableCell key={"commodity"}>
      {harvest.commodity ? harvest.commodity.name : "N/A"}
    </TableCell>,
    <TableCell key={"shipper"}>
      {harvest.shipper ? harvest.shipper.name : "N/A"}
    </TableCell>,
    <TableCell key={"ranch"}>
      {harvest.lot ? harvest.lot?.ranch?.name : "N/A"}
    </TableCell>,
    <TableCell key={"lotNum"}>{harvest.lot?.num || "N/A"}</TableCell>,
    <TableCell key={"actualNumAcres"}>
      {harvest.lot?.numOfAcres || "N/A"}
    </TableCell>,
    <TableCell key={"desiredNumAcres"}>
      {harvest.desiredNumAcres || "N/A"}
    </TableCell>,
    <TableCell key={"projectedWetDate"}>
      {harvest.projectedWetDate ? extractDate(harvest.projectedWetDate) : "N/A"}
    </TableCell>,
    <TableCell key={"actualWetDate"}>
      {harvest.actualWetDate ? extractDate(harvest.actualWetDate) : "N/A"}
    </TableCell>,
    <TableCell key={"estHarvestDate"}>
      {harvest.estHarvestDate ? extractDate(harvest.estHarvestDate) : "N/A"}
    </TableCell>,
    <TableCell key={"fieldVarieties"}>
      {harvest.fieldVarieties
        ? harvest.fieldVarieties.map((v) => v?.variety?.name).join(", ")
        : "N/A"}
    </TableCell>,
    <TableCell key={"bedWidth"}>{harvest.bedWidth || "N/A"}</TableCell>,
    <TableCell key={"seedLine"}>{harvest.seedLine || "N/A"}</TableCell>,
    <TableCell key={"bedCount"}>{harvest.bedCount || "N/A"}</TableCell>,
    <TableCell key={"harvestCompleted"} align="center">
      {harvest.harvestCompleted && <CheckCircleIcon />}
    </TableCell>,
  ];
}

function buildRowSX(harvest: HarvestList): object {
  return {
    backgroundColor: harvest?.commodity?.color,
  };
}

export default function HarvestListPage() {
  const [openModel, setOpenModal] = React.useState<boolean>(false);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h2">Harvest Schedule</Typography>
          </Box>
          <Box
            sx={{
              flexShrink: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button onClick={() => setOpenFilter(!openFilter)}>
              <FilterListIcon />
            </Button>
          </Box>
        </Box>

        <HarvestFilterList
          columns={columns}
          buildTableRow={buildTableRow}
          buildRowSX={buildRowSX}
          defaultOrderBy={"estHarvestDate"}
          harvestStateFilter="harvesting"
          modelBaseUrl={"harvests"}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
        />

        <HarvestCreateModal
          harvestSchedule={"Harvest"}
          open={openModel}
          onClose={() => setOpenModal(false)}
        />
      </Box>
    </Layout>
  );
}
