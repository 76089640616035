import deepEqual from "deep-equal";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { UseMutateAsyncFunction } from "react-query";

const DEBOUNCE_SAVE_DELAY_MS = 1000;

type Props<Type> = {
  data: Type;
  patchApiCall: UseMutateAsyncFunction<unknown, any, Type, unknown>;
  showPopup: boolean;
};

export default function Autosave<Type>({
  data,
  patchApiCall,
  showPopup,
}: Props<Type>) {
  const { enqueueSnackbar } = useSnackbar();

  const [savedData, setSavedData] = React.useState<Type>(data);

  const debouncedSave = React.useCallback(
    debounce(async (newData) => {
      try {
        await patchApiCall({ ...newData });
        setSavedData(newData);
        if (showPopup) {
          enqueueSnackbar("Successfully saved", { variant: "success" });
        }
      } catch (error) {
        console.error("autosave error", error);
        if (showPopup) {
          enqueueSnackbar("Autosave failed.", { variant: "error" });
        }
      }
    }, DEBOUNCE_SAVE_DELAY_MS),
    [],
  );

  React.useEffect(() => {
    if (data && savedData && !deepEqual(data, savedData)) {
      debouncedSave(data);
    }
  }, [data, savedData, debouncedSave]);

  return null;
}
