import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import { Harvest } from "../types";
import IrrigationList from "./IrrigationList";

type Props = {
  harvest: Harvest;
};

export default function HarvestIrrigationReportTab({ harvest }: Props) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="body1">
            Info explaining to the user what this phase is about and when to add
            items here.
          </Typography>
        </Box>

        {harvest.preliminaryWorkId && (
          <React.Fragment>
            <IrrigationList
              actualNumAcres={harvest?.lot?.numOfAcres}
              title="Preliminary Work Irrigation List"
              isPreirrigation={false}
              irrigableId={harvest.preliminaryWorkId}
              irrigableType={"PreliminaryWork"}
            />
            <Divider />
          </React.Fragment>
        )}

        <IrrigationList
          actualNumAcres={harvest?.lot?.numOfAcres}
          title="Harvest Irrigation List"
          isPreirrigation={false}
          irrigableId={harvest.id}
          irrigableType={"Harvest"}
        />
      </Box>
    </React.Fragment>
  );
}
