import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import SubGrowerAPI from "../api/SubGrower.api";
import BasicShowEdit from "../components/BasicShowEdit";
import Layout from "../components/Layout";
import { Subgrower, SubgrowerInput } from "../types";

export default function SubGrowerShow() {
  const { id } = useParams();
  const { data: subGrower } = SubGrowerAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Sub Growers</Typography>
        </Box>

        {subGrower && <SubGrowerForm subGrower={subGrower} />}
      </Box>
    </Layout>
  );
}

function SubGrowerForm({ subGrower }: { subGrower: Subgrower }) {
  const [name, setName] = React.useState<string>(subGrower.name);

  const input: SubgrowerInput = {
    ...subGrower,
    name,
  };

  return (
    <BasicShowEdit
      modelApiUseSave={SubGrowerAPI.useSave}
      modelApiUseDelete={SubGrowerAPI.useDelete}
      modelInput={input}
    >
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </BasicShowEdit>
  );
}
